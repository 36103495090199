import React, { Component } from 'react';
import { connect } from "react-redux";
import Nav from "../../components/Nav"
import "./style.css";
import {souvenirInventoryData} from "../../util/souvenirInventoryData";

function SouvenirInventoryPage(props) {
  const longSortItemList = [
    { name: "code", sortKey: "down", title: "代號" },
    { name: "name", sortKey: "down", title: "名稱" },
    { name: "suggestion", sortKey: "down", title: "建議" },
    { name: "share_price", sortKey: "down", title: "股價" },
    { name: "year_113", sortKey: "down", title: "113" },
    { name: "price_113", sortKey: "down", title: "收購價" },
    { name: "year_112", sortKey: "down", title: "112" },
    { name: "price_112", sortKey: "down", title: "收購價" },
    { name: "year_111", sortKey: "down", title: "111" },
    { name: "price_111", sortKey: "down", title: "收購價" },
    { name: "year_110", sortKey: "down", title: "110" },
    { name: "year_109", sortKey: "down", title: "109" },
    { name: "year_108", sortKey: "down", title: "108" },
    { name: "year_107", sortKey: "down", title: "107" },
  ];

  return (
    <div className='souvenir-inventory-root'>
      <Nav/>
      <div className="souvenir-inventory-background">
        <div className="souvenir-inventory-title">建議清冊+紀念品總表</div>
        <div className="souvenir-inventory-main-display">
          <table className='margin_bottom'>
            <thead>
              {longSortItemList.map((content, index) => {
                return(
                  <th className={`stock-title 
                    ${content.name === "year_113" || content.name === "year_112" ? "border_left" : ""} 
                    ${content.name === "year_113" || content.name === "price_113" || content.name === "year_112" || content.name === "price_112" ? "border_top" : ""}
                    ${content.name === "price_112" ? "border_right" : ""} `}>{content.title}</th>
                )
              })}
            </thead>
            <tbody>
              {souvenirInventoryData.map((souvenirInventory, index) => {
                return(
                  <tr className={`hover ${index % 2 != 0 ? "odd-row" : ""}`}>
                    <td>{souvenirInventory.code != null ? souvenirInventory.code : ""}</td>
                    <td>{souvenirInventory.name != null ? souvenirInventory.name : ""}</td>
                    <td>{souvenirInventory.suggestion != null ? souvenirInventory.suggestion : ""}</td>
                    <td>{souvenirInventory.share_price != null ? Math.round(souvenirInventory.share_price) : ""}</td>
                    <td className={`border_left ${index == souvenirInventoryData.length - 1 ? "border_bottom" : ""}`}>{souvenirInventory.year_113 != null ? souvenirInventory.year_113 : ""}</td>
                    <td className={`${index == souvenirInventoryData.length - 1 ? "border_bottom" : ""}`}>{souvenirInventory.price_113 != null ? souvenirInventory.price_113 : ""}</td>
                    <td className={`border_left ${index == souvenirInventoryData.length - 1 ? "border_bottom" : ""}`}>{souvenirInventory.year_112 != null ? souvenirInventory.year_112 : ""}</td>
                    <td className={`border_right ${index == souvenirInventoryData.length - 1 ? "border_bottom" : ""}`}>{souvenirInventory.price_112 != null ? souvenirInventory.price_112 : ""}</td>
                    <td>{souvenirInventory.year_111 != null ? souvenirInventory.year_111 : ""}</td>
                    <td>{souvenirInventory.price_111 != null ? souvenirInventory.price_111 : ""}</td>
                    <td>{souvenirInventory.year_110 != null ? souvenirInventory.year_110 : ""}</td>
                    <td>{souvenirInventory.year_109 != null ? souvenirInventory.year_109 : ""}</td>
                    <td>{souvenirInventory.year_108 != null ? souvenirInventory.year_108 : ""}</td>
                    <td>{souvenirInventory.year_107 != null ? souvenirInventory.year_107 : ""}</td>
                  </tr>
                )
              }) }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default connect(
  null,
  {  }
)(SouvenirInventoryPage);