import React from "react";
import { connect } from "react-redux";
import Nav from "../../components/Nav";
import { useState, useEffect } from "react";
import { BiSolidUpArrow, BiSolidDownArrow } from "react-icons/bi";
import { getAttorneyByUser } from "../../actions/attorneyAction";
import { MDBInputGroup } from "mdbreact";

const stockSortItemList = [
  { name: "stock_code", sortKey: "down", title: "股號" },
  { name: "stock_name", sortKey: "down", title: "股名" },
  { name: "gift_item", sortKey: "down", title: "紀念品" },
  { name: "total_pic", sortKey: "down", title: "交單" },
  { name: "manual_pic", sortKey: "down", title: "出貨" },
  { name: "auto_pic", sortKey: "down", title: "收購" },
  { name: "void_pic", sortKey: "down", title: "退單" },
  { name: "submit_date", sortKey: "down", title: "交單時間" },
  { name: "note", sortKey: "down", title: "備註" },
];

function ShipConfirmPage(props) {
  const [stockSortSelect, setStockSortSelect] = useState("stock_code");
  const [sortStockList, setSortStockList] = useState(stockSortItemList);
  const [searchStockList, setSearchStockList] = useState([]);
  const [stocklist, setStockList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState("");

  const localUser = JSON.parse(sessionStorage.getItem("user"));
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const userLogin = urlParams.get("user_login");
  const userId = urlParams.get("user_id");
  const yearSelected = urlParams.get("year");

  useEffect(() => {
    getShippedShipment();
  }, []);

  async function getShippedShipment() {
    const result = await getAttorneyByUser(userId, yearSelected, localUser.accessToken);
    if (result != null) {
      let tempStocklist = [];
      for (let index = 0; index < result.length; index++) {
        const element = result[index];
        tempStocklist.push({
          stock_code: element.stock_info[0].stock_code,
          stock_name: element.stock_info[0].stock_name,
          gift_item: element.stock_info[0].gift_item,
          acq_price: element.stock_info[0].acq_price,
          auto_pic: element.stock_info[0].auto_pic,
          manual_pic: element.stock_info[0].manual_pic,
          void_pic: element.stock_info[0].void_pic,
          id: element.id,
          auto_pic: element.stock_info[0].auto_pic,
          submit_date: element.submit_date,
          year: element.year,
          confirm: element.confirm,
          note: element.stock_info[0].note,
        });
      }
      setStockList(tempStocklist);
    }
  }

  useEffect(() => {
    if (search != "") {
      let tempStockList;
      if (stocklist != null) {
        tempStockList = stocklist.filter(
          (attorney) =>
            attorney.stock_name.includes(search) ||
            attorney.stock_code.includes(search) ||
            attorney.gift_item.includes(search)
        );
        setSearchStockList(tempStockList);
      }
    } else {
      setSearchStockList([]);
    }
  }, [search]);

  const renderSortIcon = (sortKey) => {
    return sortKey === "down" ? (
      <BiSolidDownArrow className="sort-icon" />
    ) : (
      <BiSolidUpArrow className="sort-icon" />
    );
  };

  const handleStockNameNewLine = (name) => {
    const items = [];

    // 将源字符串按照每行 10 个字符的方式拆分成数组
    for (let i = 0; i < name.length; i += 19) {
      const line = name.substring(i, i + 19);
      items.push(line);
    }

    return items.join("\n");
  };

  const handleAttorneySort = (sortKey) => {
    setStockSortSelect(sortKey);
    handleAttorneySortChange(sortKey);
  };

  const handleAttorneySortChange = (sortKey) => {
    let nextSort = "";
    let tempSortedList = [...stocklist];
    let tempSortItemList = [...sortStockList];
    tempSortItemList.map((content) => {
      if (content.name === sortKey) {
        nextSort = content.sortKey === "down" ? "up" : "down";
        if (
          sortKey === "id" ||
          sortKey === "submit_date" ||
          sortKey === "confirm"
        ) {
          sortByKey(tempSortedList, sortKey, content.sortKey);
        } else {
          sortByKey(tempSortedList, sortKey, content.sortKey);
        }

        content.sortKey = nextSort;
        return content.sortKey;
      }
      return null;
    });
    setSortStockList(tempSortItemList);
    setStockList(tempSortedList);
  };

  const sortByKey = (array, sortKey, sortRule) => {
    if (sortRule === "down") {
      return array.sort((a, b) => {
        return a[sortKey] < b[sortKey] ? -1 : a[sortKey] > b[sortKey] ? 1 : 0;
      });
    } else {
      return array.sort((a, b) => {
        return a[sortKey] > b[sortKey] ? -1 : a[sortKey] < b[sortKey] ? 1 : 0;
      });
    }
  };

  const renderAttorneyList = () => {
    const renderStockList =
      searchStockList.length != 0
        ? searchStockList
        : search != ""
        ? []
        : stocklist;
    return renderStockList != null
      ? renderStockList.map((attorneyItem, index) => {
          return (
            <tr className={`hover height-1 ${index % 2 != 0 ? "odd-row" : ""}`}>
              <td>{index + 1}</td>
              <td>{attorneyItem.stock_code}</td>
              <td>{attorneyItem.stock_name}</td>
              <td className="width-50">
                {attorneyItem.gift_item != null &&
                attorneyItem.gift_item.length > 19
                  ? handleStockNameNewLine(attorneyItem.gift_item)
                      .split("\n")
                      .map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))
                  : attorneyItem.gift_item}
              </td>
              <td className="padding-10">
                {parseInt(attorneyItem.auto_pic) +
                  parseInt(attorneyItem.manual_pic)}
              </td>
              <td className="width-5">{attorneyItem.manual_pic}</td>
              <td className="width-5">{attorneyItem.auto_pic}</td>
              <td className={`width-5`}>{attorneyItem.void_pic}</td>
              <td>
                {attorneyItem.submit_date.split(" ")[0].split("-").join("/")}
              </td>
              <td className="width-40">{attorneyItem.note}</td>
            </tr>
          );
        })
      : "";
  };

  function handleSearchTextChange(e) {
    setSearchText(e.target.value);
    setSearch(e.target.value);
  }

  function handleKeyPress(e) {
    if (e.which === 13) handleSearch();
  }

  function handleSearch() {
    setSearch(searchText);
  }

  return (
    <div className="shipment-root">
      <Nav />
      <div className="manager-background">
        <div className="manager-title">{userLogin + "已確認"}</div>
        <div className="main-display">
          <div className="stock-button-bar-user">
            <MDBInputGroup
              material
              containerClassName="search-block col-12"
              hint="Search.."
              onChange={handleSearchTextChange}
              onKeyPress={handleKeyPress}
              append={
                <button
                  rounded
                  size="sm"
                  type="submit"
                  className="mr-auto search-button"
                  onClick={handleSearch}
                >
                  Search
                </button>
              }
            />
          </div>
          <table>
            <thead>
              <tr>
                <th>編號</th>
                {stockSortItemList.map((content) => {
                  return (
                    <th
                      className="pointer"
                      onClick={() => {
                        handleAttorneySort(content.name);
                      }}
                    >
                      {content.title}
                      {stockSortSelect === content.name
                        ? renderSortIcon(content.sortKey)
                        : null}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>{renderAttorneyList()}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default connect(null, {})(ShipConfirmPage);
