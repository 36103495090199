import { connect } from "react-redux";
import Nav from "../../components/Nav";
import { useState, useEffect } from "react";
import { BiSolidUpArrow, BiSolidDownArrow } from "react-icons/bi";
import { getShippedList } from "../../actions/shipmentAction";
import { getCSV } from "../../actions/adminAction";
import { MDBInputGroup } from "mdbreact";
import "./style.css";

const ShippedSortList = [
  { name: "stock_code", sortKey: "down", title: "股號" },
  { name: "stock_name", sortKey: "down", title: "股名" },
  { name: "gift_item", sortKey: "down", title: "紀念品" },
  { name: "behalf_count", sortKey: "down", title: "出貨" },
  { name: "acqall_count", sortKey: "down", title: "收購" },
  { name: "void_count", sortKey: "down", title: "退單" },
  { name: "real_shipped", sortKey: "down", title: "實出貨" },
  { name: "behalf_price", sortKey: "down", title: "代領費" },
  { name: "behalf_price_total", sortKey: "down", title: "代總" },
  { name: "real_acqcount", sortKey: "down", title: "實收購" },
  { name: "acq_price", sortKey: "down", title: "收購價" },
  { name: "acq_price_total", sortKey: "down", title: "收總" },
  { name: "total_price", sortKey: "down", title: "小計" },
  { name: "attorney_note", sortKey: "down", title: "交單備註" },
  { name: "shipped_id", sortKey: "down", title: "出貨單號" },
  { name: "ship_date", sortKey: "down", title: "出貨日期" },
  { name: "ship_note", sortKey: "down", title: "出貨備註" },
  { name: "gift_category", sortKey: "down", title: "類型" },
];

const UnderShippedSortList = [
  { name: "stock_code", sortKey: "down", title: "股號" },
  { name: "stock_name", sortKey: "down", title: "股名" },
  { name: "gift_item", sortKey: "down", title: "紀念品" },
  { name: "behalf_count", sortKey: "down", title: "出貨" },
  { name: "acqall_count", sortKey: "down", title: "收購" },
  { name: "void_count", sortKey: "down", title: "退單" },
  { name: "real_shipped", sortKey: "down", title: "實出貨" },
  { name: "behalf_price", sortKey: "down", title: "代領費" },
  { name: "behalf_price_total", sortKey: "down", title: "代總" },
  { name: "real_acqcount", sortKey: "down", title: "實收購" },
  { name: "acq_price", sortKey: "down", title: "收購價" },
  { name: "acq_price_total", sortKey: "down", title: "收總" },
  { name: "total_price", sortKey: "down", title: "小計" },
  { name: "attorney_note", sortKey: "down", title: "交單備註" },
  { name: "shipped_id", sortKey: "down", title: "出貨單號" },
  { name: "ship_date", sortKey: "down", title: "出貨日期" },
  { name: "ship_note", sortKey: "down", title: "出貨備註" },
];

function ShippedPage(props) {
  const [shippedList, setShippedList] = useState([]);
  const [ShippedSortSelect, setShippedSortSelect] = useState("stock_name");
  const [sortShippedList, setSortShippedList] = useState(ShippedSortList);
  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState("");
  const [searchSortedList, setSearchSortedList] = useState([]);
  // const [feeData, setFeeData] = useState(null)

  const localUser = JSON.parse(sessionStorage.getItem("user"));
  const feeData = JSON.parse(sessionStorage.getItem("feeData"));
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const userLogin = urlParams.get("user_login");
  const year = urlParams.get("year");

  // function handleMessage(event) {
  //   if (event.data && event.data.type === 'myAppMessage') {
  //     const data = event.data;
  //     console.log(data.delivery_fee, data.program_fee);
  //     if(data) {
  //       setFeeData({
  //         delivery_fee: data.delivery_fee,
  //         program_fee: data.program_fee
  //       })
  //       window.removeEventListener('message', handleMessage);
  //     }
  //   }
  // };

  // window.addEventListener('message', handleMessage);

  useEffect(() => {
    getShippedShipment();
  }, []);

  useEffect(() => {
    if (search != "") {
      let tempSortedList;
      if (shippedList != null) {
        tempSortedList = shippedList.filter(
          (unShip) =>
            unShip.stock_code.includes(search) ||
            unShip.stock_name.includes(search) ||
            unShip.gift_item.includes(search)
        );
        setSearchSortedList(tempSortedList);
      }
    } else {
      setSearchSortedList([]);
    }
  }, [search, sortShippedList]);

  async function getShippedShipment() {
    const result = await getShippedList(userLogin, year, localUser.accessToken);
    if (result != null) {
      setShippedList(result);
    }
  }

  const handleShippedSort = (sortKey) => {
    setShippedSortSelect(sortKey);
    handleShippedSortChange(sortKey);
  };

  const handleShippedSortChange = (sortKey) => {
    let nextSort = "";
    let tempSortedList = [...shippedList];
    let tempSortItemList = [...sortShippedList];
    tempSortItemList.map((content) => {
      if (content.name === sortKey) {
        nextSort = content.sortKey === "down" ? "up" : "down";
        sortByKey(tempSortedList, sortKey, content.sortKey);
        content.sortKey = nextSort;
        return content.sortKey;
      }
      return null;
    });
    setSortShippedList(tempSortItemList);
    setShippedList(tempSortedList);
  };

  const sortByKey = (array, sortKey, sortRule) => {
    if (sortRule === "down") {
      return array.sort((a, b) => {
        return a[sortKey] < b[sortKey] ? -1 : a[sortKey] > b[sortKey] ? 1 : 0;
      });
    } else {
      return array.sort((a, b) => {
        return a[sortKey] > b[sortKey] ? -1 : a[sortKey] < b[sortKey] ? 1 : 0;
      });
    }
  };

  const renderSortIcon = (sortKey) => {
    return sortKey === "down" ? (
      <BiSolidDownArrow className="sort-icon" />
    ) : (
      <BiSolidUpArrow className="sort-icon" />
    );
  };

  function padId(id) {
    const strId = String(id);
    const paddingLength = 8 - strId.length;

    if (paddingLength <= 0) {
      return strId;
    }

    const paddingZeros = "0".repeat(paddingLength);
    return paddingZeros + strId;
  }

  function shippedPadId(id) {
    const strId = String(id);
    const paddingLength = 8 - strId.length;

    if (paddingLength <= 0) {
      return strId;
    }

    const paddingZeros = "0".repeat(paddingLength);
    return "S" + paddingZeros + strId;
  }

  function formateTime(time) {
    const originalDate = new Date(time);

    // 提取年、月、日
    const year = originalDate.getFullYear().toString().slice(2); // 提取后两位
    const month = (originalDate.getMonth() + 1).toString().padStart(2, "0"); // 月份从0开始
    const day = originalDate.getDate().toString().padStart(2, "0");

    // 格式化为 "YY/MM/DD" 的字符串
    const formattedDateString = `${year}/${month}/${day}`;
    if (time == "0000/00/00") {
      return "00/00/00";
    } else {
      return formattedDateString;
    }
  }

  async function handleDownLoadCSV(shipped_id) {
    const result = await getCSV(
      "shipped",
      0,
      localUser.id,
      shipped_id,
      localUser.accessToken
    );
    if (result) {
      const blob = new Blob(["\ufeff" + result], {
        type: "text/csv;charset=utf-8",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = shippedPadId(shipped_id) + "_table.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  function handleSearchTextChange(e) {
    setSearchText(e.target.value);
    setSearch(e.target.value);
  }

  function handleKeyPress(e) {
    if (e.which === 13) handleSearch();
  }

  function handleSearch() {
    setSearch(searchText);
  }

  const renderTable = () => {
    const renderShipedList =
      searchSortedList.length != 0
        ? searchSortedList
        : search != ""
        ? []
        : shippedList;
    if (renderShipedList) {
      return renderShipedList.map((shipment, index) => {
        return (
          <tr
            key={index}
            className={`hover ${index % 2 != 0 ? "odd-row" : ""}`}
          >
            <td>{index + 1}</td>
            <td>{shipment.stock_code}</td>
            <td>{shipment.stock_name}</td>
            <td>{shipment.gift_item}</td>
            <td>{shipment.behalf_count}</td>
            <td>{shipment.acqall_count}</td>
            <td>{shipment.void_count}</td>
            <td>{shipment.real_shipped}</td>
            <td>{shipment.behalf_price}</td>
            <td>{shipment.behalf_price_total}</td>
            <td>{shipment.real_acqcount}</td>
            <td>{shipment.acq_price}</td>
            <td>{shipment.acq_price_total}</td>
            <td>{shipment.total_price}</td>
            <td className="width-30">{shipment.attorney_note}</td>
            <td
              className="pointer button-text"
              onClick={() => handleDownLoadCSV(shipment.shipped_id)}
            >
              {shippedPadId(shipment.shipped_id)}
            </td>
            <td>
              {formateTime(
                shipment.ship_date.split(" ")[0].split("-").join("/")
              )}
            </td>
            <td>{shipment.ship_note}</td>
            {localUser && localUser.account_level === 1 ? (
              <td>{shipment.gift_category}</td>
            ) : (
              ""
            )}
          </tr>
        );
      });
    }
  };

  const renderTotalForm = () => {
    return(
      <table className="total-table-form">
        <thead>
          <tr>
            <th>小計</th>
            <th>程式費</th>
            <th>運費</th>
            <th>總計</th>
        </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {shippedList.reduce(
                (sum, shipment) => sum + Number(shipment.total_price),
                0
              )}
            </td>
            <td>
              {feeData ? feeData.program_fee : 0}
            </td>
            <td>
              {feeData ? feeData.delivery_fee : 0}
            </td>
            <td>
              {feeData ?  shippedList.reduce(
                (sum, shipment) => sum + Number(shipment.total_price),
                0
              ) - feeData.delivery_fee - feeData.program_fee : 0}
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  const renderTotalRow = () => {
    const renderShipedList =
    searchSortedList.length != 0
      ? searchSortedList
      : search != ""
      ? []
      : shippedList;
      return(
        <tr>
          <td className="bold">{"總計"}</td>
          <td></td>
          <td></td>
          <td></td>
          <td className="bold">
            {renderShipedList.reduce(
              (sum, shipment) => sum + Number(shipment.behalf_count),
              0
            )}
          </td>
          <td className="bold">
            {renderShipedList.reduce(
              (sum, shipment) => sum + Number(shipment.acqall_count),
              0
            )}
          </td>
          <td className="bold">
            {renderShipedList.reduce(
              (sum, shipment) => sum + Number(shipment.void_count),
              0
            )}
          </td>
          <td className="bold">
            {renderShipedList.reduce(
              (sum, shipment) => sum + Number(shipment.real_shipped),
              0
            )}
          </td>
          <td></td>
          <td className="bold">
            {renderShipedList.reduce(
              (sum, shipment) => sum + Number(shipment.behalf_price_total),
              0
            )}
          </td>
          <td className="bold">
            {renderShipedList.reduce(
              (sum, shipment) => sum + Number(shipment.real_acqcount),
              0
            )}
          </td>
          <td></td>
          <td className="bold">
            {renderShipedList.reduce(
              (sum, shipment) => sum + Number(shipment.acq_price_total),
              0
            )}
          </td>
          <td className="bold">
            {renderShipedList.reduce(
              (sum, shipment) => sum + Number(shipment.total_price),
              0
            )}
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      )
  }

  return (
    <div className="shipment-root">
      <Nav />
      <div className="manager-background">
        <div className="manager-title">{userLogin + "已出貨"}</div>
        <div className="main-display">
          <div className="stock-button-bar-user">
            <MDBInputGroup
              material
              containerClassName="search-block col-12"
              hint="Search.."
              onChange={handleSearchTextChange}
              onKeyPress={handleKeyPress}
              append={
                <button
                  rounded
                  size="sm"
                  type="submit"
                  className="mr-auto search-button"
                  onClick={handleSearch}
                >
                  Search
                </button>
              }
            />
          </div>
          {renderTotalForm()}
          <table>
            <thead>
              <tr>
                <th>編號</th>
                {localUser && localUser.account_level === 1
                  ? ShippedSortList.map((content) => {
                      return (
                        <th
                          onClick={() => {
                            handleShippedSort(content.name);
                          }}
                        >
                          {content.title}
                          {ShippedSortSelect === content.name
                            ? renderSortIcon(content.sortKey)
                            : null}
                        </th>
                      );
                    })
                  : UnderShippedSortList.map((content) => {
                      return (
                        <th
                          onClick={() => {
                            handleShippedSort(content.name);
                          }}
                        >
                          {content.title}
                          {ShippedSortSelect === content.name
                            ? renderSortIcon(content.sortKey)
                            : null}
                        </th>
                      );
                    })}
              </tr>
            </thead>
            <tbody>
              {renderTotalRow()}
              {renderTable()}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default connect(null, {})(ShippedPage);
