
  export const souvenirInventoryData = [
    {
      suggestion: "必",
      share_price: 31.2,
      code: 1101,
      name: "台泥",
      year_113: "環保餐具組",
      price_113: 8,
      year_112: "分隔便當盒",
      price_112: 40,
      year_111: "環保不鏽鋼保溫瓶",
      price_111: 25,
      year_110: "竹蓋玻璃保鮮盒三件組",
      year_109: "竹蓋環保垃圾桶",
      year_108: "晶采生活四件組",
      year_107: "不鏽鋼美形保溫瓶",
    },
    {
      suggestion: "必",
      share_price: 17.15,
      code: 1103,
      name: "嘉泥",
      year_113: "NAKAMURA環保置衣籃",
      price_113: 15,
      year_112: "石墨烯護膝",
      price_112: 20,
      year_111: "飛狼石墨烯頭戴及護腕",
      price_111: 10,
      year_110: "輕旅行收納袋",
      year_109: "ADVITAM香氛旅行組",
      year_108: "SOGA可愛長頸鹿杯4入含杯架",
      year_107: "梅森陶瓷加蓋杯(附湯匙)",
    },
    {
      share_price: 28.45,
      code: 1104,
      name: "環泥",
      year_113: "",
      price_113: "",
      year_112: "卡-711卡50元",
      price_112: 35,
    },
    {
      suggestion: "必",
      share_price: 17.95,
      code: 1201,
      name: "味全",
      year_113: "料理高手-蔬果本味調味料",
      price_113: 20,
      year_112: "貝納頌咖啡",
      price_112: 20,
      year_111: "貝納頌咖啡",
      price_111: 30,
      year_110: "貝納頌咖啡",
      year_109: "貝納頌咖啡",
      year_108: "貝納頌咖啡",
      year_107: "貝納頌濾掛咖啡乙盒",
    },
    {
      suggestion: "必",
      share_price: 36.45,
      code: 1203,
      name: "味王",
      year_113: "威士忌桶麵(3碗)",
      price_113: 20,
      year_112: "鮭魚藜麥粥",
      price_112: 20,
      year_111: "惦惦吃拌麵",
      price_111: 25,
      year_110: "XO梅甘醬油禮盒",
      year_109: "XO醬油禮盒二入",
      year_108: "XO醬油禮盒",
      year_107: "醬油禮盒二入",
    },
    {
      suggestion: "必",
      share_price: 95,
      code: 1215,
      name: "卜蜂",
      year_113: "雞肉鬆乙包",
      price_113: 15,
      year_112: "雞肉鬆",
      price_112: 18,
      year_111: "雞肉鬆",
      price_111: 20,
      year_110: "雞肉鬆",
      year_109: "雞肉鬆",
      year_108: "雞肉鬆",
      year_107: "雞肉鬆",
    },
    {
      suggestion: "必",
      share_price: 11.95,
      code: 1217,
      name: "愛之味",
      year_113: "愛之味鮪魚片2罐",
      price_113: 35,
      year_112: "愛之味韓式泡菜3罐",
      price_112: 30,
      year_111: "愛之味水煮鮪魚3罐",
      price_111: 50,
      year_110: "愛之味忘不了核桃燕麥粥",
      year_109: "愛之味花生八寶3瓶",
      year_108: "愛之味春心茶4瓶",
      year_107: "丹東紅御膳貢粥3瓶",
    },
    {
      suggestion: "必",
      share_price: 19.65,
      code: 1218,
      name: "泰山",
      year_113: "泰山仙草冬瓜露 3入",
      price_113: 25,
      year_112: "泰山花生仁湯4入",
      price_112: 30,
      year_111: "泰山燕麥奶花生(3入)",
      price_111: 25,
      year_110: "泰山玄米油600ml",
      year_109: "皂顧 艾草平安皂禮組 (二入)",
      year_108: "泰山花生仁湯4入組",
      year_107: "機能飲料組(四入裝)",
    },
    {
      suggestion: "可",
      share_price: 14.95,
      code: 1219,
      name: "福壽",
      year_113: "",
      price_113: "",
      year_112: "福壽香油",
      price_112: 15,
      year_109: "福壽香油",
    },
    {
      suggestion: "必",
      share_price: 17,
      code: 1220,
      name: "台榮",
      year_113: "驅塵氏雙軌密封夾鏈袋 10入",
      price_113: 6,
      year_112: "驅塵夾鏈袋",
      price_112: 5,
    },
    {
      share_price: 41,
      code: 1234,
      name: "黑松",
      year_113: "",
      price_113: "",
      year_107: "黑松PET瓶四入",
    },
    {
      share_price: 23,
      code: 1236,
      name: "宏亞",
      year_113: "",
      price_113: "",
      year_107: "本公司產品",
    },
    {
      share_price: 11.05,
      code: 1304,
      name: "台聚",
      year_113: "",
      price_113: "",
      year_109: "USii高效鎖鮮袋組合包",
      year_108: "USii高效鎖鮮袋組合包",
      year_107: "USii優系高效鎖鮮袋",
    },
    {
      share_price: 11.9,
      code: 1305,
      name: "華夏",
      year_113: "",
      price_113: "",
      year_108: "USii高效鎖鮮袋組合包",
    },
    {
      share_price: 13.6,
      code: 1308,
      name: "亞聚",
      year_113: "",
      price_113: "",
      year_111: "USii 優系高效鎖鮮袋組合包",
      price_111: 20,
      year_109: "USii高效鎖鮮袋組合包",
      year_108: "USii高效鎖鮮袋組合包",
      year_107: "USii優系高效鎖鮮袋",
    },
    {
      suggestion: "必",
      share_price: 13,
      code: 1309,
      name: "台達化",
      year_113: "廚房神器-USii優系高效鎖鮮袋",
      price_113: 8,
      year_112: "USii優系高效鎖鮮袋組合包",
      price_112: 15,
      year_111: "USii優系高效鎖鮮袋組合包",
      price_111: 20,
      year_107: "USii優系高效鎖鮮袋",
    },
    {
      suggestion: "正身",
      share_price: 9.96,
      code: 1310,
      name: "台苯",
      year_113: "甲魚全沛全方位維他命",
      price_113: 10,
      year_112: "卡-711卡50元",
      price_112: 32,
      year_111: "卡-711卡50元",
      price_111: 34,
      year_110: "卡-711卡50元",
      year_108: "愛禮不鏽鋼提鍋",
      year_107: "菲司耐熱玻璃濾壓壺",
    },
    {
      suggestion: "必",
      share_price: 10.95,
      code: 1312,
      name: "國喬",
      year_113: "鍋寶不鏽鋼雙層手提餐盒",
      price_113: 50,
      year_112: "多功能導磁盤",
      price_112: 90,
      year_111: "蜂王橄欖潤澤草本皀二入",
      price_111: 6,
      year_110: "蜂王乳油木果皂二入",
      year_109: "香皂禮盒",
      year_108: "香皂禮盒",
      year_107: "香皂禮盒",
    },
    {
      suggestion: "正身",
      share_price: 7.25,
      code: 1314,
      name: "中石化",
      year_113: "不鏽鋼單柄鍋",
      price_113: 40,
      year_112: "五件式修容組",
      price_112: 10,
      year_111: "不鏽鋼智能保溫瓶",
      price_111: 35,
      year_110: "真空不鏽鋼隨身瓶",
      year_109: "不鏽鋼單柄小湯鍋",
      year_108: "真空不鏽鋼隨手杯",
      year_107: "真空不鏽鋼保溫瓶",
    },
    {
      suggestion: "必",
      share_price: 14.25,
      code: 1316,
      name: "上曜",
      year_113: "葉黃素魚油軟膠囊一包10粒",
      price_113: 5,
      year_112: "洋甘菊皂 三入 黃盒",
      price_112: 8,
      year_111: "毛寶繽紛花園抗菌洗手乳",
      price_111: 12,
      year_110: "折疊小板凳",
      year_109: "沙威隆抗菌洗手露",
      year_108: "保溫保冷袋",
      year_107: "蜂王乳油木果精華皂三入組",
    },
    {
      share_price: 12.55,
      code: 1324,
      name: "地球",
      year_113: "正字絕緣膠帶一捲",
      price_113: 3,
      year_108: "地球膠帶一捲",
      year_107: "地球膠帶一捲",
    },
    {
      suggestion: "必",
      share_price: 34.85,
      code: 1325,
      name: "恆大",
      year_113: "立體醫用口罩壹盒",
      price_113: 5,
      year_112: "口罩一盒",
      price_112: 5,
      year_111: "口罩20片",
      price_111: 5,
      year_110: "口罩20片",
    },
    {
      share_price: 23.55,
      code: 1336,
      name: "台翰",
      year_113: "",
      price_113: "",
      year_109: "Exocare 緊緻羽絲縷面膜",
      year_108: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 6.16,
      code: 1337,
      name: "再生",
      year_113: "居慕洗手液",
      price_113: 12,
      year_112: "居慕茶樹洗手液",
      price_112: 12,
      year_111: "居慕洋甘菊洗手液",
      price_111: 12,
      year_110: "蜂王植萃精華皂二入盒",
      year_109: "蜂王 橄欖保濕草本皂",
      year_108: "蜂王植萃皂",
      year_107: "生物降解環保垃圾袋",
    },
    {
      suggestion: "必",
      share_price: 29.15,
      code: 1338,
      name: "廣華",
      year_113: "卡-711卡100元",
      price_113: 83,
      year_112: "卡-711卡100元",
      price_112: 81,
      year_111: "卡-711卡100元",
      price_111: 82,
      year_110: "卡-711卡100元",
      year_109: "卡-711卡100元",
      year_108: "卡-711卡100元",
      year_107: "卡-全家200元",
    },
    {
      suggestion: "必",
      share_price: 8.7,
      code: 1340,
      name: "勝悅",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "全家禮物卡50元",
      price_111: 39,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "50元超商券(等值台幣)",
      year_107: "卡-711卡50元",
    },
    {
      share_price: 69.3,
      code: 1341,
      name: "富林",
      year_113: "",
      price_113: "",
      year_108: "卡-711卡100元",
    },
    {
      share_price: 14.95,
      code: 1409,
      name: "新纖",
      year_113: "",
      price_113: "",
      year_112: "紳士襪",
      price_112: 10,
      year_107: "超細纖維眼鏡布",
    },
    {
      suggestion: "必",
      share_price: 25.6,
      code: 1414,
      name: "東和",
      year_113: "襪子",
      price_113: 10,
      year_112: "脖圍",
      price_112: 5,
      year_111: "五趾襪",
      price_111: 12,
      year_110: "手套",
      year_109: "襪子",
      year_108: "襪子",
      year_107: "襪子",
    },
    {
      suggestion: "正文",
      share_price: 12.85,
      code: 1416,
      name: "廣豐",
      year_113: "毛巾",
      price_113: 5,
      year_112: "毛巾",
      price_112: 5,
      year_111: "毛巾",
      price_111: 5,
      year_110: "毛巾",
      year_109: "毛巾",
      year_108: "毛巾",
      year_107: "毛巾",
    },
    {
      suggestion: "可",
      share_price: 9.05,
      code: 1417,
      name: "嘉裕",
      year_113: "皂-洗臉馬卡龍",
      price_113: 3,
      year_112: "拭鏡布",
      price_112: 5,
      year_111: "拭鏡布",
      price_111: 5,
      year_110: "竹纖維口罩套",
      year_109: "竹纖維口罩套",
      year_108: "卡-711卡50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 27,
      code: 1418,
      name: "東華",
      year_113: "手機架",
      price_113: 5,
      year_112: "原子筆一組",
      price_112: 5,
      year_111: "口罩",
      price_111: 5,
      year_110: "潤膚皂",
      year_109: "蜂王草本抗菌皂 二入",
      year_108: "天然木質甘蔗纖維吸管",
      year_107: "多用途抹布組",
    },
    {
      share_price: 42.25,
      code: 1419,
      name: "新紡",
      year_113: "",
      price_113: "",
      year_109: "高級面紙套",
    },
    {
      suggestion: "必",
      share_price: 36.95,
      code: 1423,
      name: "利華",
      year_113: "不鏽鋼玻璃定量油壺",
      price_113: 6,
      year_112: "伊莎貝爾櫻花皂一入",
      price_112: 5,
      year_111: "酒精噴霧筆",
      price_111: 5,
      year_110: "日式保溫保冷袋",
      year_109: "伊莎貝爾香皂 一入",
      year_108: "手機支架觸控筆1枝",
      year_107: "蘭麗綿羊香皂三入",
    },
    {
      suggestion: "必",
      share_price: 21.3,
      code: 1432,
      name: "大魯閣",
      year_113: "卡-大魯閣APP優惠線上發",
      price_113: 30,
      year_112: "券-APP領",
      price_112: 20,
      year_111: "700元消費抵用券及7張每月玩樂買1送1券",
      price_111: 5,
      year_110: "600元大魯閣集團消費抵用券",
    },
    {
      suggestion: "必",
      share_price: 38,
      code: 1435,
      name: "中福",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_111: "康寧二件式餐碗組",
      price_111: 35,
      year_110: "康寧二件式餐碗組",
      year_109: "抽取式面紙",
      year_108: "抽取式面紙",
      year_107: "水晶肥皂粉體",
    },
    {
      suggestion: "必",
      share_price: 13.85,
      code: 1440,
      name: "南紡",
      year_113: "",
      price_113: "",
      year_111: "KOLLOS洸動能護芯襪",
      price_111: 25,
      year_108: "統一好勁道麵條",
    },
    {
      suggestion: 2,
      share_price: 31,
      code: 1443,
      name: "立益",
      year_113: "",
      price_113: "",
      year_111: "全家禮物卡50元",
      price_111: 37,
    },
    {
      suggestion: "必",
      share_price: 19,
      code: 1449,
      name: "佳和",
      year_113: "佳和4D立體醫用口罩5片",
      price_113: 3,
      year_112: "口罩10片",
      price_112: 5,
      year_111: "口罩10片",
      price_111: 5,
      year_110: "口罩10片",
      year_109: "多功能超細纖維擦拭布",
      year_108: "多功能超細纖維擦拭布",
      year_107: "多功能超細纖維擦拭布",
    },
    {
      suggestion: "必",
      share_price: 14.6,
      code: 1452,
      name: "宏益纖",
      year_113: "皂-蜂王洋甘菊潤膚皂二入 70g",
      price_113: 5,
      year_112: "伊莎貝爾潔手慕絲",
      price_112: 12,
      year_111: "抗菌石鹼80克 二入",
      price_111: 5,
      year_110: "香皂",
      year_109: "抗菌石鹼 二入",
      year_108: "高露潔口腔清潔旅行組",
      year_107: "蜂王乳油木果精華皂2入",
    },
    {
      suggestion: "正文",
      share_price: 17.8,
      code: 1464,
      name: "得力",
      year_113: "",
      price_113: "",
      year_112: "卡-711卡50元",
      price_112: 33,
      year_109: "卡-711卡50元",
    },
    {
      share_price: 12.45,
      code: 1466,
      name: "聚隆",
      year_113: "",
      price_113: "",
      year_110: "防護口罩1盒(6入)",
      year_107: "魔乾超細纖維擦拭布",
    },
    {
      suggestion: "必",
      share_price: 12.9,
      code: 1471,
      name: "首利",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_110: "卡-711卡35元",
      year_109: "卡-711卡35元",
      year_108: "卡-711卡35元",
      year_107: "玻璃保鮮盒",
    },
    {
      share_price: 12.35,
      code: 1474,
      name: "弘裕",
      year_113: "",
      price_113: "",
      year_107: "時尚環保編織袋",
    },
    {
      suggestion: "可",
      share_price: 175,
      code: 1503,
      name: "士電",
      year_113: "",
      price_113: "",
      year_112: "伸縮軟管手電筒",
      price_112: 18,
      year_109: "拓荒者360度智慧人體感應燈",
    },
    {
      suggestion: "必",
      share_price: 55.1,
      code: 1504,
      name: "東元",
      year_113: "卡-餐品兌換券 (摩斯/樂雅樂)",
      price_113: 40,
      year_112: "摩斯券",
      price_112: 35,
      year_110: "康寧餐碗組二入",
      year_109: "摩斯 樂雅樂 餐券",
      year_107: "摩斯兌換券",
    },
    {
      share_price: 12.25,
      code: 1506,
      name: "正道",
      year_113: "",
      price_113: "",
      year_111: "蜂王燕麥肌膚舒緩柔膚皂",
      price_111: 5,
      year_109: "蜂王草本抗菌皂二入",
      year_108: "蜂王植萃皂2入",
      year_107: "環保餐具組",
    },
    {
      suggestion: "正身",
      share_price: 7,
      code: 1512,
      name: "瑞利",
      year_113: "卡-711卡50元",
      price_113: 0,
      year_111: "毛寶抗菌洗衣精",
      year_109: "乾洗手液",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 152,
      code: 1513,
      name: "中興電",
      year_113: "皂-蜂和日麗洋甘菊親膚皂三入 80g",
      price_113: 10,
      year_112: "手工皂禮盒",
      price_112: 20,
      year_111: "護手霜套組",
      price_111: 30,
      year_110: "精油禮盒",
      year_109: "艾草手工皂",
      year_108: "洗碗精",
      year_107: "中興電 迷迭香皂2入",
    },
    {
      suggestion: "必",
      share_price: 96.7,
      code: 1514,
      name: "亞力",
      year_113: "料理海鹽",
      price_113: 8,
      year_112: "3合1充電線",
      price_112: 15,
      year_111: "幸運草水晶碗",
      price_111: 10,
      year_110: "台鹽鹹淨晶亮牙膏",
      year_109: "蜂王抗菌皂三入",
      year_108: "放大鏡指甲剪",
      year_107: "蜂王果油潤膚茶皂2入",
    },
    {
      suggestion: "必",
      share_price: 17.25,
      code: 1516,
      name: "川飛",
      year_113: "卡-全家35元",
      price_113: 26,
      year_112: "卡-全家35元",
      price_112: 25,
      year_111: "卡-全家50元",
      price_111: 39,
    },
    {
      suggestion: "正文",
      share_price: 13.55,
      code: 1517,
      name: "利奇",
      year_113: "皂-乳油木果皂二入粉盒",
      price_113: 5,
      year_112: "臺灣茶摳蜂膠皂四入盒",
      price_112: 15,
      year_111: "依必朗 抗菌洗手乳 220ml",
      price_111: 10,
      year_110: "抗菌洗手乳",
      year_109: "沙威隆抗菌洗手乳",
      year_108: "閃字風扇",
      year_107: "環保提袋",
    },
    {
      share_price: 26.65,
      code: 1521,
      name: "大億",
      year_113: "",
      price_113: "",
      year_112: "卡-全家50元",
      price_112: 39,
    },
    {
      suggestion: "必",
      share_price: 39.7,
      code: 1524,
      name: "耿鼎",
      year_113: "百利菜瓜布",
      price_113: 5,
      year_112: "菜瓜布",
      price_112: 5,
      year_111: "菜瓜布",
      price_111: 5,
      year_110: "菜瓜布",
      year_109: "菜瓜布",
      year_108: "菜瓜布",
      year_107: "百利菜瓜布",
    },
    {
      suggestion: "必",
      share_price: 23.9,
      code: 1526,
      name: "日馳",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-全家中美式",
      price_112: 12,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-全中美式",
      year_107: "環保袋",
    },
    {
      suggestion: "必",
      share_price: 42,
      code: 1527,
      name: "鑽全",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 14.55,
      code: 1528,
      name: "恩德",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_109: "卡-711卡50元",
      year_108: "蘭麗綿羊皂三入",
      year_107: "阿隆索橄欖油",
    },
    {
      suggestion: "必",
      share_price: 61.2,
      code: 1536,
      name: "和大",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_109: "卡-711卡50元",
    },
    {
      share_price: 28,
      code: 1538,
      name: "正峰新",
      year_113: "",
      price_113: "",
      year_111: "多功能衣架",
      price_111: 5,
      year_110: "多功能衣架",
      year_109: "LED手電筒",
      year_108: "小麥餐具三件組",
      year_107: "水果刀加削皮器",
    },
    {
      suggestion: "必",
      share_price: 32.4,
      code: 1540,
      name: "喬福",
      year_113: "皂-伊莎貝爾櫻花皂 一入 75g",
      price_113: 3,
      year_112: "伊莎櫻花皂一入",
      price_112: 5,
      year_111: "伊莎貝爾抗菌香皂",
      price_111: 5,
      year_110: "抗菌清潔皂一入",
      year_109: "沙威隆抗菌清潔皂",
      year_108: "蜂王漢方植萃美肌皂80G",
      year_107: "蜂王天然無患子珍珠皂90G",
    },
    {
      suggestion: "可",
      share_price: 106.5,
      code: 1558,
      name: "伸興",
      year_113: "卡-711卡100元",
      price_113: 83,
      year_110: "針線盒",
      year_107: "精美縫紉附件盒",
    },
    {
      suggestion: "可",
      share_price: 61,
      code: 1563,
      name: "巧新",
      year_113: "卡-711卡50元",
      price_113: 38,
    },
    {
      suggestion: "必",
      share_price: 191,
      code: 1565,
      name: "精華",
      year_113: "醫之方晶采金盞花葉黃素 30錠",
      price_113: 70,
      year_112: "醫之方葉黃素30錠",
      price_112: 50,
      year_111: "醫之方晶采金盞花葉黃素 30錠",
      price_111: 20,
    },
    {
      suggestion: "正文",
      share_price: 27.75,
      code: 1570,
      name: "力肯",
      year_113: "卡-711卡100元",
      price_113: 83,
      year_112: "卡-711中美式",
      price_112: 15,
      year_111: "卡-711卡50元",
      price_111: 34,
    },
    {
      suggestion: "必",
      share_price: 155.5,
      code: 1580,
      name: "新麥",
      year_113: "卡-711中美式/現萃茶卡",
      price_113: 17,
      year_112: "卡-711中美式",
      price_112: 18,
      year_111: "卡-711中美式",
      price_111: 18,
      year_110: "卡-711中美式",
    },
    {
      suggestion: "必",
      share_price: 101,
      code: 1582,
      name: "信錦",
      year_113: "卡-711卡100元",
      price_113: 83,
      year_112: "卡-711卡100元",
      price_112: 81,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 27.2,
      code: 1584,
      name: "精剛",
      year_113: "甲魚全沛全方位維他命",
      price_113: 10,
      year_112: "甲魚全沛全方位維他命",
      price_112: 20,
      year_107: "neoflam運動水壺二入",
    },
    {
      suggestion: "可",
      share_price: 38.1,
      code: 1589,
      name: "永冠",
      year_113: "卡-全家50元",
      price_113: 39,
      year_111: "卡-711卡50元",
      price_111: 37,
    },
    {
      suggestion: "可",
      share_price: 52,
      code: 1591,
      name: "駿吉",
      year_113: "卡-全家50元",
      price_113: 56,
    },
    {
      suggestion: "必",
      share_price: 28.25,
      code: 1604,
      name: "聲寶",
      year_113: "雨傘",
      price_113: 25,
      year_112: "雨傘",
      price_112: 35,
      year_111: "雨傘",
      price_111: 30,
      year_110: "雨傘",
      year_109: "雨傘",
      year_108: "雨傘",
      year_107: "雨傘",
    },
    {
      suggestion: "必",
      share_price: 23.7,
      code: 1605,
      name: "華新",
      year_113: "米-千金米一包-1公斤",
      price_113: 22,
      year_112: "千金米1公斤",
      price_112: 20,
      year_111: "日式瓷碗二入",
      price_111: 10,
      year_110: "千金米 1公斤",
      year_109: "千金米1公斤",
      year_108: "千金米 1公斤",
      year_107: "LED露營燈一個",
    },
    {
      suggestion: "必",
      share_price: 40.7,
      code: 1609,
      name: "大亞",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 15,
      code: 1611,
      name: "中電",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-全家50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "可",
      share_price: 49.85,
      code: 1702,
      name: "南僑",
      year_113: "",
      price_113: "",
      year_111: "水晶肥皂液體櫻花百合1.2 kg",
      price_111: 50,
      year_110: "水晶肥皂液體檸檬香茅1.2g",
      year_109: "水晶肥皂差旅組",
      year_108: "水晶肥皂液體－檸檬香茅單入禮盒",
      year_107: "水晶肥皂洗衣用液體 兩入",
    },
    {
      suggestion: "必",
      share_price: 149.5,
      code: 1707,
      name: "葡萄王",
      year_113: "葡萄王健力蟲草王60粒",
      price_113: 50,
      year_112: "葡萄王纖樂益菌 盒",
      price_112: 40,
      year_111: "葡萄王晚安益生菌膠囊(20粒/瓶)",
      price_111: 60,
      year_110: "葡萄王御品雲芝王菌絲體膠囊(30粒/瓶)",
      year_109: "葡萄王益菌護膚皂",
      year_108: "葡萄王巴西蘑菇菌絲體膠囊",
      year_107: "葡萄王多醣靈芝王膠囊30粒",
    },
    {
      suggestion: "必",
      share_price: 37,
      code: 1708,
      name: "東鹼",
      year_113: "小蘇打粉",
      price_113: 8,
      year_112: "南僑水晶皂-綠盒",
      price_112: 25,
      year_111: "清潔劑二入",
      price_111: 15,
      year_110: "彈跳真空保溫瓶",
      year_109: "南僑水晶肥皂三入",
      year_108: "麗仕香皂禮盒6入",
      year_107: "麗仕香皂禮盒六入",
    },
    {
      suggestion: "可",
      share_price: 20.2,
      code: 1709,
      name: "和益",
      year_113: "德菲雅掛耳式咖啡三入",
      price_113: 10,
    },
    {
      suggestion: "必",
      share_price: 21.65,
      code: 1711,
      name: "永光",
      year_113: "伊莎貝爾尤加利潔手慕斯 250 ml",
      price_113: 12,
      year_112: "米500 g",
      price_112: 10,
      year_111: "皇家穀堡米 500 g",
      price_111: 8,
      year_110: "抗菌皂",
      year_109: "圓型保鮮盒",
      year_108: "樂美雅8吋方深盤",
      year_107: "折疊購物袋",
    },
    {
      suggestion: "必",
      share_price: 46,
      code: 1712,
      name: "興農",
      year_113: "楓康吳羽保鮮膜乙支",
      price_113: 25,
      year_112: "一滴淨",
      price_112: 20,
      year_111: "一滴淨抗菌洗手乳",
      price_111: 18,
      year_110: "一滴淨抗菌洗手乳",
      year_109: "楓康吳羽保鮮膜1支",
      year_108: "一滴淨免浸泡洗衣槽劑1盒",
      year_107: "楓康密實袋1支",
    },
    {
      share_price: 52.1,
      code: 1713,
      name: "國化",
      year_113: "",
      price_113: "",
      year_112: "放大鏡指甲剪",
      price_112: 5,
      year_111: "酒精噴霧筆",
      price_111: 5,
      year_110: "手機支架筆",
    },
    {
      suggestion: "必",
      share_price: 8.03,
      code: 1714,
      name: "和桐",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "卡-全家50元",
      price_112: 33,
      year_111: "卡-全家50元",
      price_111: 34,
      year_108: "多芬沐浴乳(400g)",
      year_107: "高露潔牙膏(160g*2入)",
    },
    {
      suggestion: "必",
      share_price: 7.37,
      code: 1718,
      name: "中纖",
      year_113: "日月養生滴魚精",
      price_113: 35,
      year_112: "燕窩銀耳飲",
      price_112: 30,
      year_111: "燕窩銀耳飲",
      price_111: 30,
      year_110: "日月養生滴魚精",
      year_109: "極品銀耳燕窩飲二入",
      year_108: "樟芝菌絲體多醣飲",
      year_107: "樟芝菌絲體多醣飲",
    },
    {
      suggestion: "必",
      share_price: 15.15,
      code: 1721,
      name: "三晃",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "卡-全家50元",
      price_112: 39,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-全家50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 51.2,
      code: 1722,
      name: "台肥",
      year_113: "深海健康伴手禮(1包鹽+1瓶水)",
      price_113: 15,
      year_112: "藻健康禮盒",
      price_112: 20,
      year_111: "深海健康伴手禮",
      price_111: 50,
      year_110: "深海健康伴手禮",
      year_109: "深海健康伴手禮",
      year_108: "深海健康伴手禮",
      year_107: "海洋深層水健康禮盒",
    },
    {
      suggestion: "可",
      share_price: 92.8,
      code: 1723,
      name: "中碳",
      year_113: "",
      price_113: "",
      year_111: "環保餐具",
      price_111: 12,
      year_108: "iPASS一卡通",
    },
    {
      suggestion: "可",
      share_price: 28.65,
      code: 1732,
      name: "毛寶",
      year_113: "",
      price_113: "",
      year_112: "毛寶洗衣精1000g",
      price_112: 15,
      year_111: "毛寶小蘇打植萃香氛液體皂1公斤",
      price_111: 15,
    },
    {
      suggestion: "必",
      share_price: 31.95,
      code: 1733,
      name: "五鼎",
      year_113: "微波保鮮盒",
      price_113: 5,
      year_112: "小麥餐具組",
      price_112: 5,
      year_111: "酒精噴霧筆",
      price_111: 5,
      year_110: "6合1工具組",
      year_109: "收納鞋架",
      year_108: "不鏽鋼吸管組",
      year_107: "小夜燈",
    },
    {
      suggestion: "必",
      share_price: 31.55,
      code: 1734,
      name: "杏輝",
      year_113: "蓉憶記",
      price_113: 200,
      year_112: "杏輝善補",
      price_112: 30,
      year_111: "杏輝 蓉憶記",
      price_111: 150,
      year_110: "杏輝 蓉憶記",
      year_109: "杏輝 芝麻E舒眠10粒",
      year_108: "杏輝 蓉憶記",
      year_107: "平衡肌況Vit B水凝乳",
    },
    {
      suggestion: "必",
      share_price: 32.5,
      code: 1737,
      name: "台鹽",
      year_113: "牙膏二條",
      price_113: 30,
      year_112: "洗髮精",
      price_112: 35,
      year_111: "蓓舒美海藻潤澤皂(3入)",
      price_111: 25,
      year_110: "台鹽防護乾洗手 二入",
      year_109: "台鹽面膜5片",
      year_108: "海鹽",
      year_107: "牙膏組",
    },
    {
      share_price: 17.2,
      code: 1742,
      name: "台蠟",
      year_113: "",
      price_113: "",
      year_108: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 89.1,
      code: 1760,
      name: "寶齡",
      year_113: "面膜",
      price_113: 15,
      year_112: "粉餅-替",
      price_112: 20,
      year_111: "寶齡洗髮精",
      price_111: 50,
      year_110: "寶齡髮細胞洗髮精",
      year_109: "寶齡髮細胞洗髮精",
      year_108: "液態維生素D",
      year_107: "寶齡髮細胞洗髮精",
    },
    {
      suggestion: "必",
      share_price: 34.25,
      code: 1762,
      name: "中化合成",
      year_113: "綠的潔淨組合",
      price_113: 25,
      year_112: "綠的抗菌組合",
      price_112: 25,
      year_111: "綠的防疫組",
      price_111: 25,
      year_110: "綠的防疫組",
      year_109: "大豆濃縮洗潔精",
      year_108: "綠的抗菌沐浴露",
      year_107: "綠的抗菌潔手慕斯",
    },
    {
      suggestion: "必",
      share_price: 17.3,
      code: 1781,
      name: "合世",
      year_113: "環保小麥梗餐具組",
      price_113: 5,
      year_112: "亮潔洗碗精500cc",
      price_112: 7,
      year_111: "菲蘇德美沐浴乳100ml",
      price_111: 5,
      year_108: "手電筒",
      year_107: "休閒襪",
    },
    {
      suggestion: "必",
      share_price: 50.5,
      code: 1783,
      name: "和康生",
      year_113: "花萃透亮面膜五入灰盒",
      price_113: 10,
      year_112: "敏節乳酸菌膠囊",
      price_112: 30,
      year_111: "Maxi紫花美日C沖泡飲",
      price_111: 15,
      year_110: "Maxi紫花美日C沖泡飲",
      year_109: "維他命亮澤乳霜面膜",
      year_107: "妮歐荷晶潤緊顏面膜",
    },
    {
      suggestion: "必",
      share_price: 86.5,
      code: 1784,
      name: "訊聯",
      year_113: "玫瑰肌因水潤光面膜",
      price_113: 5,
      year_112: "玫瑰面膜",
      price_112: 5,
      year_111: "玫瑰肌因水潤光面膜",
      price_111: 5,
      year_110: "玫瑰肌因水潤光面膜",
      year_109: "玫瑰肌因水潤光面膜",
      year_108: "RE.O玫瑰肌因保濕潤唇膏",
      year_107: "RE.O玫瑰肌因保濕潤唇膏",
    },
    {
      suggestion: "必",
      share_price: 60.3,
      code: 1785,
      name: "光洋科",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "Luminarc樂美雅時尚餐盤二入組",
      price_111: 35,
      year_110: "卡-711卡50元",
      year_109: "保溫彈跳杯",
      year_108: "萬用插頭",
      year_107: "保溫保冷餐袋",
    },
    {
      suggestion: "必",
      share_price: 120,
      code: 1786,
      name: "科妍",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "玻尿酸面膜 2片",
      price_112: 5,
      year_111: "玻尿酸面膜1盒(2片裝)",
      price_111: 5,
      year_110: "玻尿酸面膜1盒(2片裝)",
      year_109: "玻尿酸面膜1盒(2片裝)",
      year_108: "玻尿酸面膜1盒(2片裝)",
      year_107: "玻尿酸面膜 二片",
    },
    {
      share_price: 45.2,
      code: 1796,
      name: "金穎",
      year_113: "",
      price_113: "",
      year_108: "金穎冬蟲夏草菌絲體膠囊(30顆)",
      year_107: "牛樟芝菌絲體精粹膠囊",
    },
    {
      share_price: 9,
      code: 1806,
      name: "冠軍",
      year_113: "",
      price_113: "",
      year_107: "鍋寶鮮匯玻璃碗 2入",
    },
    {
      share_price: 20.4,
      code: 1809,
      name: "中粙",
      year_113: "毛寶洗碗精",
      price_113: 7,
    },
    {
      suggestion: "必",
      share_price: 34.5,
      code: 1815,
      name: "富喬",
      year_113: "不鏽鋼環保筷",
      price_113: 5,
      year_112: "華南共生米300 g",
      price_112: 6,
      year_111: "精緻開運鏟匙",
      price_111: 5,
      year_110: "華南共生米",
      year_109: "華南有機白米",
      year_108: "濾泡式耳掛咖啡包",
      year_107: "蜂膠保濕柔膚皂三入",
    },
    {
      share_price: 50.1,
      code: 1903,
      name: "士紙",
      year_113: "",
      price_113: "",
      year_108: "濕紙巾+面膜",
      year_107: "濕巾 + 面膜",
    },
    {
      suggestion: "必",
      share_price: 19.45,
      code: 1904,
      name: "正隆",
      year_113: "春風三層抽取式廚紙一串三捲",
      price_113: 30,
      year_112: "衛生紙",
      price_112: 55,
      year_111: "春風抽取式衛生紙一串",
      price_111: 50,
      year_110: "春風抽取式衛生紙一串",
      year_109: "蒲公英環保抽取衛生紙一串",
      year_108: "春風抽取式衛生紙一串",
      year_107: "春風抽取式衛生紙一串",
    },
    {
      suggestion: "必",
      share_price: 29.8,
      code: 1907,
      name: "永豐餘",
      year_113: "GREEN&SAFE月桃洗髮皂",
      price_113: 10,
      year_110: "GREEN&SAFE 經典醬料禮盒",
      year_107: "薄荷綠茶抗敏牙膏二入",
    },
    {
      suggestion: "必",
      share_price: 11.15,
      code: 1909,
      name: "榮成",
      year_113: "環保杯",
      price_113: 5,
      year_112: "環保袋",
      price_112: 10,
      year_111: "環保杯",
      price_111: 5,
      year_110: "環保袋",
      year_109: "環保杯",
      year_108: "環保杯",
      year_107: "環保杯",
    },
    {
      suggestion: "必",
      share_price: 19.65,
      code: 2002,
      name: "中鋼",
      year_113: "砧心有您",
      price_113: 50,
      year_112: "傘Q",
      price_112: 100,
      year_111: "精緻鈦ONE戶外型環保餐具",
      price_111: 40,
      year_110: "熊愛台灣螺絲起子工具組",
      year_109: "鯨彩都繪抗菌鋼杯",
      year_108: "卡幸福儲卡鋁盒",
      year_107: "皂到幸福 皂三入加盒",
    },
    {
      suggestion: "必",
      share_price: 69.1,
      code: 2006,
      name: "東和鋼",
      year_113: "",
      price_113: "",
      year_112: "不銹鋼餐具盒",
      price_112: 10,
      year_109: "休閒水壺(台灣製)",
    },
    {
      suggestion: "必",
      share_price: 25,
      code: 2012,
      name: "春雨",
      year_113: "甲魚全沛全方位維他命",
      price_113: 10,
      year_112: "犇牛樟芝菌絲體",
      price_112: 15,
      year_111: "德國雙人牌指甲鉗",
      price_111: 40,
    },
    {
      suggestion: "可",
      share_price: 49.9,
      code: 2013,
      name: "中鋼構",
      year_113: "",
      price_113: "",
      year_112: "燜燒罐",
      price_112: 100,
    },
    {
      suggestion: "必",
      share_price: 18.25,
      code: 2014,
      name: "中鴻",
      year_113: "卡-711卡100元",
      price_113: 83,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "熊愛台灣螺絲起子工具組",
      year_107: "皂到幸福 皂三入加盒",
    },
    {
      share_price: 28.25,
      code: 2020,
      name: "美亞鋼",
      year_113: "",
      price_113: "",
      year_111: "卡-全家50元",
      price_111: 39,
    },
    {
      suggestion: "必",
      share_price: 8.86,
      code: 2022,
      name: "聚亨",
      year_113: "皂-蜂王洋甘菊潤膚皂二入 60g",
      price_113: 8,
      year_112: "蜂王草本植舒亮澤皂二入",
      price_112: 5,
      year_111: "蜂王植萃精華皂二入",
      price_111: 5,
      year_108: "卡-全家35元",
      year_107: "好無比洗衣精",
    },
    {
      suggestion: "必",
      share_price: 15.45,
      code: 2023,
      name: "燁輝",
      year_113: "",
      price_113: "",
      year_111: "不鏽鋼保鮮盒",
      price_111: 20,
      year_108: "不鏽鋼餐具組",
    },
    {
      suggestion: "必",
      share_price: 18.95,
      code: 2024,
      name: "志聯",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "萬用工具",
      price_111: 5,
      year_110: "萬用工具",
      year_109: "工具筆",
      year_108: "迷你手電筒",
      year_107: "環保餐具",
    },
    {
      suggestion: "必",
      share_price: 0,
      code: 2025,
      name: "千興",
      year_113: "皂-伊莎貝爾涼感運動皂 94克一入",
      price_113: 5,
      year_112: "櫻花皂一顆",
      price_112: 5,
      year_111: "香皂一入",
      price_111: 5,
      year_110: "白雪葡萄籽美容香皂 二入",
      year_109: "白雪百花美容皂二入",
      year_108: "白雪香皂二入",
      year_107: "白雪沐浴禮盒",
    },
    {
      share_price: 21.3,
      code: 2028,
      name: "威致",
      year_113: "",
      price_113: "",
      year_109: "麗仕沐浴乳 200 ml",
    },
    {
      suggestion: "必",
      share_price: 16.8,
      code: 2030,
      name: "彰源",
      year_113: "防護口罩 20 片",
      price_113: 5,
      year_112: "口罩一盒20入",
      price_112: 5,
      year_111: "口罩一盒10入",
      price_111: 5,
      year_110: "防護口罩",
      year_109: "運動休閒襪",
      year_108: "健康襪",
      year_107: "健康襪",
    },
    {
      suggestion: "必",
      share_price: 45.6,
      code: 2031,
      name: "新光鋼",
      year_113: "妙管家公道先生洗潔精(500g)",
      price_113: 6,
      year_112: "毛寶洗碗精450g",
      price_112: 8,
      year_111: "好無比繽紛花園香氛洗衣精(500g)",
      price_111: 6,
      year_110: "妙管家公道先生洗潔精 500g",
      year_109: "妙管家濃縮洗衣精(600g)",
      year_108: "妙管家濃縮洗衣精(600g)",
      year_107: "白人牙膏牙刷旅行組",
    },
    {
      suggestion: "必",
      share_price: 31.8,
      code: 2035,
      name: "唐榮",
      year_113: "抗菌不銹鋼餐具",
      price_113: 6,
      year_110: "抗菌福祿單層杯",
      year_107: "抗菌不銹鋼杯",
    },
    {
      share_price: 325,
      code: 2049,
      name: "上銀科",
      year_113: "",
      price_113: "",
      year_108: "卡-全家100元",
      year_107: "卡-711卡100元",
    },
    {
      share_price: 18,
      code: 2061,
      name: "風青",
      year_113: "",
      price_113: "",
      year_108: "卡-全家35元",
    },
    {
      suggestion: "必",
      share_price: 52.1,
      code: 2065,
      name: "世豐",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
    },
    {
      suggestion: "可",
      share_price: 18.05,
      code: 2102,
      name: "泰豐",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_110: "RICO真瓷保溫杯450ml",
      year_109: "卡-711卡500元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "可",
      share_price: 20.35,
      code: 2103,
      name: "台橡",
      year_113: "減碳行動杯",
      price_113: 10,
      year_110: "多功能微粒子U型護頸枕",
      year_107: "瑜珈墊",
    },
    {
      suggestion: "必",
      share_price: 12.25,
      code: 2104,
      name: "中橡",
      year_113: "餐具組",
      price_113: 10,
      year_112: "分隔便當盒",
      price_112: 40,
      year_111: "保溫瓶",
      price_111: 25,
      year_110: "竹蓋玻璃保鮮盒三件組",
      year_109: "竹蓋環保垃圾桶",
      year_108: "晶采生活四件組",
      year_107: "不鏽鋼美形保溫瓶",
    },
    {
      suggestion: "必",
      share_price: 25.85,
      code: 2107,
      name: "厚生",
      year_113: "旅用隨行小拉鍊袋",
      price_113: 6,
      year_112: "輕巧手提袋",
      price_112: 8,
      year_111: "時尚文書袋",
      price_111: 12,
      year_110: "隨身肩背包",
      year_109: "環保隨身包",
      year_108: "工作圍裙",
      year_107: "環保隨身袋",
    },
    {
      suggestion: "必",
      share_price: 33.55,
      code: 2108,
      name: "南帝",
      year_113: "",
      price_113: "",
      year_111: "珍珍休閒食品四入",
      price_111: 25,
      year_108: "珍珍休閒食品",
    },
    {
      suggestion: "必",
      share_price: 18.2,
      code: 2109,
      name: "華豐",
      year_113: "",
      price_113: "",
      year_111: "毛寶好無比超淨亮洗衣精500g",
      price_111: 6,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 70.5,
      code: 2206,
      name: "三陽",
      year_113: "摺疊椅",
      price_113: 40,
      year_112: "Lynx隨行杯 (附布套)",
      price_112: 65,
      year_111: "保溫保冷提袋",
      price_111: 12,
      year_110: "伊莎貝爾手工香皂四入",
      year_109: "健康陶瓷隔熱杯",
      year_108: "不鏽鋼吸管組",
      year_107: "小熊保溫保冷提袋",
    },
    {
      suggestion: "必",
      share_price: 15,
      code: 2208,
      name: "台船",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
    },
    {
      suggestion: "可",
      share_price: 83,
      code: 2211,
      name: "長榮鋼",
      year_113: "",
      price_113: "",
      year_111: "鍋寶超真空燜燒罐840ml",
    },
    {
      suggestion: "可",
      share_price: 243,
      code: 2233,
      name: "宇隆",
      year_113: "",
      price_113: "",
      year_111: "卡-711卡100元",
      price_111: 82,
      year_110: "卡-711卡100元",
      year_109: "卡-711卡100元",
      year_108: "卡-711卡100元",
      year_107: "卡-711卡100元",
    },
    {
      suggestion: "必",
      share_price: 40.15,
      code: 2303,
      name: "聯電",
      year_113: "UMCx咖波聯名限定永續隨行杯",
      price_113: 25,
      year_112: "咖波貓碗組-藍盒",
      price_112: 30,
      year_111: "角落小夥伴帆布包",
      price_111: 35,
      year_110: "角落小夥伴帆布包",
      year_109: "角落小夥伴陶瓷杯",
      year_108: "卡娜赫拉馬克杯",
      year_107: "SNOOPY環保兩用袋",
    },
    {
      suggestion: "必",
      share_price: 24.9,
      code: 2312,
      name: "金寶",
      year_113: "計算機",
      price_113: 10,
      year_112: "計算機",
      price_112: 8,
      year_111: "戶外折疊運動水壺",
      price_111: 15,
      year_110: "收納袋",
      year_109: "蜂王草本抗菌皂 二入",
      year_108: "計算機",
      year_107: "計算機",
    },
    {
      suggestion: "必",
      share_price: 69.9,
      code: 2313,
      name: "華通",
      year_113: "南僑水晶葡萄柚籽洗衣液體皂 瓶 400g",
      price_113: 20,
      year_112: "南僑洗手乳",
      price_112: 20,
      year_111: "南僑水晶肥皂食器洗滌液體250ml",
      price_111: 20,
      year_110: "南僑葡萄柚籽抗菌洗手液",
      year_109: "南僑水晶肥皂食器洗滌液體250ml",
      year_108: "水晶肥皂 4 入",
      year_107: "洗手乳",
    },
    {
      suggestion: "必",
      share_price: 34.95,
      code: 2314,
      name: "台揚",
      year_113: "卡-711卡20元",
      price_113: 10,
      year_112: "毛寶洗碗精450g",
      price_112: 8,
      year_111: "黑色馬克杯1入",
      price_111: 5,
      year_108: "不鏽鋼調味罐2入",
      year_107: "英國貝爾熊隨身玻璃瓶",
    },
    {
      suggestion: "必",
      share_price: 50.2,
      code: 2316,
      name: "楠梓",
      year_113: "複合式餐盒",
      price_113: 20,
      year_112: "單層保溫飯盒",
      price_112: 20,
      year_111: "環保砧板",
      price_111: 15,
      year_110: "不銹鋼點心碗",
      year_109: "風潮杯",
      year_108: "隔熱便當盒",
      year_107: "雙層玻璃辦公杯",
    },
    {
      suggestion: "必",
      share_price: 9.94,
      code: 2323,
      name: "中環",
      year_113: "水晶肥皂櫻花百合洗衣精 500 g",
      price_113: 15,
      year_112: "水晶肥皂洗衣精",
      price_112: 15,
      year_111: "水晶肥皂洗衣精 500 g",
      price_111: 20,
      year_110: "水晶肥皂洗衣精",
      year_109: "水晶肥皂洗衣精",
      year_108: "水晶肥皂洗衣精",
      year_107: "水晶肥皂洗衣精",
    },
    {
      suggestion: "必",
      share_price: 571,
      code: 2327,
      name: "國巨",
      year_113: "伊莎貝爾無患子洗手乳250ml",
      price_113: 12,
      year_112: "妙管家洗潔精500g",
      price_112: 6,
      year_111: "妙管家洗潔精500g",
      price_111: 6,
    },
    {
      suggestion: "必",
      share_price: 40.45,
      code: 2328,
      name: "廣宇",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_110: "卡-711卡35元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 36.1,
      code: 2329,
      name: "華泰",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "保溫保冷袋提袋",
      price_111: 12,
      year_110: "卡-711卡50元",
      year_109: "木柄不銹鋼餐具組",
      year_108: "不鏽鋼環保吸管組",
      year_107: "多功能收納袋",
    },
    {
      share_price: 20,
      code: 2331,
      name: "精英",
      year_113: "",
      price_113: "",
      year_110: "卡-全家35元",
    },
    {
      suggestion: "必",
      share_price: 24.7,
      code: 2332,
      name: "友訊",
      year_113: "犇牛樟芝菌絲體精粹",
      price_113: 8,
      year_112: "雙層手提餐盒",
      price_112: 10,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "鍋寶鮮匯玻璃碗(四入)",
      year_109: "卡-711卡35元",
      year_108: "不銹鋼吸管組",
      year_107: "享樂罐2入",
    },
    {
      suggestion: "必",
      share_price: 19.55,
      code: 2337,
      name: "旺宏",
      year_113: "卡-711卡100元",
      price_113: 83,
      year_112: "卡-711卡100元",
      price_112: 76,
      year_111: "卡-711卡100元",
      price_111: 78,
      year_110: "卡-711卡100元",
      year_109: "卡-711卡100元",
      year_108: "卡-711卡100元",
      year_107: "夢田越光米",
    },
    {
      suggestion: "必",
      share_price: 48.4,
      code: 2338,
      name: "光罩",
      year_113: "運動涼感巾",
      price_113: 5,
      year_112: "紅茶包",
      price_112: 5,
      year_111: "卡-全家中美式",
      price_111: 15,
      year_110: "卡-全家中美式",
      year_109: "手機支架",
      year_108: "香皂",
    },
    {
      suggestion: "必",
      share_price: 27.2,
      code: 2340,
      name: "台亞",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "Luminarc樂美雅玻璃餐盤",
      price_111: 20,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "清淨海 環保洗碗精",
      year_107: "白雪沐浴禮盒",
    },
    {
      suggestion: "必",
      share_price: 30.75,
      code: 2342,
      name: "茂矽",
      year_113: "毛寶好無比洗衣精500ml",
      price_113: 6,
      year_112: "好無比洗衣精袋",
      price_112: 8,
      year_111: "好無比洗衣精袋",
      price_111: 6,
      year_110: "好無比洗衣精瓶",
      year_109: "好無比洗衣精瓶",
      year_108: "好無比洗衣精瓶",
      year_107: "好無比洗衣精瓶",
    },
    {
      suggestion: "必",
      share_price: 14.35,
      code: 2344,
      name: "華邦",
      year_113: "皂-美琪抗菌皂組 (1組四入)",
      price_113: 25,
      year_112: "美琪洗手慕斯500ml",
      price_112: 35,
      year_111: "美琪洗手慕斯500ml",
      price_111: 30,
      year_110: "美琪洗手慕斯500ml",
      year_109: "美琪洗手慕斯500ml",
      year_108: "ONE皂1組(2入)",
      year_107: "美琪 抗菌洗手乳",
    },
    {
      suggestion: "必",
      share_price: 774,
      code: 2345,
      name: "智邦",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 13.6,
      code: 2349,
      name: "錸德",
      year_113: "除霉凝膠",
      price_113: 5,
      year_112: "香氛噴霧 小",
      price_112: 5,
      year_111: "防蚊液 30ml 小",
      price_111: 5,
      year_110: "隨身型酒精液",
      year_109: "多功能衣架",
      year_108: "收納鞋架",
      year_107: "四爪滾輪按摩器",
    },
    {
      suggestion: "必",
      share_price: 35.3,
      code: 2352,
      name: "佳世達",
      year_113: "幸福物語蒸氣眼罩(迪士尼系列-米奇)",
      price_113: 5,
      year_112: "紙巾布60抽",
      price_112: 8,
      year_111: "艾爾絲醫用口罩(7入/盒)",
      price_111: 5,
      year_110: "怡安口罩(7入/盒)",
      year_109: "折疊收納旅行袋",
      year_108: "不銹鋼環保吸管杯套組",
      year_107: "修容組",
    },
    {
      suggestion: "必",
      share_price: 38.7,
      code: 2353,
      name: "宏碁",
      year_113: "",
      price_113: "",
      year_112: "面膜 Just Mask",
      price_112: 12,
      year_111: "Acer Vero 環保袋",
      price_111: 10,
      year_110: "抗菌隨身包",
      year_109: "單肩斜背酷包",
      year_108: "環保餐具吸管組",
      year_107: "耐熱玻璃分隔保鮮盒",
    },
    {
      share_price: 37,
      code: 2355,
      name: "敬鵬",
      year_113: "皂-沙威隆抗菌皂二入 80g",
      price_113: 5,
    },
    {
      suggestion: "必",
      share_price: "下市",
      code: 2358,
      name: "廷鑫",
      year_113: "卡-全家35元",
      price_113: 24,
      year_112: "卡-711卡50元",
      price_112: 35,
      year_111: "卡-全家50元",
      price_111: 35,
      year_108: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 143.5,
      code: 2359,
      name: "所羅門",
      year_113: "保冷保溫提袋(粉紅熊)",
      price_113: 8,
      year_112: "蜂王植萃精華皂二入皂",
      price_112: 5,
      year_111: "手工香皂二入禮盒",
      price_111: 10,
      year_108: "超商商品卡",
    },
    {
      suggestion: "必",
      share_price: 65.9,
      code: 2363,
      name: "矽統",
      year_113: "SNOOPY 馬克杯",
      price_113: 10,
      year_112: "口罩一盒",
      price_112: 5,
      year_111: "口罩",
      year_110: "馬克杯",
      year_109: "調味/油瓶",
      year_107: "防曬袖套",
    },
    {
      suggestion: "必",
      share_price: 80.5,
      code: 2364,
      name: "倫飛",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 45.8,
      code: 2365,
      name: "昆盈",
      year_113: "全罩頭戴式耳機",
      price_113: 40,
      year_112: "卡-全家50元",
      price_112: 39,
      year_111: "卡-全家50元",
      price_111: 39,
      year_110: "卡-全家50元",
      year_109: "卡-全家50元",
      year_108: "卡-全家50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 31.65,
      code: 2367,
      name: "燿華",
      year_113: "12件套曜黑不鏽鋼修容組",
      price_113: 12,
      year_112: "手機支架",
      price_112: 5,
      year_111: "三用途照明檯燈",
      price_111: 10,
      year_110: "沙威隆皂二入+口罩盒",
      year_109: "無患子草本抗菌皂二入",
      year_108: "三合一刨刀",
      year_107: "收納袋",
    },
    {
      share_price: 228,
      code: 2368,
      name: "金像",
      year_113: "",
      price_113: "",
      year_108: "高露潔牙膏旅遊組",
      year_107: "高露潔牙膏旅遊組",
    },
    {
      suggestion: "必",
      share_price: 17.6,
      code: 2369,
      name: "菱生",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡100元",
      price_111: 82,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡100元",
      year_107: "卡-711卡100元",
    },
    {
      suggestion: "必",
      share_price: 42.4,
      code: 2371,
      name: "大同",
      year_113: "",
      price_113: "",
      year_112: "卡-711卡100元",
      price_112: 81,
      year_111: "大同綠能電力小英雄漫畫口罩盒",
      price_111: 5,
      year_109: "陶樂杯(陶磁保溫杯)",
      year_108: "有機白米",
      year_107: "大同寶寶不銹鋼保溫瓶",
    },
    {
      suggestion: "必",
      share_price: 63.4,
      code: 2374,
      name: "佳能",
      year_113: "蘭花洗衣膠囊4顆",
      price_113: 5,
      year_112: "蘭花洗衣膠囊",
      price_112: 5,
      year_111: "深層海水萃取抗敏牙膏",
      price_111: 5,
      year_110: "白柚除臭環保洗衣膠囊",
      year_109: "茶樹檸檬環保洗衣膠囊",
      year_108: "台南越光米",
      year_107: "台南越光米",
    },
    {
      suggestion: "必",
      share_price: 70.6,
      code: 2375,
      name: "凱美",
      year_113: "伊莎貝爾無患子洗手乳250ml",
      price_113: 10,
      year_112: "妙管家洗潔精500g",
      price_112: 6,
      year_111: "妙管家洗潔精500g",
      price_111: 6,
      year_110: "3M菜瓜布",
      year_109: "3M菜瓜布",
      year_108: "3M菜瓜布",
    },
    {
      suggestion: "可",
      share_price: 254,
      code: 2376,
      name: "技嘉",
      year_113: "天然麻布包心手提袋兩入組(中+小)",
      price_113: 15,
      year_110: "泰山玄米油及珍穀益組合",
      year_107: "泰山芥花油及黑八寶組合",
    },
    {
      suggestion: "必",
      share_price: 553,
      code: 2379,
      name: "瑞昱",
      year_113: "卡-全家88元",
      price_113: 74,
      year_112: "卡-全家88元",
      price_112: 74,
      year_111: "卡-全家88元",
      price_111: 74,
      year_110: "卡-全家88元",
      year_109: "卡-全家88元",
      year_108: "卡-全家88元",
      year_107: "卡-全家88元",
    },
    {
      suggestion: "必",
      share_price: 4.74,
      code: 2380,
      name: "虹光",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "卡-全家50元",
      price_112: 39,
      year_111: "卡-全家50元",
      price_111: 39,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-全家50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 12.45,
      code: 2390,
      name: "云辰",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_108: "蜂王無患子滋養幸福禮盒",
    },
    {
      suggestion: "必",
      share_price: 88.7,
      code: 2393,
      name: "億光",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-711卡35元",
      price_112: 23,
    },
    {
      suggestion: "必",
      share_price: 25.95,
      code: 2399,
      name: "映泰",
      year_113: "皂-沙威隆抗菌皂二入 80g",
      price_113: 5,
      year_112: "伊莎貝爾潔手慕絲",
      price_112: 10,
      year_111: "卡-711卡50元",
      price_111: 34,
    },
    {
      suggestion: "必",
      share_price: 29.85,
      code: 2401,
      name: "凌陽",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 38.1,
      code: 2402,
      name: "毅嘉",
      year_113: "卡-711中美式/現萃茶卡",
      price_113: 17,
      year_112: "卡-711中美式",
      price_112: 18,
      year_111: "精美口罩",
      price_111: 5,
      year_110: "台灣茶摳茶樹精油潤膚皂1入",
      year_109: "沙威隆經典抗菌皂1入",
      year_108: "不鏽鋼吸管2件組",
      year_107: "卡-全家中美式",
    },
    {
      suggestion: "必",
      share_price: 489,
      code: 2404,
      name: "漢唐",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "卡-全家50元",
      price_112: 39,
      year_111: "卡-全家50元",
      price_111: 39,
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 21.15,
      code: 2405,
      name: "輔信",
      year_113: "康寧晶彩琥珀耐熱玻璃烤盤7吋",
      price_113: 20,
      year_112: "康寧5吋碗",
      price_112: 10,
      year_111: "幸運草水晶碗",
      price_111: 8,
      year_110: "康寧晶彩琥珀 8.5吋深盤",
      year_109: "密扣式玻璃保鮮盒",
      year_108: "鍋寶玻璃保鮮盒350ml",
      year_107: "美國康寧百麗5.5吋碗組",
    },
    {
      suggestion: "必",
      share_price: 13.85,
      code: 2406,
      name: "國碩",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-全家50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 13.8,
      code: 2409,
      name: "友達",
      year_113: "米-台灣產製包裝白米600公克",
      price_113: 15,
      year_112: "米-600 ｇ",
      price_112: 12,
      year_111: "米-600 ｇ",
      price_111: 12,
      year_110: "米-600 ｇ",
      year_109: "米-600 ｇ",
      year_108: "米-600 ｇ",
      year_107: "米-600 ｇ",
    },
    {
      suggestion: "必",
      share_price: 51.1,
      code: 2417,
      name: "圓剛",
      year_113: "可折疊手機支架",
      price_113: 5,
      year_112: "充電線三合一",
      price_112: 10,
      year_111: "蓓舒美海鹽淨膚皂130克二入",
      price_111: 20,
      year_110: "矽膠摺疊保鮮盒",
      year_109: "沙威隆抗菌洗手露",
      year_108: "不銹鋼環保吸管組",
      year_107: "咖啡杯",
    },
    {
      share_price: 29,
      code: 2419,
      name: "仲琦",
      year_113: "",
      price_113: "",
      year_108: "卡-全家35元",
      year_107: "LED迷你露營燈",
    },
    {
      suggestion: "必",
      share_price: 63.5,
      code: 2420,
      name: "新巨",
      year_113: "皂-伊莎貝爾櫻花皂 二入 75g",
      price_113: 8,
      year_112: "伊莎貝爾櫻花皂二入",
      price_112: 5,
      year_111: "卡-全家50元",
      price_111: 39,
      year_108: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 94.2,
      code: 2421,
      name: "建準",
      year_113: "耐斯泡舒洗潔精",
      price_113: 10,
      year_112: "泡舒洗潔精 500 ml",
      price_112: 10,
      year_111: "泡舒洗潔精 500 瓶",
      price_111: 10,
    },
    {
      suggestion: "可",
      share_price: 35,
      code: 2425,
      name: "承啟",
      year_113: "卡-711卡35元",
      price_113: 25,
    },
    {
      suggestion: "必",
      share_price: 21.75,
      code: 2426,
      name: "鼎元",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 30.05,
      code: 2430,
      name: "燦坤",
      year_113: "卡-燦坤贈品兌換券200元",
      price_113: 140,
      year_110: "300元燦坤贈品兌換券",
      year_109: "200元燦坤贈品兌換券",
    },
    {
      suggestion: "必",
      share_price: 14.4,
      code: 2431,
      name: "聯昌",
      year_113: "卡-餐品兌換券",
      price_113: 20,
      year_112: "咖哩包",
      price_112: 18,
      year_111: "樂雅樂泰式綠咖哩雞",
      price_111: 20,
      year_110: "環保購物袋",
      year_109: "活力草 手工皂",
      year_108: "樂雅樂咖哩包",
      year_107: "餐具組",
    },
    {
      suggestion: "必",
      share_price: 57.2,
      code: 2436,
      name: "偉詮",
      year_113: "皂-蜂王洋甘菊潤膚皂二入 70g",
      price_113: 5,
      year_112: "洗潔精",
      price_112: 5,
      year_111: "蜂王皂盒(二入)",
      price_111: 5,
      year_110: "艾爾柏娜 洗碗精",
      year_109: "米奇造型香皂禮盒(三入)",
      year_108: "洗碗精一瓶",
      year_107: "蜂王乳油木果精華皂三入組",
    },
    {
      suggestion: "可",
      share_price: 28.6,
      code: 2438,
      name: "翔耀",
      year_113: "多功能矽膠廚具組",
      price_113: 5,
    },
    {
      suggestion: "必",
      share_price: 112,
      code: 2439,
      name: "美律",
      year_113: "有線耳機",
      price_113: 20,
      year_112: "耳機",
      price_112: 30,
      year_111: "卡-全家50元禮贈卡",
      price_111: 35,
      year_110: "卡-全家50元禮贈卡",
    },
    {
      suggestion: "必",
      share_price: 25.05,
      code: 2442,
      name: "新美齊",
      year_113: "毛寶好無比洗衣精500ml",
      price_113: 6,
      year_112: "好無比洗衣精",
      price_112: 7,
      year_111: "毛寶洗衣精500ml",
      price_111: 6,
    },
    {
      share_price: "下市",
      code: 2443,
      name: "昶虹",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_111: "Type C充電線",
      price_111: 5,
      year_110: "護力銀 銀離子抗菌噴霧",
      year_109: "護力銀 銀離子抗菌噴霧",
      year_108: "卡-全家35元",
      year_107: "卡-全家35元",
    },
    {
      suggestion: "必",
      share_price: 17.85,
      code: 2444,
      name: "兆勁",
      year_113: "防疫消毒酒精瓶",
      price_113: 2,
      year_112: "酒精瓶",
      price_112: 5,
      year_111: "咖啡豆",
      price_111: 15,
    },
    {
      suggestion: "必",
      share_price: 108.5,
      code: 2449,
      name: "京元電",
      year_113: "提袋",
      price_113: 5,
      year_112: "菱格紋購物袋",
      price_112: 5,
      year_111: "保溫保冷拉鍊袋",
      price_111: 5,
      year_110: "丹寧布提袋",
      year_109: "3C收納包",
      year_108: "3C收納包",
    },
    {
      suggestion: "可",
      share_price: 71.1,
      code: 2453,
      name: "凌群",
      year_113: "伊莎貝爾抗菌日用品組",
      price_113: 12,
    },
    {
      suggestion: "必",
      share_price: 166.5,
      code: 2455,
      name: "全新",
      year_113: "",
      price_113: "",
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 35.65,
      code: 2457,
      name: "飛宏",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "巧收旅行袋",
      year_107: "繽紛鋁箔保溫袋",
    },
    {
      suggestion: "必",
      share_price: 162.5,
      code: 2458,
      name: "義隆電",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-711卡35元",
      price_112: 20,
      year_111: "卡-711卡35元",
      price_111: 20,
      year_107: "卡-711卡50元",
    },
    {
      share_price: 24.1,
      code: 2460,
      name: "建通",
      year_113: "",
      price_113: "",
      year_111: "三合一開罐器",
      year_110: "美容修甲組",
      year_108: "餐具組",
      year_107: "長版杯套",
    },
    {
      suggestion: "必",
      share_price: 19.9,
      code: 2461,
      name: "光群雷",
      year_113: "手機掛繩",
      price_113: 5,
      year_112: "袋子-替",
      price_112: 5,
      year_111: "依必朗抗菌洗手乳",
      price_111: 12,
      year_110: "環保帆布手提袋",
      year_109: "橄欖保濕草本皂",
      year_108: "卡-全家50元",
      year_107: "濾掛式咖啡",
    },
    {
      suggestion: "必",
      share_price: 75.8,
      code: 2464,
      name: "盟立",
      year_113: "卡-全家50元",
      price_113: 35,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-全家50元",
      price_111: 37,
      year_110: "卡-全家50元",
      year_109: "卡-全家50元",
      year_108: "卡-全家50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 72.8,
      code: 2465,
      name: "麗台",
      year_113: "BtNPN植物奈米貼片(三入)",
      price_113: 10,
      year_112: "蜂膠香皂四入組",
      price_112: 20,
      year_111: "USB充電式手電筒",
      price_111: 15,
      year_110: "伊拉法兒 乳霜皂三入",
      year_109: "環保餐具組",
      year_108: "強化餐盤",
      year_107: "樂美雅 強化瓷麵碗",
    },
    {
      suggestion: "必",
      share_price: 188,
      code: 2467,
      name: "志聖",
      year_113: "環保餐具組 藍紅隨機",
      price_113: 8,
      year_112: "餐具三入組紅袋",
      price_112: 5,
      year_111: "多功能手電筒",
      price_111: 10,
      year_110: "圍脖",
      year_109: "圍脖",
      year_108: "環保餐具組加穴位指壓棒",
      year_107: "穴位指壓棒加環保餐具組",
    },
    {
      suggestion: "必",
      share_price: 63.4,
      code: 2471,
      name: "資通",
      year_113: "水晶肥皂食器洗滌液體瓶 100 ml",
      price_113: 12,
      year_112: "蜂王草本植舒亮澤皂二入",
      price_112: 5,
      year_111: "醫療口罩（6入／盒）",
      price_111: 5,
      year_110: "顏帝亞嬰兒皂",
      year_109: "蜂王乳油木果皂二入",
      year_108: "好無比超麗潔洗衣精",
      year_107: "蘭麗綿羊皂二入",
    },
    {
      suggestion: "必",
      share_price: 45.7,
      code: 2478,
      name: "大毅",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711中美式",
      price_112: 18,
      year_111: "卡-711中美式",
      price_111: 18,
      year_110: "卡-711中美式",
      year_109: "抗菌口罩套乙個",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 158.5,
      code: 2480,
      name: "敦陽科",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_110: "卡-711卡35元",
      year_109: "卡-711卡35元",
      year_108: "卡-全家中美式",
      year_107: "卡-全家中美式",
    },
    {
      suggestion: "必",
      share_price: 48.2,
      code: 2481,
      name: "強茂",
      year_113: "皂-蜂王洋甘菊潤膚皂二入 60g",
      price_113: 6,
      year_112: "蜂王草本植舒亮澤皂二入",
      price_112: 5,
      year_111: "毛寶繽紛花園抗菌洗手乳",
      price_111: 12,
      year_110: "毛寶繽紛花園抗菌洗手乳",
      year_109: "好無比超麗潔洗衣精",
      year_108: "地中海薰衣草香氛皂",
    },
    {
      suggestion: "必",
      share_price: 23.95,
      code: 2482,
      name: "連宇",
      year_113: "高露潔旅行組(牙刷+牙膏20g)",
      price_113: 12,
      year_112: "伊莎貝爾櫻花皂三入",
      price_112: 10,
      year_111: "伊莎貝爾香皂二入",
      price_111: 7,
    },
    {
      suggestion: "必",
      share_price: 24.95,
      code: 2484,
      name: "希華",
      year_113: "日曬海鹽",
      price_113: 8,
      year_112: "包裝米500 g",
      price_112: 10,
      year_111: "麵條二包",
      price_111: 15,
      year_110: "四季釀造油膏300ml",
      year_109: "好勁道家常麵條 2包入",
      year_108: "好勁道家常麵條 2包入",
      year_107: "好勁道家常麵條 2包入",
    },
    {
      suggestion: "必",
      share_price: 15.45,
      code: 2485,
      name: "兆赫",
      year_113: "卡-萊爾富卡50元",
      price_113: 33,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "依莎貝爾抗菌手工皂六入盒",
      price_111: 30,
      year_110: "妙管家懷特保溫杯 450ml",
      year_109: "鈦之美-鈦合金印章",
      year_108: "LED磁吸式可吊掛多功能手電筒/工作燈",
      year_107: "SUPERARE廚用剪刀三入組",
    },
    {
      suggestion: "必",
      share_price: 107,
      code: 2486,
      name: "一詮",
      year_113: "卡-全家大美式(2027/06)",
      price_113: 20,
      year_112: "卡-全家大美式",
      price_112: 15,
      year_111: "卡-全家大美式",
      price_111: 18,
      year_110: "全家美式咖啡提領卡",
      year_109: "卡-全大熱美",
      year_108: "卡-全家中美式",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 58.8,
      code: 2488,
      name: "漢平",
      year_113: "卡-711卡35元",
      price_113: 23,
      year_112: "卡-711卡35元",
      price_112: 20,
      year_111: "卡-711卡35元",
      price_111: 21,
      year_110: "卡-711卡35元",
      year_109: "卡-711卡35元",
      year_108: "蘭麗手工香皂三入",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 18,
      code: 2489,
      name: "瑞軒",
      year_113: "USB Type-C to C傳輸充電線",
      price_113: 5,
      year_112: "口罩防護組",
      price_112: 5,
      year_111: "口罩收納組",
      price_111: 5,
      year_110: "口罩收納組合",
      year_109: "手動增壓連續超細噴霧瓶",
      year_108: "雙層隔熱玻璃杯 (2入)",
      year_107: "時尚保冰溫提袋",
    },
    {
      suggestion: "必",
      share_price: 98,
      code: 2493,
      name: "揚博",
      year_113: "造型便當盒",
      price_113: 15,
      year_112: "歐風精華皂二入",
      price_112: 6,
      year_111: "洗手乳加香皂組",
      price_111: 15,
      year_110: "三用照明燈",
      year_109: "無患子皂組",
      year_108: "餐具組",
      year_107: "手電筒",
    },
    {
      suggestion: "必",
      share_price: 47.25,
      code: 2498,
      name: "宏達電",
      year_113: "HTC雙面抗UV機能帽PLUS",
      price_113: 35,
      year_112: "HTC 帽",
      price_112: 15,
      year_111: "HTC隨身保溫杯袋組乙組",
      price_111: 40,
      year_110: "休閒折疊椅",
      year_109: "多功能後背包乙個",
      year_108: "黑色手提購物袋乙個",
      year_107: "黑色後背包",
    },
    {
      suggestion: "必",
      share_price: 43.4,
      code: 2504,
      name: "國產",
      year_113: "真空保溫杯",
      price_113: 20,
      year_112: "雨陽傘",
      price_112: 30,
      year_111: "4D立體醫用口罩",
      price_111: 5,
      year_110: "防疫口罩 10入",
      year_109: "蜂王燕麥柔膚皂沐浴禮盒",
      year_108: "環保餐具六件組",
      year_107: "LED閃字風扇",
    },
    {
      suggestion: "可",
      share_price: 11.7,
      code: 2506,
      name: "太設",
      year_113: "",
      price_113: "",
      year_111: "茶樹檸檬洗手乳",
      price_111: 8,
      year_108: "西華四季高真空保溫瓶",
    },
    {
      suggestion: "必",
      share_price: 10.55,
      code: 2515,
      name: "中工",
      year_113: "金尊陶瓷手拿杯",
      price_113: 40,
      year_112: "收納袋旅行組",
      price_112: 15,
      year_111: "LED三角警示燈",
      price_111: 15,
      year_110: "兩用野餐保冷袋",
      year_109: "矽膠折疊保鮮盒",
      year_108: "歐式環保餐具組",
      year_107: "LED手提式照明燈",
    },
    {
      suggestion: "必",
      share_price: 22.25,
      code: 2516,
      name: "新建",
      year_113: "高級充電線",
      price_113: 12,
      year_112: "樂美雅盤",
      price_112: 15,
      year_111: "防疫休閒組",
      price_111: 15,
      year_110: "休閒頸枕",
      year_109: "旅行清潔組",
      year_108: "喜馬拉雅山玫瑰鹽",
      year_107: "抗菌紳士襪",
    },
    {
      suggestion: "必",
      share_price: 51.5,
      code: 2520,
      name: "冠德",
      year_113: "卡-環球購物中心禮券100元",
      price_113: 60,
      year_112: "雙層隔熱玻璃瓶",
      price_112: 5,
      year_111: "環保擦板文具組",
      price_111: 5,
      year_110: "手工皂禮盒",
      year_109: "抗菌洗手乳禮盒",
      year_108: "卡-711卡50元",
      year_107: "白雪沐浴禮盒",
    },
    {
      share_price: 38.35,
      code: 2530,
      name: "華建",
      year_113: "",
      price_113: "",
      year_109: "卡-711卡50元",
      year_108: "卡-全家50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 24.5,
      code: 2534,
      name: "宏盛",
      year_113: "米-白米-1 公斤",
      price_113: 28,
      year_112: "米-1 公斤",
      price_112: 25,
      year_111: "米-1 公斤",
      price_111: 18,
      year_110: "米-1 公斤",
      year_109: "米-1 公斤",
      year_108: "米-1 公斤",
      year_107: "米-1 公斤",
    },
    {
      suggestion: "必",
      share_price: 56.2,
      code: 2535,
      name: "達欣工",
      year_113: "野營旋扣玻璃保鮮罐",
      price_113: 25,
      year_112: "USB智能觸控燈",
      price_112: 25,
      year_111: "Travel Fox 日式簡約保溫保冰袋",
      price_111: 12,
      year_110: "真空mini保溫瓶",
      year_109: "拓荒者360度旋轉智慧感應燈",
      year_108: "藍芽自拍棒",
      year_107: "NARUMI鏡面馬克杯",
    },
    {
      suggestion: "可",
      share_price: 12.2,
      code: 2537,
      name: "聯上發",
      year_113: "皂-妙管家洗衣粉",
      price_113: 5,
    },
    {
      suggestion: "可",
      share_price: 14,
      code: 2538,
      name: "基泰",
      year_113: "",
      price_113: "",
      year_111: "卡-711卡50元",
      price_111: 37,
      year_108: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 71.5,
      code: 2546,
      name: "根基",
      year_113: "卡-環球購物中心禮券100元",
      price_113: 60,
      year_112: "雙層隔熱玻璃瓶",
      price_112: 5,
      year_111: "環保擦板文具組",
      price_111: 5,
      year_110: "手工皂禮盒",
      year_109: "抗菌洗手乳禮盒",
      year_108: "卡-711卡50元",
      year_107: "白雪沐浴禮盒",
    },
    {
      suggestion: "必",
      share_price: 10.9,
      code: 2547,
      name: "日勝生",
      year_113: "CESTVRAI抗痕肌能拉提面膜",
      price_113: 20,
      year_112: "日勝生面膜",
      price_112: 20,
      year_111: "莎菲亞 初乳抗菌防護組/沐浴露",
      price_111: 20,
      year_110: "莎菲亞初乳潔顏抗菌組",
      year_109: "莎菲亞頭皮養護組",
      year_108: "Marveloux馬勒斯肌能收斂噴霧禮盒",
      year_107: "莎菲亞 紅玉蠟菊抗痕修護組",
    },
    {
      suggestion: "必",
      share_price: 7.11,
      code: 2601,
      name: "益航",
      year_113: "妙管家多功能儲物盒1.2L",
      price_113: 25,
      year_112: "妙管家廚房三寶",
      price_112: 10,
      year_111: "妙管家可調式桌上型手機支架",
      price_111: 15,
      year_110: "妙管家多功能運動雪克杯",
      year_109: "手工薑黃皂",
      year_108: "16G隨身碟",
      year_107: "妙管家口袋杯",
    },
    {
      suggestion: "必",
      share_price: 206,
      code: 2603,
      name: "長榮海",
      year_113: "Jack wolfskin輕柔薄毯",
      price_113: 20,
      year_112: "康寧陶瓷不鏽鋼隨行杯",
      price_112: 100,
      year_111: "卡-711卡50元",
      price_111: 34,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 24.35,
      code: 2605,
      name: "新興",
      year_113: "不銹鋼高級修容組/鍋子/油壼，2：1：1",
      price_113: 8,
      year_112: "青瓷碗/油瓶",
      price_112: 10,
      year_111: "不銹鋼環保餐具三件組/盤子",
      price_111: 10,
      year_110: "樂活泡茶瓶",
      year_109: "金安德森運動毛巾",
      year_108: "多功能玻璃杯(二入裝)",
      year_107: "玻璃隨手瓶",
    },
    {
      share_price: 29.9,
      code: 2607,
      name: "榮運",
      year_113: "",
      price_113: "",
      year_111: "卡-711卡50元",
    },
    {
      suggestion: "可",
      share_price: 38.45,
      code: 2608,
      name: "大榮",
      year_113: "",
      price_113: "",
      year_111: "兩件式環保餐具組",
      price_111: 10,
      year_108: "三件式環保餐具組",
    },
    {
      suggestion: "可",
      share_price: 67,
      code: 2609,
      name: "陽明",
      year_113: "卡-711卡100元",
      price_113: 83,
    },
    {
      suggestion: "必",
      share_price: 22.15,
      code: 2611,
      name: "志信",
      year_113: "",
      price_113: "",
      year_112: "卡-全家50元",
      price_112: 35,
      year_111: "卡-全家50元",
      price_111: 35,
      year_108: "卡-全家50元",
    },
    {
      suggestion: "可",
      share_price: 39.1,
      code: 2612,
      name: "中航",
      year_113: "",
      price_113: "",
      year_111: "卡-711卡50元",
      price_111: 37,
      year_108: "卡-711卡50元",
    },
    {
      suggestion: "可",
      share_price: 31.05,
      code: 2613,
      name: "中櫃",
      year_113: "",
      price_113: "",
      year_111: "蜂王橄欖潤澤草本皂(二入)",
      price_111: 5,
      year_108: "蜂王乳油果精華皂(盒裝二入)",
    },
    {
      suggestion: "必",
      share_price: 17.05,
      code: 2614,
      name: "東森",
      year_113: "活絡豐盈精油洗髮露",
      price_113: 35,
      year_112: "面膜",
      price_112: 20,
      year_111: "自然美頂級植萃呵護精油",
      price_111: 30,
      year_110: "超效保濕爆水氣墊面膜",
      year_109: "史努比下雪森林寬口手提保溫袋",
      year_108: "PS美美研面膜(7入/1盒)",
      year_107: "Nakamura青花瓷雙盤組",
    },
    {
      suggestion: "必",
      share_price: 17.3,
      code: 2616,
      name: "山隆",
      year_113: "春風三層抽取式廚紙一串三捲",
      price_113: 30,
      year_112: "衛生紙",
      price_112: 55,
      year_111: "春風抽取式衛生紙一串",
      price_111: 50,
      year_110: "春風抽取式衛生紙一串",
      year_109: "蒲公英環保抽取衛生紙一串",
      year_108: "春風抽取式衛生紙一串",
      year_107: "春風抽取式衛生紙一串",
    },
    {
      suggestion: "必",
      share_price: 44.55,
      code: 2618,
      name: "長榮航",
      year_113: "陶瓷保溫杯墊禮盒組",
      price_113: 25,
      year_111: "卡-711卡50元",
      price_111: 34,
    },
    {
      suggestion: "必",
      share_price: 75.9,
      code: 2636,
      name: "台驊",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "卡-全家50元",
      price_112: 39,
      year_111: "卡-全家50元",
      price_111: 39,
      year_109: "蜂王草本抗菌皂二入",
      year_108: "卡-全家35元",
      year_107: "卡-全家大冰拿鐵",
    },
    {
      suggestion: "必",
      share_price: 18.15,
      code: 2641,
      name: "正德",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-711卡35元",
      price_111: 23,
    },
    {
      suggestion: "必",
      share_price: 52.9,
      code: 2704,
      name: "國賓",
      year_113: "保溫杯",
      price_113: 20,
      year_110: "鍋寶時尚沖茶器",
      year_107: "鍋寶耐熱玻璃分隔保鮮盒",
    },
    {
      suggestion: "必",
      share_price: 18,
      code: 2705,
      name: "六福",
      year_113: "六福村主題樂園門票乙張",
      price_113: 130,
      year_112: "本幫醃篤鮮火鍋湯",
      price_112: 20,
      year_111: "六福村動物紋雨衣",
      price_111: 5,
      year_110: "口罩香氛貼片",
      year_109: "六福村50元贈品卷",
      year_108: "六福村50元餐飲商品抵用卷",
      year_107: "不鏽鋼吸管3件組",
    },
    {
      suggestion: "必",
      share_price: 225.5,
      code: 2727,
      name: "王品",
      year_113: "王品股東大禮包",
      price_113: 170,
      year_112: "卡-王品",
      price_112: 130,
      year_111: "王品股東大禮包",
      price_111: 100,
      year_110: "王品股東大禮包",
      year_109: "王品集團2200元美食抵用券",
      year_108: "王品 伍佰元現金抵用券",
      year_107: "王品集團 75折優惠券",
    },
    {
      share_price: 27,
      code: 2734,
      name: "易飛網",
      year_113: "皂-沙威隆抗菌皂二入 80g",
      price_113: 7,
    },
    {
      share_price: 33,
      code: 2740,
      name: "天蔥",
      year_113: "",
      price_113: "",
      year_110: "天蒽貴賓券 100元",
    },
    {
      suggestion: "必",
      share_price: 18.1,
      code: 2801,
      name: "彰銀",
      year_113: "",
      price_113: "",
      year_112: "卡-全家100元",
      price_112: 85,
      year_109: "dr.Si矽寶巧力碗",
    },
    {
      suggestion: "必",
      share_price: 18.45,
      code: 2812,
      name: "台中銀",
      year_113: "",
      price_113: "",
      year_112: "毛寶洗衣精",
      price_112: 8,
      year_109: "蔗蜜坊洗碗精",
      year_107: "蔗蜜坊 洗衣精",
    },
    {
      suggestion: "必",
      share_price: 15,
      code: 2820,
      name: "華票",
      year_113: "卡-711中美式/現萃茶卡",
      price_113: 17,
      year_110: "卡-全家35元 禮贈",
      year_107: "川本家 真空燜燒罐",
    },
    {
      suggestion: "必",
      share_price: 29.65,
      code: 2832,
      name: "台產",
      year_113: "",
      price_113: "",
      year_112: "卡-全家50元",
      price_112: 39,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 15,
      code: 2834,
      name: "台企銀",
      year_113: "卡-711卡100元",
      price_113: 83,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_110: "兩極食器精品杯",
      year_107: "耐熱食器側柄玻璃沖泡壺",
    },
    {
      share_price: "下市",
      code: 2841,
      name: "台開",
      year_113: "",
      price_113: "",
      year_112: "卡-全家35元",
      price_112: 25,
      year_111: "泰山調和油1L",
      year_110: "森田藥妝面膜2片",
      year_109: "檸檬磚12入和入場券",
      year_108: "杯盤組盒",
      year_107: "泰崗岩茶20入",
    },
    {
      share_price: 25.05,
      code: 2852,
      name: "第一產",
      year_113: "",
      price_113: "",
      year_108: "高級原子筆",
      year_107: "高級原子筆",
    },
    {
      suggestion: "必",
      share_price: 27.85,
      code: 2880,
      name: "華南金",
      year_113: "SNOOPY事事如意碗組",
      price_113: 45,
      year_112: "卡娜赫拉保鮮盒",
      price_112: 40,
      year_111: "樂扣樂扣耐熱玻璃保鮮盤",
      price_111: 35,
      year_110: "法國樂美雅餐盤2入組",
      year_109: "捲捲矽水瓶",
      year_108: "拉拉熊保溫袋+保鮮盒",
      year_107: "樂美雅露特莎8吋方深盤二入",
    },
    {
      suggestion: "必",
      share_price: 17.3,
      code: 2883,
      name: "開發金",
      year_113: "經典野餐墊一入",
      price_113: 15,
      year_112: "不銹鋼晶鑽杯",
      price_112: 65,
      year_111: "故宮花鳥碗(6入)組",
      price_111: 80,
      year_110: "觸控顯示智能保溫杯",
      year_109: "生活工廠多功能儲物罐三件套",
      year_108: "折疊椅",
      year_107: "水晶耐熱烤盤",
    },
    {
      suggestion: "必",
      share_price: 35.1,
      code: 2885,
      name: "元大金",
      year_113: "卡-全家100元",
      price_113: 85,
      year_112: "卡-711卡100元",
      price_112: 81,
      year_111: "卡-711卡100元",
      price_111: 82,
      year_109: "康寧晶彩琥珀餐盤2入組",
      year_108: "寬口雙肩背包",
      year_107: "太陽能充電圓燈",
    },
    {
      suggestion: "必",
      share_price: 17.45,
      code: 2887,
      name: "台新金",
      year_113: "方形密封易撥罐/控油瓶/手機架1:1:1",
      price_113: 10,
      year_112: "玻璃調味罐",
      price_112: 8,
      year_111: "草本抗菌皂三入",
      price_111: 10,
      year_110: "攜帶型手機平板支架",
      year_107: "折疊後背包",
    },
    {
      suggestion: "必",
      share_price: 12.3,
      code: 2888,
      name: "新光金",
      year_113: "不銹鋼真空保溫瓶",
      price_113: 20,
      year_112: "摩斯漢堡兌換券",
      price_112: 40,
    },
    {
      suggestion: "必",
      share_price: 14.3,
      code: 2889,
      name: "國票金",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 22.45,
      code: 2890,
      name: "永豐金",
      year_113: "豐收椅",
      price_113: 40,
      year_112: "寬庭豐收盤組",
      price_112: 25,
      year_111: "黃金萬兩茶摳禮盒",
      price_111: 10,
      year_110: "寬庭時尚旅行毯",
      year_109: "德國雙人牌雙面抗菌砧板",
      year_108: "喜穀豐藏米禮盒",
    },
    {
      suggestion: "必",
      share_price: 39.75,
      code: 2891,
      name: "中信金",
      year_113: "不鏽鋼雙層隔熱碗/圓形微波保鮮盒1:1",
      price_113: 18,
      year_112: "圓形微波保鮮盒",
      price_112: 15,
      year_111: "智能肩頸舒緩儀",
      price_111: 35,
      year_110: "臺灣茶摳防疫隨身組",
      year_109: "日式碟筷架組",
      year_108: "可攜式USB隨心燈",
      year_107: "環保蔬果削皮器",
    },
    {
      suggestion: "必",
      share_price: 8,
      code: 2911,
      name: "麗嬰房",
      year_113: "嬰兒柔護濕巾20抽單包",
      price_113: 5,
      year_112: "麗嬰房嬰兒柔護濕巾20抽",
      price_112: 5,
      year_111: "麗嬰房嬰兒柔護濕巾20抽",
      price_111: 5,
      year_110: "麗嬰房嬰兒柔護濕巾20抽",
      year_109: "超純水嬰兒潔膚柔濕巾20抽單包",
      year_108: "超純水柔濕巾20抽單包",
      year_107: "超純水柔濕巾20抽單包",
    },
    {
      suggestion: "必",
      share_price: 19,
      code: 2913,
      name: "農林",
      year_113: "Lumnarc玻璃微波保鮮盒370ml",
      price_113: 15,
      year_112: "手機支架",
      price_112: 5,
      year_111: "鍋寶多功能電子鍋三人份",
      price_111: 200,
      year_110: "德威樂義大利麵一包",
      year_109: "德威樂義大利麵一包",
      year_108: "德威樂義大利麵二包",
      year_107: "仙女紅茶袋裝",
    },
    {
      suggestion: "必",
      share_price: 13.95,
      code: 2929,
      name: "淘帝",
      year_113: "卡-711中美式/現萃茶卡",
      price_113: 17,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "可",
      share_price: 91,
      code: 2947,
      name: "振宇",
      year_113: "",
      price_113: "",
      year_112: "316餐具組",
      price_112: 10,
      year_111: "小家私隨手工具箱",
      price_111: 10,
    },
    {
      share_price: 97.9,
      code: 3004,
      name: "豐達",
      year_113: "",
      price_113: "",
      year_112: "米薄餅",
      price_112: 10,
      year_109: "華南特調掛耳咖啡",
    },
    {
      share_price: 122,
      code: 3005,
      name: "神基",
      year_113: "",
      price_113: "",
      year_107: "澳洲天然草本蜂蜜洗髮精",
    },
    {
      share_price: 61.8,
      code: 3006,
      name: "晶豪科",
      year_113: "環保原子筆組",
      price_113: 5,
      year_112: "原子筆",
      price_112: 5,
      year_111: "高級原子筆",
      price_111: 5,
      year_110: "高級原子筆",
      year_109: "高級原子筆",
      year_108: "高級原子筆",
      year_107: "高級原子筆",
    },
    {
      suggestion: "必",
      share_price: 116.5,
      code: 3010,
      name: "華立",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "卡-711卡100元",
      price_112: 81,
      year_111: "古寶無患子噴霧式乾洗手",
      price_111: 17,
      year_110: "古寶無患子噴霧式乾洗手",
      year_109: "耐熱分隔玻璃保鮮盒",
      year_108: "316不鏽鋼環保吸管組",
    },
    {
      suggestion: "必",
      share_price: 17.75,
      code: 3011,
      name: "今皓",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "春之玫瑰造形香皂乙顆",
      price_111: 5,
    },
    {
      suggestion: "必",
      share_price: 143,
      code: 3014,
      name: "聯陽",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元替",
      price_112: 37,
      year_111: "蜂王抗菌洗手露",
      price_111: 37,
      year_110: "蜂王抗菌洗手露",
      year_109: "五穀米",
      year_108: "牙刷牙膏組",
      year_107: "池上米 600克",
    },
    {
      share_price: 549,
      code: 3017,
      name: "奇鋐",
      year_113: "",
      price_113: "",
      year_111: "伊莎貝爾香皂三入",
      price_111: 10,
    },
    {
      suggestion: "必",
      share_price: 27,
      code: 3018,
      name: "隆銘",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 35,
      year_111: "卡-711拿鐵",
      price_111: 23,
      year_108: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 186,
      code: 3019,
      name: "亞光",
      year_113: "炫彩時尚杯",
      price_113: 6,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "高露潔旅行組(二入組)",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "香桔淨濃縮洗衣酵素粉1公斤",
    },
    {
      suggestion: "必",
      share_price: 271,
      code: 3023,
      name: "信邦",
      year_113: "保冷袋",
      price_113: 40,
      year_112: "不鏽鋼保鮮盒",
      price_112: 40,
      year_111: "多功能咖啡研磨杯",
      price_111: 30,
      year_110: "隨行杯果汁機",
      year_109: "多功能工具組",
      year_108: "耳機(單耳)",
      year_107: "不鏽鋼保溫保冰晶鑽杯",
    },
    {
      suggestion: "必",
      share_price: 18,
      code: 3024,
      name: "憶聲",
      year_113: "皮革收納筒",
      price_113: 6,
      year_112: "袖套",
      price_112: 5,
      year_111: "愛佳寶不鏽鋼料理剪刀",
      price_111: 15,
      year_110: "保溫保冰袋",
      year_109: "沙威隆洗手乳",
      year_108: "獅王潔白牙膏",
      year_107: "蘭麗洗手乳",
    },
    {
      suggestion: "可",
      share_price: 69,
      code: 3025,
      name: "星通",
      year_113: "飲料提袋",
      price_113: 3,
      year_112: "手提袋",
      price_112: 5,
      year_111: "摺疊手機座",
      price_111: 8,
      year_110: "不鏽鋼吸管組",
      year_109: "蜂王草本抗菌皂一入",
      year_108: "蜂王黑砂糖香皂",
      year_107: "蜂王美肌皂",
    },
    {
      suggestion: "必",
      share_price: 88.9,
      code: 3026,
      name: "禾伸堂",
      year_113: "手機掛繩",
      price_113: 10,
      year_112: "行李束帶吊牌組",
      price_112: 8,
      year_111: "側背袋",
      price_111: 8,
      year_110: "運動毛巾",
      year_109: "陶瓷杯墊二入",
      year_108: "繽紛生活紙膠帶組",
      year_107: "側背袋",
    },
    {
      suggestion: "必",
      share_price: 29.9,
      code: 3027,
      name: "盛達",
      year_113: "皂-蜂王洋甘菊親膚皂一入 80g",
      price_113: 5,
      year_112: "珪藻土杯墊",
      price_112: 5,
      year_111: "蜂王洗碗精 盒",
      price_111: 10,
      year_110: "防疫抗菌口罩盒組",
      year_109: "蜂王草本抗菌皂二入",
      year_108: "甦芙植妍皂二入組",
      year_107: "薰衣草精油皂二入組",
    },
    {
      suggestion: "必",
      share_price: 29.95,
      code: 3028,
      name: "增你強",
      year_113: "折疊式擦拭板手機座",
      price_113: 5,
      year_112: "木質手機座",
      price_112: 5,
      year_111: "酒精噴霧瓶",
      price_111: 5,
      year_110: "手指按摩器",
      year_109: "迷你伸縮鍵盤刷",
      year_108: "修容組",
      year_107: "LED燈開瓶器",
    },
    {
      suggestion: "必",
      share_price: 150.5,
      code: 3029,
      name: "零壹",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-711卡35元",
      price_112: 20,
      year_111: "卡-711卡35元",
      price_111: 20,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 91,
      code: 3032,
      name: "偉訓",
      year_113: "妙管家小蘇打抗菌洗衣精",
      price_113: 6,
      year_112: "毛寶葳香抗菌洗衣精",
      price_112: 8,
      year_111: "毛寶洗碗精",
      price_111: 10,
    },
    {
      suggestion: "必",
      share_price: 34.6,
      code: 3033,
      name: "威健",
      year_113: "柴語錄玻璃保鮮盒/卡100元",
      price_113: 40,
      year_112: "卡-全家100元替",
      price_112: 85,
      year_111: "卡-全家100元替",
      price_111: 50,
      year_110: "卡-全家100元替",
      year_109: "全家禮物卡50元",
      year_108: "卡-全家100元",
      year_107: "單肩斜背包",
    },
    {
      suggestion: "必",
      share_price: 544,
      code: 3034,
      name: "聯詠",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-全家50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 240.5,
      code: 3035,
      name: "智原",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-全家50元",
      year_107: "電子行李秤",
    },
    {
      suggestion: "必",
      share_price: 106.5,
      code: 3036,
      name: "文曄",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 35,
      year_111: "卡-711卡50元",
      price_111: 34,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡88",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 125,
      code: 3037,
      name: "欣興",
      year_113: "卡-OK卡100元",
      price_113: 75,
      year_112: "卡-ok卡100",
      price_112: 65,
      year_111: "矽膠摺疊杯",
      price_111: 15,
      year_110: "成人平面口罩",
      year_109: "Unifresh 植物菁粹面膜",
      year_108: "粉漾手提陶瓷杯",
      year_107: "台灣茶摳故事五件禮盒",
    },
    {
      suggestion: "必",
      share_price: 27.25,
      code: 3038,
      name: "全台",
      year_113: "帆布袋",
      price_113: 5,
      year_112: "毛寶洗衣精",
      price_112: 7,
      year_111: "毛寶洗衣精",
      price_111: 6,
      year_110: "耐熱玻璃保鮮盒",
      year_109: "蜂王抗菌皂二入",
      year_108: "蜂王植萃皂",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 35,
      code: 3041,
      name: "揚智",
      year_113: "輕便旅行分裝四入組",
      price_113: 5,
      year_112: "購物袋",
      price_112: 5,
      year_111: "露營餐具",
      price_111: 10,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 100.5,
      code: 3042,
      name: "晶技",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 35,
      year_111: "卡-711卡50元",
      price_111: 34,
      year_108: "收納袋",
      year_107: "環保餐具四件組",
    },
    {
      suggestion: "必",
      share_price: 34.5,
      code: 3043,
      name: "科風",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_110: "卡-711卡35元",
      year_109: "不銹鋼吸管",
      year_108: "不銹鋼吸管",
      year_107: "防曬袖套",
    },
    {
      suggestion: "必",
      share_price: 29.25,
      code: 3047,
      name: "訊舟",
      year_113: "皂-蜂王洋甘菊潤膚皂二入 70g",
      price_113: 6,
      year_112: "蜂王植粹精華皂二入盒",
      price_112: 5,
      year_111: "橄欖草本潤澤皂二入盒",
      price_111: 5,
      year_110: "植萃天然精華皂二入盒",
      year_109: "蜂王抗菌皂二入",
      year_108: "純萃甦芙植妍皂二入禮盒",
      year_107: "蜂王乳油木果精華皂2入禮盒",
    },
    {
      suggestion: "必",
      share_price: 29.5,
      code: 3048,
      name: "益登",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 8.42,
      code: 3049,
      name: "和鑫",
      year_113: "卡-宜睿好禮即享券50元-替",
      price_113: 10,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "HANNS品牌購物袋",
      year_109: "馬卡龍香氛皂",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 16.65,
      code: 3050,
      name: "鈺德",
      year_113: "折疊傘",
      price_113: 12,
      year_112: "香氛噴霧 小",
      price_112: 5,
      year_111: "防蚊液 30ml 小",
      price_111: 10,
      year_110: "隨身酒精清潔液",
      year_109: "巧衣架",
      year_108: "收納鞋架",
      year_107: "四爪滾輪按摩器",
    },
    {
      suggestion: "必",
      share_price: 32.1,
      code: 3051,
      name: "力特",
      year_113: "金安德森高級碳鋼平底牛排煎鍋",
      price_113: 30,
      year_112: "料理剪刀",
      price_112: 10,
      year_111: "3-料理剪刀",
      price_111: 15,
      year_110: "台灣茶摳生薑滋養皂",
      year_109: "依莎貝爾香皂",
      year_108: "蘭麗綿羊皂",
      year_107: "妙管家洗衣粉",
    },
    {
      suggestion: "必",
      share_price: 11.3,
      code: 3052,
      name: "夆典",
      year_113: "安妮兔玻璃胖胖瓶 300ml",
      price_113: 5,
      year_112: "手機支架",
      price_112: 5,
      year_111: "五件式修容組",
      price_111: 15,
      year_110: "8+1手電筒工具組",
      year_109: "多功能手電筒",
      year_108: "保溫保冷袋",
      year_107: "耐熱玻璃瓶附提袋",
    },
    {
      share_price: 75.1,
      code: 3055,
      name: "蔚華科",
      year_113: "",
      price_113: "",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 32.45,
      code: 3056,
      name: "總太",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 35,
      year_111: "卡-711卡50元",
      price_111: 37,
    },
    {
      suggestion: "必",
      share_price: 11.85,
      code: 3057,
      name: "喬鼎",
      year_113: "LED觸控筆",
      price_113: 3,
      year_112: "萬用轉接頭",
      price_112: 5,
      year_111: "折疊式手機架",
      price_111: 10,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-全家50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 15.4,
      code: 3058,
      name: "立德",
      year_113: "皂-蜂王洋甘菊潤膚皂一入 70 g",
      price_113: 5,
      year_112: "蜂王香氛皂(櫻,鬱,薰衣草)",
      price_112: 5,
      year_111: "伊莎貝爾抗菌皂",
      price_111: 5,
      year_110: "沙威隆經典抗菌皂",
      year_109: "蜂王草本抗菌皂",
      year_108: "蜂王保濕美肌潔膚皂",
      year_107: "蜂王天然無患子珍珠皂",
    },
    {
      suggestion: "必",
      share_price: 40.8,
      code: 3059,
      name: "華晶",
      year_113: "BLACK HAMMER 水壺袋",
      price_113: 15,
      year_112: "掌廚不鏽鋼保鮮盒",
      price_112: 35,
      year_111: "不鏽鋼隔熱餐碗",
      price_111: 25,
      year_110: "時尚隨行玻璃杯",
      year_109: "無染棉浴巾禮盒",
      year_108: "不銹鋼吸管五件組",
      year_107: "櫻花野餐保溫袋",
    },
    {
      suggestion: "必",
      share_price: 24.55,
      code: 3060,
      name: "銘異",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "卡-全家100元",
      price_112: 85,
      year_111: "卡-全家100元",
      price_111: 85,
      year_110: "卡-全家100元",
      year_109: "卡-全家100元",
      year_108: "卡-全家50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 32.25,
      code: 3062,
      name: "建漢",
      year_113: "玻璃控油壺",
      price_113: 5,
      year_112: "美甲修容4件組",
      price_112: 5,
      year_111: "馬克杯",
      price_111: 5,
      year_110: "帆布手提袋",
      year_109: "LED強光手電筒檯燈",
      year_108: "LED隨身風扇",
      year_107: "不鏽鋼吸管組",
    },
    {
      suggestion: "必",
      share_price: 86.5,
      code: 3078,
      name: "僑威",
      year_113: "手提袋",
      price_113: 5,
      year_112: "洗碗精",
      price_112: 10,
      year_111: "毛寶液體皂 500g",
      price_111: 6,
      year_110: "保溫保冷手提袋",
      year_109: "卡-711卡35元",
      year_108: "手提袋",
      year_107: "袋中袋",
    },
    {
      suggestion: "可",
      share_price: 490,
      code: 3081,
      name: "聯亞",
      year_113: "卡-711卡50元",
      price_113: 38,
    },
    {
      suggestion: "必",
      share_price: 72,
      code: 3090,
      name: "日電貿",
      year_113: "皂-蜂王洋甘菊潤膚皂二入 60g",
      price_113: 6,
      year_112: "蜂王植萃精華皂二入皂盒",
      price_112: 5,
      year_111: "抗菌石鹼 二入",
      price_111: 5,
      year_110: "蜂王草本抗菌皂二入",
      year_109: "蜂王草本抗菌皂二入",
      year_108: "蜂王乳油木果精華皂二入",
      year_107: "高級原子筆一支",
    },
    {
      suggestion: "必",
      share_price: 26.5,
      code: 3092,
      name: "鴻碩",
      year_113: "訊號線",
      price_113: 5,
      year_112: "訊號線",
      price_112: 5,
      year_111: "訊號線",
      price_111: 10,
    },
    {
      suggestion: "必",
      share_price: 30.25,
      code: 3094,
      name: "聯傑",
      year_113: "工具組",
      price_113: 5,
      year_112: "保溫提袋",
      price_112: 10,
      year_111: "醋",
      price_111: 5,
      year_110: "手機架",
      year_109: "手電筒",
      year_108: "保鮮盒",
      year_107: "保鮮盒",
    },
    {
      suggestion: "必",
      share_price: 41,
      code: 3095,
      name: "及成",
      year_113: "皂-伊莎貝爾櫻花皂 三入 75g",
      price_113: 6,
      year_112: "不銹鋼餐具盒",
      price_112: 5,
      year_111: "抗菌洗手乳",
      price_111: 12,
      year_110: "不鏽鋼餐具三件組",
      year_109: "蘭麗抗菌皂彩盒(三入)",
      year_108: "不鏽鋼環保吸管組",
      year_107: "企鵝杯",
    },
    {
      suggestion: "必",
      share_price: 32.55,
      code: 3118,
      name: "進階",
      year_113: "卡-全家35元",
      price_113: 26,
      year_112: "卡-全家35元",
      price_112: 25,
      year_111: "卡-全家35元",
      price_111: 25,
      year_110: "卡-全家35元禮贈卡",
      year_109: "卡-全家35元",
      year_108: "卡-全家35元",
      year_107: "卡-全家35元",
    },
    {
      suggestion: "必",
      share_price: 30.5,
      code: 3122,
      name: "笙泉",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-全家中美式",
      price_112: 12,
      year_111: "卡-全家中美式",
      price_111: 15,
      year_110: "卡-711卡35元",
      year_109: "卡-711卡35元",
      year_108: "卡-711卡35元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 29.2,
      code: 3128,
      name: "昇銳",
      year_113: "卡-全家35元",
      price_113: 26,
      year_112: "卡-全家35元",
      price_112: 25,
      year_111: "卡-全家50元",
      price_111: 39,
      year_110: "卡-全家35元",
      year_109: "卡-全家35元",
      year_108: "卡-全家35元",
      year_107: "卡-全家35元",
    },
    {
      suggestion: "必",
      share_price: 1315,
      code: 3131,
      name: "弘塑",
      year_113: "皂-伊莎貝爾涼感運動皂 94克一入",
      price_113: 5,
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 113.5,
      code: 3138,
      name: "耀登",
      year_113: "保冷袋",
      price_113: 8,
      year_112: "手提復古露營燈",
      price_112: 20,
      year_111: "陶瓷噴嘴奈米噴霧機",
      price_111: 10,
      year_110: "迷你杯",
    },
    {
      suggestion: "必",
      share_price: 72.9,
      code: 3141,
      name: "晶宏",
      year_113: "皂-沙威隆抗菌皂二入 80g",
      price_113: 5,
      year_112: "螢光筆組/口罩",
      price_112: 5,
      year_111: "防疫口罩",
      price_111: 5,
      year_110: "酒精濕巾",
      year_109: "文件夾",
      year_108: "自粘便利貼紙組",
      year_107: "環保袋",
    },
    {
      suggestion: "必",
      share_price: 31.7,
      code: 3149,
      name: "正達",
      year_113: "洗碗精",
      price_113: 0,
      year_112: "洗手乳",
      price_112: 8,
      year_111: "卡-711中美式",
      price_111: 12,
      year_110: "卡-711中美式",
      year_109: "濃縮洗衣精",
      year_108: "卡-全家中美式",
      year_107: "卡-全家中美式",
    },
    {
      suggestion: "必",
      share_price: 162.5,
      code: 3163,
      name: "波若威",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 35,
      year_111: "卡-711卡50元",
      price_111: 34,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 109,
      code: 3169,
      name: "亞信",
      year_113: "卡-711卡50元",
      price_113: 36,
      year_112: "卡-711卡50元",
      price_112: 30,
      year_111: "卡-711卡50元",
      price_111: 32,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 36.2,
      code: 3176,
      name: "基亞",
      year_113: "石墨膝能量護膝",
      price_113: 25,
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-全100元禮贈卡",
      year_109: "卡-全家50元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 17.65,
      code: 3191,
      name: "和進",
      year_113: "卡-全家35元",
      price_113: 26,
      year_112: "卡-全家35元",
      price_112: 25,
      year_110: "卡-全家35元",
    },
    {
      suggestion: "必",
      share_price: 6,
      code: 3202,
      name: "樺晟",
      year_113: "露營304不鏽鋼摺疊餐具2件組",
      price_113: 8,
      year_112: "旅遊收納二件組",
      price_112: 10,
      year_111: "Oral Care口腔護理漱口水",
      price_111: 12,
      year_110: "伊莎貝爾手工香皂四入組",
      year_108: "LED手持風扇",
      year_107: "手機吸盤固定座",
    },
    {
      suggestion: "必",
      share_price: 32.35,
      code: 3205,
      name: "佰研",
      year_113: "HealthOne植酵液體驗組 6包/組",
      price_113: 10,
      year_112: "葉黃素膠囊/包",
      price_112: 15,
      year_111: "葉黃素複方膠囊 14顆",
      price_111: 15,
      year_110: "葉黃素複方膠囊(14顆/包)",
      year_109: "越光米",
      year_108: "初鹿牧場棉花牛奶糖",
      year_107: "法莫莊園有機紅藜麥",
    },
    {
      suggestion: "必",
      share_price: 44.8,
      code: 3206,
      name: "志豐",
      year_113: "木製精巧手機座",
      price_113: 5,
      year_112: "環保袋著走",
      price_112: 5,
      year_111: "環保餐具組",
      price_111: 5,
      year_110: "平面口罩(五入裝)",
      year_109: "有線耳機",
      year_108: "有線耳機",
      year_107: "有線耳機",
    },
    {
      suggestion: "必",
      share_price: 110.5,
      code: 3207,
      name: "耀勝",
      year_113: "濕紙巾 10抽 x 三包",
      price_113: 5,
      year_112: "卡-711卡50元",
      price_112: 35,
      year_108: "露營燈",
      year_107: "環保袋",
    },
    {
      suggestion: "必",
      share_price: 34.15,
      code: 3209,
      name: "全科",
      year_113: "康寧餐盤",
      price_113: 12,
      year_112: "充電線",
      price_112: 10,
      year_111: "微型LED手電筒",
      price_111: 5,
      year_110: "高效節能燈泡",
      year_109: "喜馬拉雅山玫瑰鹽",
      year_108: "南非琥珀茶",
      year_107: "益生菌",
    },
    {
      suggestion: "必",
      share_price: 182,
      code: 3211,
      name: "順達",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-全家80元",
      year_107: "卡-711卡80元",
    },
    {
      suggestion: "必",
      share_price: 96.2,
      code: 3213,
      name: "茂訊",
      year_113: "食品級304不鏽鋼多功能泡麵碗",
      price_113: 15,
      year_112: "布里昂雙開蓋瓶",
      price_112: 20,
      year_111: "呷賀米 500 g",
      price_111: 12,
      year_110: "粉彩一壺四杯",
      year_109: "甜心玻璃儲物罐",
      year_108: "繽紛糖果色系折疊杯",
      year_107: "馬卡龍折疊購物袋",
    },
    {
      share_price: 218,
      code: 3218,
      name: "大學光",
      year_113: "",
      price_113: "",
      year_107: "卡-711卡35元",
    },
    {
      suggestion: "必",
      share_price: 23.35,
      code: 3221,
      name: "台嘉碩",
      year_113: "條紋收納包",
      price_113: 5,
      year_112: "原子筆",
      price_112: 5,
      year_111: "方形折疊購物袋",
      price_111: 5,
      year_110: "360度吸盤手機支架",
      year_109: "額溫檢測卡",
      year_108: "精美LED手電筒",
    },
    {
      share_price: 50,
      code: 3224,
      name: "三顧",
      year_113: "皂-蜂王洋甘菊潤膚皂二入 70g",
      price_113: 6,
    },
    {
      suggestion: "必",
      share_price: 265,
      code: 3227,
      name: "原相",
      year_113: "彼得兔多功能運動毛巾",
      price_113: 15,
      year_112: "Luminarc 21cm餐盤",
      price_112: 15,
      year_111: "美麗佳人多功能運動巾",
      price_111: 15,
      year_110: "義大利BORMIOLI 20cm白玉玻璃湯盤",
      year_109: "康寧 晶彩琥珀8.5吋深盤",
      year_108: "Luminarc樂美雅露特莎7吋拉麵碗",
      year_107: "樂美雅8吋強化深盤",
    },
    {
      suggestion: "必",
      share_price: 206,
      code: 3228,
      name: "金麗科",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "卡-全家50元",
      price_112: 39,
      year_111: "卡-全家50元",
      price_111: 39,
    },
    {
      share_price: 18,
      code: 3229,
      name: "晟鈦",
      year_113: "",
      price_113: "",
      year_110: "G!Mobile G SIM卡",
      year_109: "卡-711卡35元",
    },
    {
      share_price: 55.7,
      code: 3230,
      name: "錦明",
      year_113: "卡-錦明股東專屬會員卡",
      price_113: 2,
      year_112: "（線上申請兌換）",
      price_112: 5,
      year_111: "2600元滿額消費抵用點數",
    },
    {
      share_price: 26.4,
      code: 3232,
      name: "昱捷",
      year_113: "",
      price_113: "",
      year_111: "燕麥肌膚舒緩柔膚皂",
      price_111: 5,
      year_107: "吸管餐具",
    },
    {
      suggestion: "必",
      share_price: 49.5,
      code: 3234,
      name: "光環",
      year_113: "輕便手提防塵鞋袋",
      price_113: 5,
      year_112: "卡-全家35元",
      price_112: 20,
      year_111: "LED鑰匙圈",
      year_110: "LED鑰匙圈",
      year_109: "工具組",
      year_108: "工具組",
      year_107: "卡-全家中美式",
    },
    {
      suggestion: "必",
      share_price: 19.7,
      code: 3236,
      name: "千如",
      year_113: "皂-蜂王洋甘菊潤膚皂二入 70g",
      price_113: 8,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      share_price: 58.2,
      code: 3257,
      name: "虹冠電",
      year_113: "",
      price_113: "",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 76.5,
      code: 3260,
      name: "威剛",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 76.8,
      code: 3264,
      name: "欣銓",
      year_113: "卡-711卡50元",
      price_113: 36,
      year_112: "卡-711卡88元",
      price_112: 64,
      year_111: "卡-711卡50元",
      year_110: "卡-711卡60元",
      year_109: "卡-711卡88",
      year_108: "卡-全家50元",
      year_107: "發芽玄米",
    },
    {
      suggestion: "必",
      share_price: 22.05,
      code: 3268,
      name: "海德威",
      year_113: "卡-711卡35元",
      price_113: 23,
      year_112: "卡-711卡35元",
      price_112: 20,
      year_111: "卡-711卡35元",
      price_111: 21,
      year_110: "卡-711卡35元",
      year_109: "卡-711卡35元",
      year_108: "五件式筆組",
      year_107: "折疊購物袋",
    },
    {
      suggestion: "必",
      share_price: 15.6,
      code: 3276,
      name: "宇環",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-全家50元",
    },
    {
      share_price: 34.6,
      code: 3284,
      name: "太普高",
      year_113: "",
      price_113: "",
      year_109: "卡-711卡35元",
      year_108: "卡-711卡50元",
      year_107: "卡-全家50元",
    },
    {
      share_price: 21.25,
      code: 3288,
      name: "點晶",
      year_113: "",
      price_113: "",
      year_111: "卡-711卡35元",
      price_111: 23,
    },
    {
      suggestion: "必",
      share_price: 133.5,
      code: 3289,
      name: "宜特",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-全家50元",
      year_109: "卡-全家50元",
      year_108: "卡-全家50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 34.35,
      code: 3290,
      name: "東浦",
      year_113: "卡-全家中美式",
      price_113: 12,
      year_112: "卡-全家中美式",
      price_112: 12,
      year_111: "卡-全家中美式 限期",
      price_111: 15,
      year_110: "高級鋼珠筆",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 69.4,
      code: 3305,
      name: "昇貿",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-飯店券",
      price_112: 5,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_110: "卡-711卡35元",
      year_109: "桃禧集團100元現金抵用券",
      year_108: "卡-711卡35元",
      year_107: "環保飲料杯手提套",
    },
    {
      suggestion: "必",
      share_price: 49.4,
      code: 3306,
      name: "鼎天",
      year_113: "高露潔全效旅行組",
      price_113: 8,
      year_112: "高露潔全效旅行組",
      price_112: 8,
      year_111: "高露潔旅行輕便三入組",
      price_111: 10,
    },
    {
      suggestion: "必",
      share_price: 33.85,
      code: 3311,
      name: "閎暉",
      year_113: "美式格紋收納提籃",
      price_113: 6,
      year_112: "野餐墊",
      price_112: 5,
      year_111: "愛盲手工皂袋組",
      price_111: 5,
      year_110: "環保玻璃瓶",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 52.1,
      code: 3312,
      name: "弘憶股",
      year_113: "",
      price_113: "",
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_110: "卡-711卡35元",
      year_109: "卡-711卡35元",
      year_107: "卡-711卡35元",
    },
    {
      suggestion: "必",
      share_price: 15.55,
      code: 3313,
      name: "斐成",
      year_113: "葉黃素魚油軟膠囊一包10粒",
      price_113: 5,
      year_112: "洋甘菊皂 三入",
      price_112: 8,
      year_111: "毛寶繽紛花園抗菌洗手乳",
      price_111: 12,
      year_110: "不鏽鋼玻璃調味瓶",
      year_109: "小麥推蓋式不鏽鋼304環保餐具組",
      year_108: "折疊陶瓷刀+陶瓷刨刀",
      year_107: "蜂王地中海薰衣草皂二入",
    },
    {
      suggestion: "必",
      share_price: 46.9,
      code: 3317,
      name: "尼克森",
      year_113: "好來潔牙雙人組(牙膏50g+牙刷*2入)",
      price_113: 15,
      year_112: "好來牙膏牙刷組",
      price_112: 10,
      year_111: "卡-711卡100元",
      price_111: 82,
      year_110: "黑人牙膏牙刷組",
      year_109: "高露潔旅行組",
      year_108: "黑人牙膏牙刷組",
      year_107: "黑人牙膏牙刷組",
    },
    {
      suggestion: "必",
      share_price: 15.8,
      code: 3321,
      name: "同泰",
      year_113: "卡-711卡50元",
      price_113: 36,
      year_112: "卡-711卡50元",
      price_112: 30,
      year_111: "卡-711卡50元",
      price_111: 34,
      year_110: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 18.3,
      code: 3322,
      name: "建舜電",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711中美式",
      year_109: "卡-711卡35元",
      year_108: "卡-711卡35元",
      year_107: "卡-711卡35元",
    },
    {
      suggestion: "必",
      share_price: 35.45,
      code: 3323,
      name: "加百裕",
      year_113: "卡-宜睿好禮即享券50元",
      price_113: 35,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "茶樹洗手串",
      year_108: "卡-711卡50元",
      year_107: "玻璃梅森瓶組",
    },
    {
      suggestion: "必",
      share_price: 591,
      code: 3324,
      name: "雙鴻",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "卡-全家50元",
      price_112: 39,
      year_111: "卡-全家50元",
      price_111: 39,
      year_110: "卡-全家50元",
      year_109: "卡-全家50元",
      year_108: "卡-全家50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 19.3,
      code: 3325,
      name: "旭品",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "環保餐具組",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 60.2,
      code: 3338,
      name: "泰碩",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "卡-全家50元",
      price_112: 39,
      year_111: "卡-全家50元",
      price_111: 39,
      year_110: "卡-全家50元",
      year_109: "卡-全家50元",
      year_108: "卡-全家50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "可",
      share_price: 33.35,
      code: 3339,
      name: "泰谷",
      year_113: "卡-711卡50元",
      price_113: 36,
    },
    {
      suggestion: "必",
      share_price: 33.65,
      code: 3346,
      name: "麗清",
      year_113: "皂-MEDIMIX綠寶石美膚皂 125g",
      price_113: 5,
      year_112: "MEDIMIX 皂",
      price_112: 5,
      year_111: "MEDIMIX綠寶石皇室藥草浴美肌皂",
      price_111: 5,
      year_110: "MEDIMIX皇室藥草浴美肌皂",
    },
    {
      suggestion: "必",
      share_price: 56,
      code: 3356,
      name: "奇偶",
      year_113: "卡-711中美式/現萃茶卡",
      price_113: 18,
      year_112: "卡-711中美式",
      price_112: 18,
      year_111: "卡-711咖中美/現萃茶",
      price_111: 18,
      year_110: "卡-711咖中美/現萃茶",
      year_109: "7-11中杯美式咖啡卡",
      year_108: "卡-711卡35元",
      year_107: "卡-711卡35元",
    },
    {
      suggestion: "必",
      share_price: 13.65,
      code: 3360,
      name: "尚立",
      year_113: "環境大善 善玉活性水",
      price_113: 2,
      year_112: "歐佳水寶除菌液",
      price_112: 5,
      year_111: "歐佳水寶除菌液",
      price_111: 5,
      year_110: "歐佳水寶除菌液",
      year_109: "歐佳水寶除菌液",
      year_108: "歐佳水寶除菌液",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 335,
      code: 3363,
      name: "上詮",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "卡-全家35元",
      price_112: 25,
      year_111: "卡-全家35元",
      price_111: 25,
      year_110: "卡-全家35元",
      year_109: "卡-全家35元",
      year_108: "卡-全家35元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 14.4,
      code: 3372,
      name: "典範",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_107: "USB小米燈",
    },
    {
      suggestion: "必",
      share_price: 24.4,
      code: 3373,
      name: "熱映",
      year_113: "皂-蜂王洋甘菊潤膚皂三入 70 g",
      price_113: 10,
      year_112: "蜂王植萃精華皂三入皂",
      price_112: 10,
      year_111: "蜂王橄欖草本潤澤皂三入",
      price_111: 10,
      year_110: "蜂王植萃水感保濕皂三入",
      year_109: "蜂王抗菌皂三入",
      year_108: "強化玻璃餐盤",
      year_107: "蜂王乳油木果精華皂三入組",
    },
    {
      suggestion: "必",
      share_price: 248,
      code: 3376,
      name: "新日興",
      year_113: "皂-蜂王洋甘菊潤膚皂二入 60g",
      price_113: 5,
      year_112: "旅行牙膏組",
      price_112: 8,
      year_111: "手工的皂二入盒",
      price_111: 10,
      year_108: "清潔刷1個",
      year_107: "便條貼",
    },
    {
      suggestion: "必",
      share_price: 55.9,
      code: 3379,
      name: "彬台",
      year_113: "皂-伊莎貝爾櫻花皂 二入 75g",
      price_113: 5,
      year_112: "伊莎貝爾櫻花皂二入",
      price_112: 5,
      year_111: "蜂王植萃精華皂兩入",
      price_111: 5,
      year_108: "瓷杯一只",
      year_107: "馬克杯",
    },
    {
      share_price: 35.75,
      code: 3380,
      name: "明泰",
      year_113: "",
      price_113: "",
      year_109: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 27.75,
      code: 3390,
      name: "旭軟",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "卡-全家50元",
      price_112: 37,
      year_111: "卡-全家50元",
      price_111: 35,
      year_110: "卡-全家50元",
      year_109: "卡-全家50元",
      year_108: "卡-全家50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 104.5,
      code: 3402,
      name: "漢科",
      year_113: "伊莎貝爾無患子洗手乳250 ml",
      price_113: 10,
      year_112: "洗手乳-伊莎貝爾 250 ml",
      price_112: 10,
      year_111: "伊莎貝爾抗菌洗手乳",
      price_111: 12,
      year_110: "伊莎貝爾無患子洗手乳",
      year_109: "沙威隆洗手乳",
      year_108: "蛋型小夜燈",
      year_107: "迷你檯燈",
    },
    {
      suggestion: "必",
      share_price: 475,
      code: 3406,
      name: "玉晶光",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_111: "卡-全家50元",
      price_111: 39,
      year_108: "保溫杯",
    },
    {
      suggestion: "必",
      share_price: 17.7,
      code: 3419,
      name: "譁裕",
      year_113: "皂-蜂王香氛皂一入 70 g",
      price_113: 3,
      year_112: "蜂王香氛皂一入",
      price_112: 5,
      year_111: "蜂王 薰衣草皂一入",
      price_111: 5,
      year_110: "玫瑰精油香皂 一顆",
      year_109: "德國櫻花浪漫情懷皂",
      year_108: "手機支架",
    },
    {
      suggestion: "必",
      share_price: 61.9,
      code: 3430,
      name: "奇鈦",
      year_113: "卡-全家35元",
      price_113: 26,
      year_112: "卡-全家50元",
      price_112: 37,
    },
    {
      suggestion: "必",
      share_price: 21.25,
      code: 3437,
      name: "榮創",
      year_113: "皂-菲蘇德美燕麥潔膚皂",
      price_113: 5,
      year_112: "毛寶抗菌洗手乳",
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
    },
    {
      suggestion: "可",
      share_price: 62.5,
      code: 3438,
      name: "類比科",
      year_113: "卡-711卡50元",
      price_113: 36,
      year_107: "時尚陶瓷刀三件組",
    },
    {
      suggestion: "必",
      share_price: 38.2,
      code: 3441,
      name: "聯一光",
      year_113: "滾珠按摩器",
      price_113: 5,
      year_112: "小米燈/替",
      price_112: 5,
      year_111: "USB小米燈",
      price_111: 5,
      year_110: "USB小米燈",
      year_109: "USB小米燈",
      year_108: "環保杯袋",
      year_107: "冰涼感防曬袖套",
    },
    {
      suggestion: "必",
      share_price: 304,
      code: 3450,
      name: "聯鈞",
      year_113: "不鏽鋼玻璃調味罐",
      price_113: 8,
      year_112: "珪藻土洗漱墊",
      price_112: 5,
      year_111: "修甲美容組",
      price_111: 15,
      year_110: "妙管家多功能運動雪克杯",
      year_109: "康寧晶彩琥珀8.5吋深盤",
      year_108: "鍋寶多功能廚房剪刀",
      year_107: "卡-711中美式",
    },
    {
      suggestion: "必",
      share_price: 109,
      code: 3455,
      name: "由田",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 33,
      year_111: "卡-711卡50元",
      price_111: 34,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 98.8,
      code: 3479,
      name: "安勤",
      year_113: "卡-全家中美式(2027/06)",
      price_113: 12,
      year_112: "卡-全家中美式",
      price_112: 12,
      year_111: "卡-全家中美式",
      price_111: 15,
      year_110: "卡-全家中美式",
      year_109: "卡-全家中美式",
      year_108: "卡-全家中美式",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 149,
      code: 3483,
      name: "力致",
      year_113: "卡-全家35元",
      price_113: 26,
      year_112: "卡-全家35元",
      price_112: 25,
      year_111: "卡-全家35元",
      price_111: 25,
      year_110: "卡-全家35元",
      year_109: "卡-全家35元",
      year_108: "卡-全家35元",
      year_107: "卡-全家35元",
    },
    {
      suggestion: "必",
      share_price: 54.1,
      code: 3484,
      name: "崧騰",
      year_113: "口袋傘",
      price_113: 10,
      year_112: "不鏽鋼多功能碗",
      price_112: 15,
      year_111: "金安德森保溫瓶",
      price_111: 20,
      year_110: "飛狼百搭斜背包",
      year_109: "心想事成 串皂",
      year_108: "飛狼雙色拼接二用腰包",
      year_107: "電子行李秤",
    },
    {
      suggestion: "必",
      share_price: 30.45,
      code: 3490,
      name: "單井",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-711卡35元",
      price_112: 20,
      year_111: "卡-711卡35元",
      price_111: 20,
      year_110: "卡-711卡35元",
      year_109: "伊莎貝爾抗菌洗手乳",
      year_108: "手工羊毛皂",
      year_107: "卡-711卡50元",
    },
    {
      share_price: 401,
      code: 3491,
      name: "昇達科",
      year_113: "皂-蜂王洋甘菊潤膚皂二入 60g",
      price_113: 5,
      year_112: "抗菌香皂",
      price_112: 5,
      year_109: "蜂王草本抗菌皂二入",
    },
    {
      suggestion: "必",
      share_price: 19,
      code: 3494,
      name: "誠研",
      year_113: "卡+HiTi inPhoto相片沖印卷",
      price_113: 8,
      year_112: "HiTi相片沖印卷20張",
      price_112: 10,
      year_111: "HiTi相片沖印卷20張",
      price_111: 10,
      year_110: "HiTi相片沖印卷20張",
      year_109: "HiTi相片沖印卷20張",
      year_108: "HiTi inPhoto相片沖印卷",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 52.4,
      code: 3498,
      name: "陽程",
      year_113: "卡-全家100元",
      price_113: 85,
      year_112: "卡-全家50元",
      price_112: 39,
      year_111: "卡-全家50元",
      price_111: 39,
      year_110: "卡-全家50元",
      year_109: "卡-全家50元",
      year_108: "卡-全家50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 19.55,
      code: 3499,
      name: "環天科",
      year_113: "卡-全家35元",
      price_113: 26,
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-711卡35元",
      price_111: 25,
    },
    {
      suggestion: "必",
      share_price: 26.95,
      code: 3508,
      name: "位速",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 22.4,
      code: 3518,
      name: "柏騰",
      year_113: "保冷袋 shanldin標籤",
      price_113: 8,
      year_112: "保溫提袋",
      price_112: 10,
      year_111: "不鏽鋼隔熱餐碗",
      price_111: 20,
      year_110: "風潮水杯",
      year_109: "密扣式保鮮盒",
      year_108: "玻璃水杯400ML",
      year_107: "修容組",
    },
    {
      suggestion: "必",
      share_price: 18.55,
      code: 3520,
      name: "華盈",
      year_113: "卡-全家35元",
      price_113: 26,
      year_112: "卡-全家35元",
      price_112: 25,
      year_111: "卡-全家35元",
      price_111: 25,
      year_108: "卡-全家50元",
      year_107: "魔乾超細纖維擦拭布",
    },
    {
      suggestion: "必",
      share_price: 25.6,
      code: 3522,
      name: "御頂",
      year_113: "御頂集團品牌優惠券",
      price_113: 2,
      year_112: "卡-御頂券",
      price_112: 5,
      year_111: "御頂餐飲集團抵用券",
      price_111: 5,
      year_110: "御頂集團餐飲抵用券",
      year_109: "御頂集團餐飲抵用券伍佰元",
    },
    {
      share_price: 20.25,
      code: 3523,
      name: "迎輝",
      year_113: "",
      price_113: "",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 277.5,
      code: 3526,
      name: "凡甲",
      year_113: "皂-蜂王洋甘菊潤膚皂二入 60g",
      price_113: 6,
      year_112: "蜂王草本植舒亮澤皂二入",
      price_112: 5,
      year_111: "蜂王植萃精華皂二入盒",
      price_111: 5,
      year_110: "蜂王植萃精華皂二入盒",
      year_109: "蜂王草本抗菌皂二入",
      year_108: "台灣茶摳(肥皂)",
      year_107: "好無比超麗潔洗衣精",
    },
    {
      suggestion: "必",
      share_price: 75.7,
      code: 3527,
      name: "聚積",
      year_113: "木酢洗手慕斯 100 ml",
      price_113: 5,
      year_112: "四合一掀4蓋調味罐",
      price_112: 8,
      year_111: "愛佳寶不鏽鋼料理剪刀",
      price_111: 15,
      year_110: "伊莎貝爾無患子洗手乳",
      year_109: "手工香皂二入組",
      year_108: "蜂王植萃皂2入",
      year_107: "精美陶瓷刀具組",
    },
    {
      share_price: 83,
      code: 3530,
      name: "晶相光",
      year_113: "",
      price_113: "",
      year_111: "卡-全家50元",
      price_111: 39,
    },
    {
      suggestion: "必",
      share_price: 47.55,
      code: 3535,
      name: "晶彩",
      year_113: "環保袋",
      price_113: 5,
      year_112: "沙威隆一入",
      price_112: 5,
      year_111: "蜂王 薰衣草皂一入",
      price_111: 5,
      year_110: "蜂王薰衣草皂一入",
      year_109: "德國櫻花浪漫情懷皂",
      year_108: "德國櫻花浪漫情懷皂",
    },
    {
      suggestion: "必",
      share_price: 50.5,
      code: 3537,
      name: "堡達",
      year_113: "卡-全家35元",
      price_113: 26,
      year_112: "卡-全家中美式限替",
      price_112: 10,
      year_111: "卡-全家中美式限替",
      price_111: 15,
      year_108: "蜂王植萃皂2入",
    },
    {
      suggestion: "必",
      share_price: 38.65,
      code: 3540,
      name: "曜越",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_110: "卡-全家50元",
      year_109: "卡-711卡35元",
      year_108: "卡-711卡50元",
      year_107: "卡-全家50元",
    },
    {
      share_price: 28.2,
      code: 3541,
      name: "西柏",
      year_113: "公司LOGO金屬桿原子筆",
      price_113: 2,
      year_112: "原子筆",
      price_112: 5,
    },
    {
      suggestion: "必",
      share_price: 22.75,
      code: 3543,
      name: "州巧",
      year_113: "保冷袋 shanldin標籤",
      price_113: 10,
      year_112: "不鏽鋼吸管組",
      price_112: 8,
      year_111: "伊莎貝爾手工皂三入",
      price_111: 15,
      year_110: "保溫保冷袋",
    },
    {
      suggestion: "必",
      share_price: 77.5,
      code: 3545,
      name: "敦泰",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡100元",
      price_112: 81,
      year_111: "卡-711卡100元",
      price_111: 82,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡200元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "可",
      share_price: 76.6,
      code: 3546,
      name: "宇峻",
      year_113: "手機支架",
      price_113: 5,
      year_112: "手機支架",
      price_112: 5,
      year_111: "金屬杯墊",
      price_111: 5,
      year_110: "金屬杯墊",
      year_109: "手機指環扣",
      year_108: "手機指環扣",
      year_107: "手機指環扣",
    },
    {
      suggestion: "必",
      share_price: 172,
      code: 3548,
      name: "兆利",
      year_113: "卡-711中美式/現萃茶卡",
      price_113: 17,
      year_112: "卡-全家中美式 限",
      price_112: 10,
      year_111: "卡-全家中美式 限",
      price_111: 10,
      year_110: "卡-全家中美式 限",
      year_109: "卡-711咖中美",
      year_108: "卡-711卡35元",
      year_107: "卡-711卡35元",
    },
    {
      suggestion: "必",
      share_price: 16.45,
      code: 3550,
      name: "聯穎",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_110: "卡-711卡35元",
      year_109: "卡-711卡35元",
      year_108: "卡-711卡35元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 87.9,
      code: 3552,
      name: "同致",
      year_113: "卡-711卡100元",
      price_113: 83,
      year_112: "卡-711卡100元",
      price_112: 81,
      year_111: "卡-711卡100元",
      price_111: 82,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 41.15,
      code: 3556,
      name: "禾瑞亞",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡35元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 70,
      code: 3557,
      name: "嘉威",
      year_113: "手機架",
      price_113: 6,
      year_111: "EVAK真空儲物罐",
      price_111: 10,
      year_110: "鑽石紋雙層冷杯",
      year_109: "手機架",
      year_108: "戶外運動杯 600ml",
    },
    {
      suggestion: "必",
      share_price: 375,
      code: 3563,
      name: "牧德",
      year_113: "卡-宜睿即享券50元",
      price_113: 35,
      year_112: "卡-全家50元",
      price_112: 39,
      year_111: "卡-全家50元",
      price_111: 39,
      year_110: "卡-全家100元",
      year_109: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 32.3,
      code: 3567,
      name: "逸昌",
      year_113: "卡-全家35元",
      price_113: 24,
      year_112: "卡-全家35元",
      price_112: 20,
      year_111: "卡-全家中美式",
      price_111: 15,
      year_110: "卡-全家中美式",
      year_109: "卡-全家中美式",
      year_108: "卡-全家中美式",
      year_107: "卡-全家中拿鐵",
    },
    {
      suggestion: "必",
      share_price: 9.91,
      code: 3576,
      name: "聯合再生",
      year_113: "卡-宜睿好禮即享券35元",
      price_113: 22,
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_110: "卡-全家中美式",
      year_109: "卡-全家中美式",
      year_108: "卡-全家35元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 78.4,
      code: 3580,
      name: "友威科",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "玻璃壺",
      price_111: 25,
      year_110: "卡-711卡35元",
      year_109: "卡-711卡35元",
      year_108: "微笑調味罐",
      year_107: "防曬袖套",
    },
    {
      suggestion: "必",
      share_price: 64.6,
      code: 3588,
      name: "通嘉",
      year_113: "卡-711中美式/現萃茶卡",
      price_113: 13,
      year_112: "卡-711中美",
      price_112: 18,
      year_111: "卡-711中美",
      price_111: 18,
      year_110: "卡-711中美",
      year_109: "卡-全家中美式",
      year_108: "卡-全家中美式",
      year_107: "卡-全家30元",
    },
    {
      suggestion: "必",
      share_price: 24,
      code: 3591,
      name: "艾笛森",
      year_113: "",
      price_113: "",
      year_112: "卡-711卡20元",
      price_112: 11,
      year_111: "卡-711中美",
      price_111: 18,
      year_108: "卡-711中美",
    },
    {
      suggestion: "必",
      share_price: 392.5,
      code: 3592,
      name: "瑞鼎",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-全家50元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 7.83,
      code: 3593,
      name: "力銘",
      year_113: "",
      price_113: "",
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      share_price: 56,
      code: 3594,
      name: "磐儀",
      year_113: "",
      price_113: "",
      year_110: "伊莎貝爾香皂三入",
    },
    {
      suggestion: "必",
      share_price: 58.8,
      code: 3605,
      name: "宏致",
      year_113: "皂-蜂王皂 彩盒二入",
      price_113: 6,
      year_112: "蜂膠草本抗菌皂二入盒",
      price_112: 5,
      year_111: "蜂王皂 二入",
      price_111: 5,
      year_110: "秈十糙米300克",
      year_109: "池上有機米 300g",
      year_108: "三合一高速傳輸線",
      year_107: "晶品米 350 g",
    },
    {
      suggestion: "必",
      share_price: 18.25,
      code: 3607,
      name: "谷崧",
      year_113: "卡-全家中美式(2027/06)",
      price_113: 16,
      year_112: "卡-全家中美式",
      price_112: 12,
      year_111: "卡-全家中美式",
      price_111: 18,
      year_110: "統一超商咖啡卡(中杯美式)",
      year_109: "玉米田個人筷套組",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 27.35,
      code: 3615,
      name: "安可",
      year_113: "萬用去污膏",
      price_113: 5,
      year_112: "香氛噴霧 中",
      price_112: 5,
      year_111: "防蚊液30ml 小",
      price_111: 5,
      year_110: "隨身酒精清潔液",
      year_109: "巧衣架",
      year_108: "收納鞋架",
      year_107: "四爪滾輪按摩器",
    },
    {
      share_price: 57,
      code: 3622,
      name: "洋華",
      year_113: "",
      price_113: "",
      year_107: "卡-全家35元",
    },
    {
      share_price: 40.35,
      code: 3624,
      name: "光頡",
      year_113: "皂-蜂王洋甘菊潤膚皂二入 70g",
    },
    {
      suggestion: "必",
      share_price: 18.45,
      code: 3625,
      name: "西勝",
      year_113: "廚房不鏽鋼料理剪刀(附刀套)",
      price_113: 10,
      year_112: "修甲美容-12件套組",
      price_112: 12,
      year_111: "三用途照明檯燈",
      price_111: 10,
      year_110: "小麥環保餐具組",
      year_109: "小麥環保餐具組",
      year_108: "香氛皂2入盒",
      year_107: "卡-全家50元",
    },
    {
      share_price: "下市",
      code: 3627,
      name: "華信",
      year_113: "卡-全家35元",
      price_113: 26,
      year_112: "卡-全家35元",
      price_112: 25,
    },
    {
      suggestion: "必",
      share_price: 13.2,
      code: 3632,
      name: "研勤",
      year_113: "米-包裝米",
      price_113: 10,
      year_112: "米-300 g",
      price_112: 5,
      year_111: "穗美人 米300 g",
      price_111: 8,
      year_110: "穗美人 米300 g",
      year_109: "穗美人 米300 g",
      year_108: "穗美人 米300 g",
      year_107: "穗美人 米300 g",
    },
    {
      suggestion: "必",
      share_price: 79.3,
      code: 3645,
      name: "達邁",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "卡-全家50元",
      price_112: 39,
      year_111: "卡-全家50元",
      price_111: 39,
      year_110: "卡-全家50元",
      year_109: "卡-全家50元",
      year_108: "卡-全家50元",
      year_107: "樂美雅白玉強化餐盤二入",
    },
    {
      suggestion: "必",
      share_price: 29,
      code: 3646,
      name: "艾恩特",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      share_price: 3605,
      code: 3661,
      name: "世芯",
      year_113: "",
      price_113: "",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      share_price: 63,
      code: 3663,
      name: "鑫科",
      year_113: "",
      price_113: "",
      year_112: "卡-711卡100元",
      price_112: 81,
    },
    {
      suggestion: "可",
      share_price: 33.9,
      code: 3666,
      name: "光耀",
      year_113: "卡-711卡50元",
      price_113: 38,
    },
    {
      suggestion: "可",
      share_price: 19,
      code: 3672,
      name: "康聯",
      year_113: "卡-711卡35元",
      price_113: 25,
    },
    {
      suggestion: "必",
      share_price: 39.75,
      code: 3673,
      name: "TPK",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "卡-全家50元",
      price_112: 39,
      year_111: "卡-全家50元",
      price_111: 39,
      year_108: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 17.45,
      code: 3686,
      name: "達能",
      year_113: "卡-全家35元",
      price_113: 26,
      year_112: "卡-全家35元",
      price_112: 25,
      year_111: "卡-全家35元",
      price_111: 25,
      year_110: "卡-全家35元",
      year_109: "卡-全家35元",
      year_108: "卡-全家35元",
      year_107: "卡-全家35元",
    },
    {
      suggestion: "必",
      share_price: 94,
      code: 3687,
      name: "歐買尬",
      year_113: "卡-711中美式/現萃茶卡",
      price_113: 17,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "OhMyGod遊戲點數卡",
      year_108: "OhMyGod遊戲點數卡",
      year_107: "OhMyGod遊戲點數卡",
    },
    {
      share_price: 95.5,
      code: 3689,
      name: "湧德",
      year_113: "三⽤觸控螢光原⼦筆",
      price_113: 2,
      year_112: "螢光原子筆",
      price_112: 5,
      year_111: "三用觸控螢光原子筆",
      price_111: 5,
      year_110: "三用觸控螢光原子筆",
      year_109: "35元超商商品卡",
      year_108: "卡-全家35元",
    },
    {
      suggestion: "必",
      share_price: 70.1,
      code: 3702,
      name: "大聯大",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "卡-全家50元",
      price_112: 39,
      year_110: "卡-全家35元",
      year_109: "卡-全家50元",
    },
    {
      share_price: 40.3,
      code: 3704,
      name: "合勤控",
      year_113: "",
      price_113: "",
      year_111: "卡-711卡35元",
      price_111: 23,
    },
    {
      share_price: 55.3,
      code: 3705,
      name: "永信",
      year_113: "",
      price_113: "",
      year_108: "HAC 草本舒緩凝膠 70ml",
    },
    {
      suggestion: "必",
      share_price: 75.4,
      code: 3706,
      name: "神達",
      year_113: "Cepis檸檬黑胡椒香米薄餅",
      price_113: 12,
      year_112: "米薄餅",
      price_112: 10,
      year_111: "醫用口罩10 入",
      price_111: 5,
      year_110: "Cepis有機甘栗",
      year_109: "顏帝亞洄瀾香柚嬰兒皂二入",
      year_108: "Cepis有機甘栗",
      year_107: "天然草本蜂蜜洗髮精",
    },
    {
      suggestion: "必",
      share_price: 45,
      code: 3707,
      name: "漢磊",
      year_113: "gasserre賦活保濕面膜一片",
      price_113: 5,
      year_112: "洗手乳隨身瓶 30 ml",
      price_112: 5,
      year_111: "毛寶繽紛花園抗菌洗手乳",
      price_111: 5,
      year_110: "面膜",
      year_109: "面膜",
      year_108: "面膜",
      year_107: "佳瑟莉 保濕活膚面膜",
    },
    {
      suggestion: "必",
      share_price: 83.6,
      code: 3708,
      name: "上緯",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_110: "卡-711卡35元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 11.95,
      code: 3710,
      name: "連展",
      year_113: "瓜地馬拉濾掛咖啡",
      price_113: 5,
      year_112: "口罩",
      price_112: 5,
      year_111: "咖啡濾掛包 三包",
      price_111: 10,
      year_110: "咖啡濾掛包三包",
      year_109: "咖啡濾掛包",
      year_108: "廚房好幫手純天然苦茶粕",
      year_107: "環保不銹鋼吸管組",
    },
    {
      suggestion: "必",
      share_price: 164.5,
      code: 3711,
      name: "日月光",
      year_113: "安妮兔玻璃胖胖瓶 300ml",
      price_113: 10,
      year_112: "卡-711卡100元",
      price_112: 81,
      year_111: "幸運草碗組",
      price_111: 25,
      year_110: "安妮兔鑄鐵造型保鮮盒",
      year_109: "保鮮盒袋",
      year_108: "樂活杯",
    },
    {
      suggestion: "必",
      share_price: 44.15,
      code: 3714,
      name: "富采",
      year_113: "黃阿瑪的後宮生活聯名帆布袋",
      price_113: 12,
      year_112: "高硼硅玻璃瓶",
      price_112: 35,
      year_111: "保溫袋",
      price_111: 12,
      year_110: "口袋型迷你保溫瓶",
    },
    {
      suggestion: "必",
      share_price: 60,
      code: 3715,
      name: "定穎",
      year_113: "皂-手工香皂-星兒皂",
      price_113: 5,
      year_112: "手工香皂",
      price_112: 5,
    },
    {
      suggestion: "必",
      share_price: 38.15,
      code: 3716,
      name: "中化控",
      year_113: "綠的抗菌組合",
      price_113: 30,
      year_112: "綠的抗菌組合",
      price_112: 25,
      year_111: "綠的抗菌組合",
      price_111: 22,
      year_110: "時尚貝利鍋",
      year_109: "大豆濃縮洗潔精",
      year_108: "綠的抗菌沐浴露",
      year_107: "綠的抗菌潔手慕斯",
    },
    {
      suggestion: "必",
      share_price: 30.65,
      code: 4102,
      name: "永日",
      year_113: "購物袋",
      price_113: 5,
      year_112: "酒精濕紙巾二包",
      price_112: 5,
      year_111: "醫用口罩5入",
      price_111: 5,
      year_110: "HAC常樂舒衛粉",
      year_109: "HAC常寶益生菌粉",
      year_108: "HAC 草本舒緩凝膠",
      year_107: "永信HAC穩固鈣粉",
    },
    {
      suggestion: "必",
      share_price: 86.1,
      code: 4104,
      name: "佳醫",
      year_113: "CureCare保濕精華面膜",
      price_113: 5,
      year_112: "面膜一片",
      price_112: 12,
      year_111: "面膜 一片",
      price_111: 5,
      year_108: "面膜",
      year_107: "面膜",
    },
    {
      suggestion: "必",
      share_price: 77,
      code: 4105,
      name: "東洋",
      year_113: "曼秀雷敦軟膏 35 g",
      price_113: 30,
      year_112: "玫瑰岩鹽",
      price_112: 12,
      year_111: "社福團體玫瑰岩鹽",
      price_111: 12,
      year_110: "曼秀雷敦軟膏75g",
      year_109: "愛盲惜福皂袋組",
      year_108: "社福團體玫瑰岩鹽",
      year_107: "社福團體手工皂",
    },
    {
      share_price: 124,
      code: 4107,
      name: "邦特",
      year_113: "",
      price_113: "",
      year_108: "牙籤刷",
      year_107: "環保袋",
    },
    {
      suggestion: "必",
      share_price: 16.45,
      code: 4108,
      name: "懷特",
      year_113: "美吾髮-髮膜隨機出（馬鞭草、藍風鈴）",
      price_113: 20,
      year_112: "洗髮沐浴精",
      price_112: 25,
      year_111: "美吾髮 南非重生洗髮精",
      price_111: 30,
      year_110: "美吾髮 南非重生洗髮精",
      year_109: "黑娜護髮染髮霜",
      year_108: "清潔品",
      year_107: "蓓歐芙黃金麥洗髮精",
    },
    {
      suggestion: "必",
      share_price: 23.15,
      code: 4109,
      name: "穆拉德",
      year_113: "088粉紅拿鐵+367排便果凍各1",
      price_113: 10,
      year_112: "犇牛樟芝菌絲體 替",
      price_112: 15,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_110: "卡-711卡35元",
      year_109: "卡-711卡35元",
      year_108: "卡-全家50元",
      year_107: "牙刷",
    },
    {
      suggestion: "必",
      share_price: 29.2,
      code: 4111,
      name: "濟生",
      year_113: "Q10緊緻嫩白面膜二入",
      price_113: 8,
      year_112: "面膜/替",
      price_112: 10,
      year_111: "透亮白皙精華液",
      price_111: 15,
      year_110: "咖啡萃控油洗髮精",
      year_109: "Q10面膜三入",
      year_108: "白茶保濕噴霧",
      year_107: "SOVENUS 潔淨洗面露",
    },
    {
      share_price: 26.1,
      code: 4113,
      name: "聯上",
      year_113: "",
      price_113: "",
      year_110: "乾洗手",
      year_109: "臺鹽牙膏旅行組",
      year_108: "蜂王香皂禮盒二入",
      year_107: "飲料杯套",
    },
    {
      suggestion: "必",
      share_price: 33.95,
      code: 4114,
      name: "健喬",
      year_113: "SL咀嚼錠/身可補發泡錠",
      price_113: 15,
      year_112: "卡-全家50元",
      price_112: 39,
      year_111: "卡-全家50元",
      price_111: 39,
      year_108: "身可補酵素B群發泡錠",
      year_107: "水SPA天絲柔面膜",
    },
    {
      share_price: 86,
      code: 4119,
      name: "旭富",
      year_113: "",
      price_113: "",
      year_111: "卡-全家50元",
      price_111: 37,
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 18.45,
      code: 4121,
      name: "優盛",
      year_113: "卡-佑全藥局50元禮物卡",
      price_113: 35,
      year_112: "卡-佑全100元禮物卡",
      price_112: 60,
      year_111: "卡-佑全100元禮物卡",
      price_111: 70,
      year_110: "卡-全家50元",
      year_109: "卡-全家50元",
      year_108: "卡-全家50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 93.2,
      code: 4126,
      name: "太醫",
      year_113: "",
      price_113: "",
      year_112: "卡-全大美式",
      price_112: 15,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "筆記便條本",
      year_109: "擦拭布",
      year_108: "擦拭布",
      year_107: "手機吸盤固定座",
    },
    {
      suggestion: "必",
      share_price: 39.6,
      code: 4127,
      name: "天良",
      year_113: "高基能 液態維生素D3",
      price_113: 5,
      year_112: "高基能維生素D",
      price_112: 30,
      year_111: "好脂速 液體軟膠囊 60粒",
      price_111: 15,
      year_110: "潤髮乳",
      year_109: "豬哥亮dvd 或皂",
      year_108: "多種",
      year_107: "TCK 360ml 茶壺",
    },
    {
      suggestion: "必",
      share_price: 32.2,
      code: 4128,
      name: "中天",
      year_113: "棉花田-桔梗精華飲6入",
      price_113: 40,
      year_112: "卡-FMC",
      price_112: 50,
      year_111: "養氣人蔘精華飲包6入",
      price_111: 70,
      year_110: "養氣人蔘精華飲包6入",
      year_109: "李時珍頂級四物鐵/6入",
      year_108: "李時珍頂級四物鐵/6入",
      year_107: "李時珍頂級四物鐵/6入",
    },
    {
      suggestion: "必",
      share_price: 95.7,
      code: 4129,
      name: "聯合骨",
      year_113: "頸椎按摩儀",
      price_113: 10,
      year_112: "手機支架",
      price_112: 5,
      year_111: "小麥環保杯",
      price_111: 5,
      year_110: "防護口罩",
      year_109: "蜂王草本抗菌皂二入",
      year_108: "蜂王植萃皂2入",
      year_107: "新骨瓷杯匙組",
    },
    {
      suggestion: "必",
      share_price: 23.75,
      code: 4130,
      name: "健亞",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-711卡35元",
      price_111: 23,
    },
    {
      suggestion: "必",
      share_price: 16.6,
      code: 4132,
      name: "國鼎",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "國鼎紅敏風錠",
      price_112: 1000,
    },
    {
      suggestion: "必",
      share_price: 30.65,
      code: 4133,
      name: "亞諾法",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-711卡35元",
      price_112: 20,
      year_111: "卡-711中美式",
      price_111: 14,
    },
    {
      suggestion: "可",
      share_price: 91.5,
      code: 4138,
      name: "曜亞",
      year_113: "CureCare保濕精華面膜",
      price_113: 5,
      year_108: "面膜",
      year_107: "面膜",
    },
    {
      suggestion: "必",
      share_price: 25.05,
      code: 4142,
      name: "國光生",
      year_113: "LED手電筒",
      price_113: 5,
      year_112: "旋轉式噴霧分裝瓶",
      price_112: 5,
      year_111: "雙角度調節伸縮手機架",
      price_111: 10,
      year_110: "炫彩口袋杯",
      year_109: "植粹環保吸管餐具組",
      year_108: "保溫保冷袋",
      year_107: "行李束口收納袋",
    },
    {
      suggestion: "必",
      share_price: 90.1,
      code: 4147,
      name: "中裕",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 30,
      year_111: "卡-711卡50元",
      price_111: 30,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 11.9,
      code: 4150,
      name: "優你康",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "卡-全家50元",
      price_112: 39,
    },
    {
      suggestion: "必",
      share_price: 20.75,
      code: 4155,
      name: "訊映",
      year_113: "訊映旗下產品折價券2000元",
      price_113: 2,
      year_112: "沙威隆洗手乳",
      price_112: 10,
    },
    {
      suggestion: "必",
      share_price: 69.5,
      code: 4160,
      name: "創源",
      year_113: "玫瑰肌因水潤光面膜",
      price_113: 5,
      year_112: "玫瑰肌因水潤光面膜",
      price_112: 5,
      year_111: "玫瑰肌因水潤光面膜",
      price_111: 5,
      year_110: "玫瑰肌因水潤光面膜",
      year_109: "玫瑰肌因水潤光面膜",
      year_108: "RE.O玫瑰肌因保濕潤唇膏",
      year_107: "RE.O玫瑰肌因保濕潤唇膏",
    },
    {
      suggestion: "必",
      share_price: 120.5,
      code: 4162,
      name: "智擎",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 125,
      year_110: "卡-711卡100元",
      year_109: "蜂王抗菌洗手乳+抗菌皂2入",
      year_108: "曼秀雷敦軟膏75g",
      year_107: "卡-711卡100元",
    },
    {
      suggestion: "必",
      share_price: 111.5,
      code: 4163,
      name: "鐿鈦",
      year_113: "卡-全家35元",
      price_113: 26,
      year_112: "卡-全家50元",
      price_112: 39,
      year_111: "卡-全家50元",
      price_111: 39,
    },
    {
      suggestion: "必",
      share_price: 21.45,
      code: 4167,
      name: "松瑞藥",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_110: "卡-711卡35元",
      year_109: "不鏽鋼保鮮盒",
      year_108: "環保購物袋",
      year_107: "環保購物袋",
    },
    {
      suggestion: "必",
      share_price: 24.75,
      code: 4168,
      name: "醣聯",
      year_113: "安妮兔方型一碗一匙禮盒",
      price_113: 12,
      year_112: "蜂王草本植舒亮澤皂二入",
      price_112: 5,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_110: "卡-711卡35元",
      year_109: "卡-711卡35元",
      year_108: "卡-711卡35元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 28,
      code: 4171,
      name: "瑞基",
      year_113: "卡-宜睿好禮即享券35元",
      price_113: 22,
      year_112: "卡-全家35元",
      price_112: 25,
    },
    {
      suggestion: "必",
      share_price: 16.35,
      code: 4172,
      name: "因華",
      year_113: "皂-蜂王洋甘菊潤膚皂二入 70g",
      price_113: 5,
      year_112: "卡-全家50元",
      price_112: 39,
    },
    {
      suggestion: "必",
      share_price: 21.7,
      code: 4173,
      name: "久裕",
      year_113: "CureCare保濕精華面膜",
      price_113: 5,
      year_112: "面膜一片",
      price_112: 12,
      year_111: "面膜 一片",
      price_111: 5,
      year_108: "面膜",
      year_107: "面膜",
    },
    {
      suggestion: "必",
      share_price: 61.7,
      code: 4174,
      name: "浩鼎",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-全家50元",
      price_112: 35,
      year_111: "卡-711卡50元",
      price_111: 34,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 19,
      code: 4195,
      name: "基米",
      year_113: "極限野營登山扣環一個",
      price_113: 2,
      year_112: "口罩一盒",
      price_112: 5,
    },
    {
      suggestion: "可",
      share_price: 49.6,
      code: 4303,
      name: "信立",
      year_113: "葉黃素魚油軟膠囊一包10粒",
      price_113: 5,
    },
    {
      suggestion: "必",
      share_price: 17.8,
      code: 4306,
      name: "炎洲",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "手機架",
      price_112: 5,
      year_111: "卡-全家50元",
      price_111: 39,
      year_110: "膠帶組",
      year_109: "複方滾珠精油",
      year_108: "卡-全家50元",
    },
    {
      share_price: 20.15,
      code: 4401,
      name: "東隆興",
      year_113: "",
      price_113: "",
      year_108: "T恤",
    },
    {
      suggestion: "必",
      share_price: 24,
      code: 4402,
      name: "福大",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "口罩保潔墊",
      price_112: 5,
      year_111: "口罩保潔墊",
      price_111: 5,
      year_110: "口罩保潔墊",
      year_109: "壽司米",
      year_108: "壽司米",
      year_107: "購物袋",
    },
    {
      share_price: 3.28,
      code: 4414,
      name: "如興",
      year_113: "",
      price_113: "",
      year_111: "卡-711卡35元",
      price_111: 23,
    },
    {
      suggestion: "必",
      share_price: 43.5,
      code: 4510,
      name: "高鋒",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_108: "卡-711卡50元",
    },
    {
      suggestion: "可",
      share_price: 30.6,
      code: 4513,
      name: "福裕",
      year_113: "",
      price_113: "",
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-711卡35元",
      price_111: 23,
    },
    {
      suggestion: "可",
      share_price: 33.35,
      code: 4523,
      name: "永彰",
      year_113: "",
      price_113: "",
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
    },
    {
      suggestion: "可",
      share_price: 31.15,
      code: 4526,
      name: "東台",
      year_113: "",
      price_113: "",
      year_112: "卡-711卡35元",
      price_112: 23,
      year_109: "卡-711卡35元",
      year_108: "卡-711卡50元",
    },
    {
      share_price: 6,
      code: 4529,
      name: "淳紳",
      year_113: "",
      price_113: "",
      year_110: "防護口罩 10 入",
    },
    {
      suggestion: "必",
      share_price: 12.85,
      code: 4530,
      name: "宏易",
      year_113: "LED觸控發光筆",
      price_113: 2,
      year_112: "手電筒",
      price_112: 5,
      year_111: "隱形擴充腰包",
      price_111: 5,
      year_110: "中興富貴平安米 300g",
      year_109: "中興富貴平安米",
      year_108: "中興富貴平安米300g",
    },
    {
      suggestion: "必",
      share_price: 34.1,
      code: 4533,
      name: "協易機",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_108: "卡-711卡35元",
      year_107: "卡-711卡35元",
    },
    {
      suggestion: "必",
      share_price: 35.35,
      code: 4541,
      name: "晟田",
      year_113: "卡-711卡35元",
      price_113: 23,
      year_112: "卡-711卡35元",
      price_112: 20,
      year_111: "卡-711卡50元",
      price_111: 35,
    },
    {
      suggestion: "可",
      share_price: 39,
      code: 4543,
      name: "萬在",
      year_113: "保溫保冷袋(熊)",
      price_113: 10,
    },
    {
      suggestion: "可",
      share_price: 45.6,
      code: 4545,
      name: "銘鈺",
      year_113: "",
      price_113: "",
      year_112: "卡-全家200元",
      price_112: 177,
    },
    {
      suggestion: "可",
      share_price: 23,
      code: 4552,
      name: "力達",
      year_113: "卡-全家50元",
      price_113: 39,
    },
    {
      suggestion: "必",
      share_price: 129,
      code: 4557,
      name: "永新",
      year_113: "米-養氣米 600 g",
      price_113: 10,
      year_112: "米-黑米",
      price_112: 20,
      year_111: "養氣米 黑米 600 g",
      price_111: 20,
      year_110: "養氣米 黑米600克",
      year_109: "養氣米 黑米600克",
      year_108: "養氣米 黑米 600 g",
      year_107: "養氣米 黑米 600 g",
    },
    {
      suggestion: "必",
      share_price: 23.2,
      code: 4564,
      name: "元翎",
      year_113: "毛寶洗碗精",
      price_113: 5,
      year_112: "毛寶洗碗精450g",
      year_111: "毛寶洗碗精",
    },
    {
      share_price: 169,
      code: 4576,
      name: "大銀微系統",
      year_113: "",
      price_113: "",
      year_108: "卡-全家100元",
    },
    {
      suggestion: "必",
      share_price: 11,
      code: 4712,
      name: "南璋",
      year_113: "皂-蜂王洋甘菊潤膚皂二入 60g",
      price_113: 6,
      year_112: "蜂王草本植舒亮澤皂二入",
      price_112: 5,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_107: "普力-600 快速錠",
    },
    {
      suggestion: "必",
      share_price: 16.2,
      code: 4714,
      name: "永捷",
      year_113: "葉黃素魚油軟膠囊一包10粒",
      price_113: 5,
      year_112: "洋甘菊皂 三入盒",
      price_112: 8,
      year_111: "毛寶繽紛花園抗菌洗手乳",
      price_111: 12,
      year_110: "折疊小板凳",
      year_109: "沙威隆抗菌洗手露",
      year_108: "保溫保冷袋",
      year_107: "蜂王乳油木果精華皂三入組",
    },
    {
      suggestion: "必",
      share_price: 21,
      code: 4716,
      name: "大立高",
      year_113: "皂-櫻花浪漫情懷皂 70 g",
      price_113: 3,
      year_112: "防疫筆",
      price_112: 5,
      year_111: "多功能防疫筆",
      price_111: 5,
      year_110: "護目鏡(含防塵袋)",
      year_109: "八合一工具組",
      year_108: "304不銹鋼吸管組",
      year_107: "毛寶好無比超淨能洗衣精",
    },
    {
      suggestion: "必",
      share_price: 63.5,
      code: 4721,
      name: "美琪瑪",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-全家50元",
      year_108: "卡-全家50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 274,
      code: 4728,
      name: "雙美",
      year_113: "卡-全家35元",
      price_113: 26,
      year_112: "卡-全家35元",
      price_112: 25,
      year_111: "卡-全家35元",
      price_111: 23,
      year_108: "充電式露營照明涼風扇",
      year_107: "面膜禮盒三入",
    },
    {
      code: 4730,
      name: "通用",
      year_113: "",
      price_113: "",
      year_112: "卡-711卡200元",
      price_112: 169,
    },
    {
      suggestion: "必",
      share_price: 38.25,
      code: 4735,
      name: "豪展",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 33,
      year_111: "卡-711卡50元",
      price_111: 33,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡100元",
    },
    {
      share_price: 66,
      code: 4737,
      name: "華廣",
      year_113: "",
      price_113: "",
      year_111: "卡-711卡100元",
      price_111: 82,
    },
    {
      suggestion: "必",
      share_price: 53.7,
      code: 4739,
      name: "康普",
      year_113: "卡-全家35元",
      price_113: 26,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_109: "卡-全家35元",
    },
    {
      suggestion: "必",
      share_price: 78.5,
      code: 4743,
      name: "合一",
      year_113: "棉花田桔梗精華飲6入",
      price_113: 40,
      year_112: "卡-FMC",
      price_112: 50,
      year_108: "頂級四物鐵",
      year_107: "頂級四物鐵六入",
    },
    {
      suggestion: "必",
      share_price: 22.05,
      code: 4744,
      name: "皇將",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "卡-全家50元",
      price_112: 39,
      year_111: "卡-全家50元",
      price_111: 35,
    },
    {
      suggestion: "必",
      share_price: 74.1,
      code: 4746,
      name: "台耀",
      year_113: "卡-711中美式/現萃茶卡",
      price_113: 25,
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_110: "卡-711卡35元",
    },
    {
      suggestion: "必",
      share_price: 650,
      code: 4749,
      name: "新應材",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
    },
    {
      share_price: 41.05,
      code: 4754,
      name: "國碳",
      year_113: "酒精噴霧筆",
      price_113: 3,
    },
    {
      suggestion: "必",
      share_price: 0,
      code: 4804,
      name: "大略",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "卡-全家50元",
      price_112: 39,
      year_111: "卡-全家50元",
      price_111: 39,
    },
    {
      suggestion: "必",
      share_price: 10,
      code: 4806,
      name: "昇華",
      year_113: "加捷清廢茶茶包(5g)x2包/盒",
      price_113: 5,
      year_112: "卡-全家50元",
      price_112: 39,
    },
    {
      share_price: 17.7,
      code: 4807,
      name: "日成",
      year_113: "",
      price_113: "",
      year_110: "精美飾品",
      year_109: "沒用的7000元購物金",
      year_107: "卡-711卡100元",
    },
    {
      suggestion: "必",
      share_price: 38.5,
      code: 4903,
      name: "聯光通",
      year_113: "甲魚全沛全方位維他命",
      price_113: 10,
      year_112: "甲魚全沛全方位維他命",
      price_112: 20,
      year_111: "卡-全家35元",
      price_111: 25,
      year_110: "卡-全家50元",
      year_109: "卡-全家50元",
      year_108: "卡-全家50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 33.9,
      code: 4906,
      name: "正文",
      year_113: "條紋收納包",
      price_113: 5,
      year_112: "吸盤手機支架",
      price_112: 5,
      year_111: "360度吸盤手機支架",
      price_111: 5,
      year_110: "360度吸盤手機支架",
      year_109: "額溫卡",
      year_108: "LED手電筒",
      year_107: "不鏽鋼吸管(粗,細,刷)3入",
    },
    {
      suggestion: "必",
      share_price: 99.1,
      code: 4908,
      name: "前鼎",
      year_113: "妙管家口袋杯",
      price_113: 8,
      year_112: "鋁合金伸縮手機架",
      price_112: 5,
      year_111: "沙威隆抗菌皂二入",
      price_111: 7,
      year_110: "沙威隆抗菌皂二入",
      year_109: "沙威隆抗菌皂",
      year_108: "閃字風扇",
      year_107: "兩段式掀蓋保鮮盒",
    },
    {
      suggestion: "必",
      share_price: 103.5,
      code: 4909,
      name: "新復興",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_110: "卡-711卡35元",
      year_109: "卡-711卡35元",
      year_108: "卡-711卡35元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "可",
      share_price: 134,
      code: 4912,
      name: "聯德",
      year_113: "卡-711卡50元",
      price_113: 38,
    },
    {
      suggestion: "必",
      share_price: 38.3,
      code: 4916,
      name: "事欣科",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "阿隆索特級初榨橄欖油",
    },
    {
      suggestion: "必",
      share_price: 34,
      code: 4923,
      name: "力士",
      year_113: "卡-711卡100元",
      price_113: 83,
      year_112: "卡-711卡100元",
      price_112: 81,
    },
    {
      suggestion: "必",
      share_price: 38.15,
      code: 4927,
      name: "泰鼎",
      year_113: "三段磨刀器",
      price_113: 10,
      year_112: "修甲美容4件組",
      price_112: 8,
      year_111: "依必朗 抗菌洗手乳",
      price_111: 12,
      year_110: "沙威隆抗菌洗手乳",
      year_109: "無患子香皂二入禮盒",
      year_108: "香皂禮盒二入",
      year_107: "環保餐具組",
    },
    {
      suggestion: "必",
      share_price: 30.25,
      code: 4930,
      name: "燦星網",
      year_113: "",
      price_113: "",
      year_112: "卡-燦坤券100",
      price_112: 65,
      year_111: "卡-燦坤券100元",
      price_111: 70,
    },
    {
      suggestion: "必",
      share_price: 69.4,
      code: 4931,
      name: "新盛力",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡100元",
      price_112: 81,
      year_111: "卡-711卡200元",
      price_111: 37,
    },
    {
      suggestion: "必",
      share_price: 12.65,
      code: 4934,
      name: "太極",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_110: "卡-711卡35元",
      year_109: "卡-全家35元",
      year_108: "卡-全家35元",
      year_107: "卡-全家35元",
    },
    {
      suggestion: "必",
      share_price: 20.45,
      code: 4939,
      name: "亞電",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711中拿",
      price_112: 18,
      year_111: "卡-711中拿",
      price_111: 23,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 18,
      code: 4943,
      name: "康控",
      year_113: "",
      price_113: "",
      year_112: "沙威隆抗菌組合",
      price_112: 10,
      year_111: "伊莎貝爾洗淨禮盒",
      price_111: 25,
    },
    {
      share_price: 81,
      code: 4945,
      name: "陞達",
      year_113: "",
      price_113: "",
      year_111: "卡-711卡100元",
      price_111: 82,
    },
    {
      suggestion: "必",
      share_price: 50,
      code: 4946,
      name: "辣椒",
      year_113: "",
      price_113: "",
      year_112: "水瓶",
      price_112: 10,
      year_111: "小麥手機架玻璃瓶",
      price_111: 5,
      year_110: "環保餐具組",
      year_109: "購物袋",
      year_108: "購物袋",
      year_107: "水壺",
    },
    {
      suggestion: "必",
      share_price: 12.75,
      code: 4950,
      name: "牧東",
      year_113: "料理用刀具",
      price_113: 6,
      year_112: "卡-711卡50元",
      price_112: 37,
    },
    {
      suggestion: "必",
      share_price: 118,
      code: 4953,
      name: "緯軟",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "卡-全家50元",
      price_112: 37,
      year_111: "卡-全家50元",
      price_111: 39,
      year_110: "卡-全家50元",
      year_109: "卡-全家50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 31.75,
      code: 4956,
      name: "光鋐",
      year_113: "MINI健康捲尺",
      price_113: 3,
      year_112: "伊莎貝爾櫻花皂三入",
      price_112: 8,
      year_111: "伊莎貝爾香皂三入",
      price_111: 10,
      year_110: "伊莎貝爾 手工皂二入",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 13.9,
      code: 4960,
      name: "誠美材",
      year_113: "玻璃微波保鮮盒",
      price_113: 20,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡100元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "毛寶植萃乾洗手(80g)",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 231,
      code: 4961,
      name: "天鈺",
      year_113: "卡-宜睿即享券50元",
      price_113: 35,
      year_112: "卡-全家50元",
      price_112: 39,
      year_111: "卡-全家50元",
      price_111: 39,
    },
    {
      suggestion: "必",
      share_price: 78.5,
      code: 4967,
      name: "十銓",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "卡-全家50元",
      price_112: 39,
      year_111: "卡-全家100元",
      price_111: 85,
      year_110: "卡-全家50元",
      year_109: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 204.5,
      code: 4968,
      name: "立積",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "卡-全家50元",
      price_112: 39,
      year_111: "卡-全家50元",
      price_111: 39,
      year_110: "卡-全家50元",
      year_109: "卡-全家50元",
      year_108: "卡-全家50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 113.5,
      code: 4971,
      name: "英特磊",
      year_113: "卡-711中美式/現萃茶卡",
      price_113: 17,
      year_112: "卡-711中美式",
      price_112: 15,
      year_111: "卡-711中美式",
      price_111: 14,
      year_108: "卡-711卡50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 33,
      code: 4973,
      name: "廣穎",
      year_113: "實用保鮮密封棒",
      price_113: 5,
      year_112: "伊莎貝爾潔手慕絲",
      price_112: 12,
      year_111: "毛寶抗菌洗衣精",
      price_111: 6,
      year_110: "伊莎貝爾無患子抗菌洗手乳",
      year_109: "蜂王草本抗菌皂二入",
      year_108: "卡-全家中美式",
      year_107: "卡-全家霜淇淋",
    },
    {
      suggestion: "必",
      share_price: 124,
      code: 4974,
      name: "亞泰",
      year_113: "小雛菊袖套",
      price_113: 5,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "多功能微波保鮮盒",
      price_111: 5,
      year_110: "修容7件組",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "香桔淨洗衣酵素粉1000g",
    },
    {
      suggestion: "必",
      share_price: 35.65,
      code: 4976,
      name: "佳凌",
      year_113: "卡-711中拿鐵/美式咖啡卡",
      price_113: 20,
      year_112: "卡-711中拿",
      price_112: 18,
      year_111: "卡-711卡50元",
      price_111: 37,
    },
    {
      suggestion: "可",
      share_price: 143.5,
      code: 4977,
      name: "眾達",
      year_113: "卡-宜睿好禮即享券 35 元",
      price_113: 22,
    },
    {
      suggestion: "必",
      share_price: 250.5,
      code: 4979,
      name: "華星光",
      year_113: "卡-711中美式/現萃茶卡",
      price_113: 17,
      year_112: "卡-711中美式",
      price_112: 18,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "可",
      share_price: 145,
      code: 4991,
      name: "環宇",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_109: "伊莎貝爾抗菌洗手乳",
      year_108: "卡-711卡35元",
      year_107: "卡-711卡50元",
    },
    {
      share_price: 72,
      code: 4995,
      name: "晶達",
      year_113: "",
      price_113: "",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 32.15,
      code: 4999,
      name: "鑫禾",
      year_113: "廚房料理剪刀",
      price_113: 8,
      year_112: "開瓶器",
      price_112: 5,
      year_111: "伊莎貝爾香皂三入",
      price_111: 10,
      year_110: "伊莎貝爾無患子洗手乳",
      year_109: "蜂王橄欖保濕草本皂二入",
      year_108: "卡-711卡35元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 49.4,
      code: 5009,
      name: "榮剛",
      year_113: "甲魚全沛全方位維他命",
      price_113: 10,
      year_112: "犇牛樟芝菌絲體 替",
      price_112: 15,
      year_111: "卡-711卡50元",
      price_111: 37,
    },
    {
      suggestion: "必",
      share_price: 20.3,
      code: 5011,
      name: "久陽",
      year_113: "加捷清廢茶茶包(5g)x2包/盒",
      price_113: 5,
      year_112: "犇牛樟芝菌絲體替",
      price_112: 15,
      year_111: "卡-711卡35元",
      price_111: 21,
      year_110: "卡-711卡35元",
    },
    {
      share_price: 30.5,
      code: 5013,
      name: "強新",
      year_113: "",
      price_113: "",
      year_107: "工具組",
    },
    {
      suggestion: "必",
      share_price: 14.55,
      code: 5014,
      name: "建錩",
      year_113: "卡-全家中美式(2027/06)",
      price_113: 12,
      year_112: "卡-全家中美式",
      price_112: 12,
      year_111: "卡-全家中美式",
      price_111: 15,
      year_110: "卡-全家中美式",
      year_109: "卡-全家中美式",
      year_108: "卡-全家50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "可",
      share_price: 36,
      code: 5015,
      name: "華祺",
      year_113: "卡-711卡100元",
      price_113: 83,
    },
    {
      share_price: 36.6,
      code: 5201,
      name: "凱衛",
      year_113: "",
      price_113: "",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 28.7,
      code: 5202,
      name: "力新",
      year_113: "甲魚全沛全方位維他命",
      price_113: 10,
      year_112: "犇牛樟芝菌絲體替",
      price_112: 15,
      year_110: "卡-全家50元",
      year_107: "牙得安 牙膏",
    },
    {
      suggestion: "必",
      share_price: 112.5,
      code: 5203,
      name: "訊連",
      year_113: "卡-711中拿鐵/美式咖啡卡",
      price_113: 20,
      year_112: "卡-711中拿",
      price_112: 20,
      year_109: "卡-711中拿鐵",
    },
    {
      suggestion: "可",
      share_price: 26.55,
      code: 5210,
      name: "寶碩",
      year_113: "",
      price_113: "",
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
    },
    {
      share_price: 62.9,
      code: 5212,
      name: "凌網",
      year_113: "",
      price_113: "",
      year_109: "卡-全家200元",
    },
    {
      suggestion: "必",
      share_price: 126,
      code: 5222,
      name: "全訊",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-全家50元",
      price_112: 39,
    },
    {
      suggestion: "必",
      share_price: 39,
      code: 5227,
      name: "立凱",
      year_113: "卡-711卡100元",
      price_113: 83,
      year_112: "卡-711卡100元",
      price_112: 81,
      year_111: "卡-711卡100元",
      price_111: 82,
      year_110: "康寧 晶彩琥珀8.5吋深盤",
      year_109: "白雪葡萄籽美容皂二入",
      year_108: "玻璃微波保鮮盒",
      year_107: "妙廚師不鏽鋼環保吸管組",
    },
    {
      suggestion: "必",
      share_price: 25.7,
      code: 5230,
      name: "雷笛克",
      year_113: "皂-沙威隆抗菌皂二入 80g",
      price_113: 8,
      year_112: "小麥餐具組",
      price_112: 5,
      year_111: "毛寶洗碗精",
      price_111: 10,
      year_110: "伊莎貝爾香皂三入",
      year_109: "蜂王草本抗菌皂二入",
    },
    {
      suggestion: "可",
      share_price: 59,
      code: 5243,
      name: "乙盛",
      year_113: "硅藻土杯墊",
      price_113: 3,
      year_112: "草本植舒亮澤皂",
    },
    {
      suggestion: "必",
      share_price: 23.3,
      code: 5251,
      name: "天鉞電",
      year_113: "水晶肥皂食器洗滌液體瓶 100 ml",
      price_113: 7,
      year_112: "蜂膠草本抗菌皂一入",
      price_112: 5,
      year_111: "蜂膠草本抗皂",
      price_111: 5,
      year_110: "蜂王皂二入",
      year_109: "不鏽鋼吸管組",
      year_108: "八合一工具筆",
      year_107: "環保袋",
    },
    {
      suggestion: "必",
      share_price: 90.5,
      code: 5258,
      name: "虹堡",
      year_113: "皂-蜂王洋甘菊潤膚皂三入 70 g",
      price_113: 9,
      year_112: "法國Luminarc時光沙拉碗",
    },
    {
      suggestion: "必",
      share_price: 149.5,
      code: 5263,
      name: "智崴",
      year_113: "卡-體驗優惠券",
      price_113: 25,
      year_112: "卡-i-Ride體驗兌換券",
      price_112: 80,
      year_111: "卡-i-Ride體驗兌換券",
      price_111: 60,
      year_110: "i-Ride體驗兌換券",
      year_109: "i-Ride體驗兌換券",
      year_108: "i-Ride體驗兌換券",
      year_107: "高雄i-Ride體驗中心體驗兌換券",
    },
    {
      suggestion: "必",
      share_price: 27.75,
      code: 5272,
      name: "笙科",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "卡-全家50元",
      price_112: 39,
      year_111: "卡-全家50元",
      price_111: 39,
      year_110: "卡-全家50元",
      year_109: "卡-全家50元",
      year_108: "卡-全家50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "可",
      share_price: 241,
      code: 5289,
      name: "宜鼎",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-全家50元",
    },
    {
      share_price: 91,
      code: 5299,
      name: "杰力",
      year_113: "",
      price_113: "",
      year_111: "卡-711卡50元",
      price_111: 34,
      year_110: "卡-711卡50元",
      year_109: "卡-全家50元",
      year_108: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 40.05,
      code: 5309,
      name: "系統",
      year_113: "路跑瓶",
      price_113: 8,
      year_112: "11用瑞士刀",
      price_112: 8,
      year_111: "馬克杯",
      price_111: 5,
      year_110: "不鏽鋼餐具組",
      year_109: "不鏽鋼餐具組",
      year_108: "強光LED手電筒",
      year_107: "葉片修容組",
    },
    {
      share_price: 897,
      code: 5314,
      name: "世紀",
      year_113: "",
      price_113: "",
      year_112: "洋甘菊皂 三入 黃盒",
      price_112: 8,
      year_111: "抗菌洗手乳",
      price_111: 12,
      year_109: "伊莎貝爾抗菌洗手乳",
      year_108: "環保購物袋",
      year_107: "手機指環支架",
    },
    {
      suggestion: "必",
      share_price: 20.7,
      code: 5315,
      name: "光聯",
      year_113: "米-台灣米 600 g",
      price_113: 20,
      year_112: "米-600 ｇ",
      price_112: 12,
      year_111: "台灣米 600 g",
      price_111: 10,
      year_110: "台灣米 600 g",
      year_109: "台灣米 600 g",
      year_108: "臺灣米 600 g",
      year_107: "臺灣米",
    },
    {
      share_price: 11.85,
      code: 5324,
      name: "士開",
      year_113: "",
      price_113: "",
      year_108: "台灣茶摳手工皂禮盒",
      year_107: "卡-711卡35元",
    },
    {
      suggestion: "正文",
      share_price: 16.65,
      code: 5328,
      name: "華容",
      year_113: "USB Type-C to C傳輸充電線",
      price_113: 5,
      year_112: "口罩防護組",
      price_112: 5,
      year_111: "口罩收納組合",
      price_111: 5,
      year_110: "口罩收納組合",
      year_109: "噴霧瓶",
      year_108: "雙層耐熱隔熱杯二入",
      year_107: "時尚保冰溫提袋",
    },
    {
      suggestion: "必",
      share_price: 33.2,
      code: 5351,
      name: "鈺創",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "卡-全家50元",
      price_112: 39,
      year_111: "卡-全家50元",
      price_111: 39,
      year_110: "卡-全家50元禮贈卡",
      year_109: "卡-全家中美式",
      year_108: "卡-711卡50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 10.1,
      code: 5355,
      name: "佳總",
      year_113: "皂-香氛皂",
      price_113: 2,
      year_112: "蜂王香芬皂一入",
      price_112: 5,
      year_111: "蜂王皂一入",
      price_111: 5,
      year_110: "沙威隆皂 一入",
      year_109: "無患子抗菌皂一入",
      year_108: "觸控筆手機架",
      year_107: "USB小米燈",
    },
    {
      suggestion: "必",
      share_price: 30.4,
      code: 5356,
      name: "協益",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-711卡100元",
      price_112: 78,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_110: "卡-711卡35元",
      year_109: "卡-全家88元",
      year_108: "卡-711卡50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "可",
      share_price: 83.2,
      code: 5371,
      name: "中光電",
      year_113: "",
      price_113: "",
      year_112: "米 600g",
      price_112: 12,
      year_111: "心苑米 600 g",
      price_111: 13,
      year_110: "心苑米 600 g",
      year_109: "心苑米 1公斤",
      year_108: "心苑米 1公斤",
      year_107: "心苑米 1公斤",
    },
    {
      suggestion: "必",
      share_price: 78.6,
      code: 5386,
      name: "青雲",
      year_113: "皂- 伊莎貝爾櫻花皂 二入 75g",
      price_113: 6,
      year_112: "伊莎貝爾櫻花皂二入",
      price_112: 5,
      year_111: "伊莎貝爾皂 二入",
      price_111: 7,
    },
    {
      suggestion: "必",
      share_price: 36.8,
      code: 5392,
      name: "能率",
      year_113: "蘭花洗衣膠囊4顆",
      price_113: 5,
      year_112: "蘭花洗衣膠囊",
      price_112: 5,
      year_111: "潔淨抗敏牙膏",
      price_111: 5,
      year_110: "白柚除臭環保洗衣膠囊",
    },
    {
      suggestion: "可",
      share_price: 18.35,
      code: 5398,
      name: "慕康",
      year_113: "",
      price_113: "",
      year_112: "卡-711卡50元",
      price_112: 37,
      year_109: "毛寶好無比洗衣精500g",
      year_108: "毛寶好無比超麗潔洗衣精500 g",
      year_107: "毛寶好無比超麗潔洗衣精",
    },
    {
      suggestion: "必",
      share_price: 31.2,
      code: 5410,
      name: "國眾",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-全家中美式 限",
      price_112: 10,
      year_111: "卡-711中美式",
      price_111: 18,
      year_110: "卡-全家中美式",
      year_109: "卡-全家中美式",
      year_108: "卡-全家中美式",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 53.9,
      code: 5425,
      name: "台半",
      year_113: "卡-宜睿好禮即享券50元",
      price_113: 35,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "可",
      share_price: 29.4,
      code: 5426,
      name: "振發",
      year_113: "",
      price_113: "",
      year_111: "愛佳寶不鏽鋼料理剪刀",
      price_111: 15,
      year_108: "陶瓷刀組(刨刀+水果刀+刀套)",
      year_107: "陶瓷刀組",
    },
    {
      share_price: 288.5,
      code: 5434,
      name: "崇越",
      year_113: "",
      price_113: "",
      year_107: "安永膠原淨白面膜",
    },
    {
      suggestion: "必",
      share_price: 24.5,
      code: 5438,
      name: "東友",
      year_113: "多用途手電筒",
      price_113: 5,
      year_112: "卡-全家中美式",
      price_112: 8,
      year_111: "伊莎貝爾抗菌洗手乳",
      price_111: 8,
      year_110: "摩斯/樂雅樂餐飲兌換券",
      year_109: "卡-711卡50元",
      year_108: "不銹鋼餐具組",
      year_107: "車充USB",
    },
    {
      suggestion: "必",
      share_price: 94.4,
      code: 5443,
      name: "均豪",
      year_113: "USB手電筒",
      price_113: 5,
      year_112: "GPM 涼感巾",
      price_112: 5,
      year_111: "GPM運動束口包",
      price_111: 5,
      year_110: "三用照明檯燈",
      year_109: "台鹽健康含碘海鹽",
      year_108: "頭等倉 白米300克",
      year_107: "環保袋",
    },
    {
      share_price: 17.7,
      code: 5450,
      name: "寶聯",
      year_113: "",
      price_113: "",
      year_107: "卡-711中美式",
    },
    {
      suggestion: "必",
      share_price: 29.8,
      code: 5452,
      name: "佶優",
      year_113: "卡-全家35元",
      price_113: 26,
      year_112: "卡-全家35元",
      price_112: 25,
      year_111: "卡-全家35元",
      price_111: 25,
    },
    {
      suggestion: "必",
      share_price: 15.35,
      code: 5460,
      name: "同協",
      year_113: "皂-蜂王洋甘菊潤膚皂二入 70g",
      price_113: 6,
      year_112: "抹布",
      price_112: 5,
      year_111: "魔乾超細纖維擦拭布",
      price_111: 8,
      year_110: "台灣茶摳手工皂二入",
      year_109: "洗碗海棉二入",
      year_108: "雀之牌 洗碗海棉二入",
      year_107: "台灣茶摳手工皂二入",
    },
    {
      suggestion: "必",
      share_price: 41.85,
      code: 5471,
      name: "松翰",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "卡-全家100元",
      price_112: 85,
      year_111: "卡-全家100元",
      price_111: 85,
      year_110: "卡-全家50元",
      year_109: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 13.5,
      code: 5475,
      name: "德宏",
      year_113: "皂-伊莎貝爾櫻花香皂75g一入",
      price_113: 3,
      year_112: "妙管家洗衣粉 500g",
      price_112: 5,
      year_111: "妙管家洗衣粉 500g",
      year_110: "高級美容香皂",
      year_109: "美容香皂",
      year_108: "手電筒",
      year_107: "環保餐具組",
    },
    {
      suggestion: "必",
      share_price: 123,
      code: 5478,
      name: "智冠",
      year_113: "飛狼內陶瓷塗層保溫杯二入組500ml",
      price_113: 60,
      year_112: "MyCard點數卡100點",
      price_112: 65,
      year_111: "卡-MyCard點數卡100點",
      price_111: 55,
      year_110: "MyCard點數卡100點",
      year_109: "MyCard點數卡100點",
      year_108: "MyCard點數卡或週邊商品",
      year_107: "BLACK HAMMER-單柄牛奶鍋",
    },
    {
      suggestion: "必",
      share_price: 32,
      code: 5481,
      name: "新華",
      year_113: "",
      price_113: "",
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_110: "卡-711卡35元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 114,
      code: 5483,
      name: "中美晶",
      year_113: "日式砂鍋",
      price_113: 20,
      year_112: "迷人兔方形碗組",
      price_112: 25,
      year_111: "康寧8.5深盤",
      price_111: 20,
      year_110: "安妮兔碗盤組",
      year_109: "熊本熊玻璃保鮮盒 950ml",
      year_108: "玻璃保鮮盒",
      year_107: "強化微波拉麵碗",
    },
    {
      suggestion: "必",
      share_price: 53.5,
      code: 5484,
      name: "慧友",
      year_113: "皂-蜂王香氛皂一入 70 g",
      price_113: 3,
      year_112: "香皂乙顆盒",
      price_112: 5,
      year_110: "春之玫瑰精油造形香皂/顆",
      year_109: "蜂王皂乙顆",
      year_108: "蜂王皂乙顆",
      year_107: "蜂王香皂乙顆",
    },
    {
      share_price: 33.9,
      code: 5487,
      name: "通泰",
      year_113: "",
      price_113: "",
      year_107: "蘭麗抗菌洗手乳",
    },
    {
      suggestion: "必",
      share_price: 12.15,
      code: 5488,
      name: "松普",
      year_113: "皂-蜂王洋甘菊潤膚皂二入 60g",
      price_113: 5,
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_110: "卡-711卡35元",
      year_109: "卡-711卡35元",
      year_108: "卡-711卡50元",
      year_107: "碗盤蔬果洗潔精",
    },
    {
      suggestion: "必",
      share_price: 25.15,
      code: 5490,
      name: "同亨",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-全家50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 10.8,
      code: 5498,
      name: "凱崴",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-711卡35元",
      price_111: 23,
    },
    {
      suggestion: "必",
      share_price: 9.98,
      code: 5512,
      name: "力麒",
      year_113: "卡-711卡35元",
      price_113: 10,
      year_112: "歐瀅洗衣精 500 g 瓶",
      price_112: 15,
      year_111: "清淨海環保洗碗精480g",
      price_111: 15,
      year_110: "清淨海環保洗碗精480g",
      year_109: "清淨海-環保洗手乳",
      year_108: "環保購物袋",
      year_107: "環保袋",
    },
    {
      share_price: 28,
      code: 5515,
      name: "建國",
      year_113: "",
      price_113: "",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 11.1,
      code: 5521,
      name: "工信",
      year_113: "可折疊旋轉桌面手機支架",
      price_113: 8,
      year_112: "妙管家廚房三寶",
      price_112: 12,
      year_111: "時尚12件修甲/修容組",
      price_111: 20,
      year_110: "卡-711卡50元",
      year_109: "北歐海綿布",
      year_108: "卡-711卡50元",
      year_107: "鍋寶耐熱陶瓷鍋",
    },
    {
      share_price: 28,
      code: 5529,
      name: "鉅陞",
      year_113: "",
      price_113: "",
      year_110: "卡-711卡100元",
      year_109: "卡-711中拿鐵",
      year_108: "卡-711中拿鐵",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 439.5,
      code: 5536,
      name: "聖暉",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
    },
    {
      suggestion: "必",
      share_price: 25.95,
      code: 5603,
      name: "陸海",
      year_113: "皂-伊莎貝爾櫻花皂二入 75g",
      price_113: 6,
      year_112: "伊莎貝爾櫻花皂二入",
      price_112: 5,
      year_111: "伊莎貝爾香皂二入",
      price_111: 7,
    },
    {
      share_price: 44,
      code: 5604,
      name: "中連貨",
      year_113: "",
      price_113: "",
      year_107: "水晶肥皂",
    },
    {
      suggestion: "必",
      share_price: 15.7,
      code: 5608,
      name: "四維航",
      year_113: "卡-全家35元",
      price_113: 26,
      year_112: "卡-全家35元",
      price_112: 25,
      year_111: "小熊造型方巾",
      price_111: 5,
      year_110: "中興富貴平安米200克",
      year_109: "中興富貴平安米200g",
      year_108: "中興富貴平安米300g",
      year_107: "中興富貴平安米300g",
    },
    {
      suggestion: "必",
      share_price: 80.3,
      code: 5609,
      name: "中菲行",
      year_113: "智能語音燈-替",
      price_113: 5,
      year_112: "背包",
      price_112: 5,
      year_111: "妙管家口袋杯",
      price_111: 8,
      year_110: "手機架",
      year_108: "環保吸管",
      year_107: "愛佳寶 玻璃儲物罐",
    },
    {
      suggestion: "必",
      share_price: 4.8,
      code: 5701,
      name: "劍湖山",
      year_113: "566果萃維生素B洗髮露",
      price_113: 18,
      year_112: "澎澎沐浴乳280 g",
      price_112: 5,
      year_111: "咖啡系列產品",
      price_111: 18,
      year_110: "古坑崁城典藏黑咖啡10入",
      year_109: "劍湖山酷咖啡",
      year_108: "劍湖山酷咖啡系列產品",
      year_107: "澎澎 花萃沐浴露",
    },
    {
      suggestion: "必",
      share_price: 66.8,
      code: 5706,
      name: "鳳凰",
      year_113: "鳳凰旅遊行李綁帶",
      price_113: 5,
      year_112: "鳳凰旅遊行李綁帶",
      price_112: 5,
      year_111: "鳳凰旅遊行李綁帶",
      price_111: 5,
    },
    {
      share_price: 12.2,
      code: 5863,
      name: "瑞興銀",
      year_113: "菱格紳士襪乙雙",
      price_113: 10,
    },
    {
      share_price: 43.05,
      code: 5876,
      name: "上海商銀",
      year_113: "",
      price_113: "",
      year_108: "不鏽鋼餐具組(筷子+湯匙)",
      year_107: "真空彈蓋保溫杯",
    },
    {
      suggestion: "可",
      share_price: 24.45,
      code: 5880,
      name: "合庫金",
      year_113: "",
      price_113: "",
      year_112: "卡-全家100元",
      price_112: 85,
      year_109: "卡-全家100元",
    },
    {
      share_price: 13.9,
      code: 5905,
      name: "南仁湖",
      year_113: "卡-小墾丁渡假村300元住宿抵用券",
      price_113: 2,
      year_112: "南仁湖住宿券",
      price_112: 5,
      year_111: "小墾丁渡假村300元住宿抵用券",
      price_111: 5,
      year_110: "小墾丁渡假村500元住宿抵用券",
      year_109: "小墾丁渡假村500元住宿券",
      year_108: "櫻花野餐袋",
      year_107: "抗菌洗手乳",
    },
    {
      share_price: 26.15,
      code: 6005,
      name: "群益證",
      year_113: "",
      price_113: "",
      year_111: "304不鏽鋼包邊吸管組",
      price_111: 5,
      year_108: "多功能工具組",
      year_107: "LED露營燈",
    },
    {
      suggestion: "必",
      share_price: 10.9,
      code: 6015,
      name: "宏遠證",
      year_113: "多功能仙人掌造型工具組",
      price_113: 10,
      year_112: "電動按摩梳",
      price_112: 12,
      year_111: "雙層玻璃瓶",
      price_111: 20,
      year_110: "兩用手電筒",
      year_109: "玻璃壺",
      year_108: "玻璃保鮮盒",
      year_107: "放大鏡",
    },
    {
      suggestion: "必",
      share_price: 14.35,
      code: 6016,
      name: "康和證",
      year_113: "樂美雅LUMINARC強化餐盤三入組",
      price_113: 35,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "美琪抗菌香皂100g三顆裝",
      price_111: 20,
      year_110: "樂美雅 高級餐盤二入組",
      year_109: "美琪抗菌香皂100g三入",
      year_108: "家魔仕鳳翔水晶碗2入組",
      year_107: "白人牙膏禮盒",
    },
    {
      suggestion: "必",
      share_price: 12.6,
      code: 6026,
      name: "福邦證",
      year_113: "皂-伊莎貝爾櫻花皂 三入 75g",
      price_113: 12,
      year_112: "伊莎貝爾櫻花皂三入",
      price_112: 10,
      year_111: "卡-711卡35元",
      price_111: 23,
    },
    {
      suggestion: "必",
      share_price: 177,
      code: 6104,
      name: "創惟",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "毛寶洗碗精450g",
      price_112: 8,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 16.7,
      code: 6108,
      name: "競國",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_110: "卡-711卡35元",
      year_109: "卡-711卡35元",
      year_108: "卡-711卡35元",
      year_107: "卡-711卡35元",
    },
    {
      suggestion: "可",
      share_price: 12,
      code: 6109,
      name: "亞元",
      year_113: "卡-711卡50元",
      price_113: 38,
    },
    {
      suggestion: "必",
      share_price: 56.6,
      code: 6111,
      name: "大宇資",
      year_113: "驅塵氏雙軌密封夾鏈袋 M35入",
      price_113: 7,
      year_112: "伸縮摺疊手機支架",
      price_112: 5,
      year_111: "愛佳寶不鏽鋼保鮮盒",
      price_111: 30,
      year_110: "愛佳寶保溫保冷袋",
      year_109: "花仙子茶樹檸檬洗手乳300g",
    },
    {
      share_price: 73,
      code: 6112,
      name: "聚碩",
      year_113: "",
      price_113: "",
      year_108: "手持迷你風扇",
      year_107: "LED燈絲球泡燈",
    },
    {
      suggestion: "必",
      share_price: 7.92,
      code: 6116,
      name: "彩晶",
      year_113: "卡-宜睿好禮即享券50元",
      price_113: 35,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "HANNS品牌購物袋",
      year_109: "馬卡龍香氛皂",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "可",
      share_price: 12.75,
      code: 6120,
      name: "達運",
      year_113: "卡-711卡50元",
      price_113: 38,
    },
    {
      suggestion: "必",
      share_price: 52.5,
      code: 6122,
      name: "擎邦",
      year_113: "皂-伊莎貝爾櫻花皂 二入 75g",
      price_113: 5,
      year_112: "伊莎貝爾櫻花皂二入",
      price_112: 5,
      year_111: "蜂王植萃精華皂二入",
      price_111: 5,
      year_108: "瓷碗乙只",
      year_107: "瓷碗乙只",
    },
    {
      suggestion: "必",
      share_price: 82.1,
      code: 6125,
      name: "廣運",
      year_113: "卡-全家35元",
      price_113: 26,
      year_112: "卡-全家35元",
      price_112: 25,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_110: "卡-711卡35元",
      year_109: "卡-全家35元",
      year_108: "卡-全家35元",
      year_107: "卡-全家35元",
    },
    {
      suggestion: "必",
      share_price: 28.2,
      code: 6126,
      name: "信音",
      year_113: "臨時停車號碼牌",
      price_113: 5,
      year_112: "手電筒+行動電源",
      price_112: 10,
      year_111: "USB充電式手電筒",
      price_111: 15,
      year_110: "玻璃沖茶杯",
      year_109: "充電式手電筒",
      year_108: "鏡面陶瓷杯一個",
      year_107: "廚房用品二件組",
    },
    {
      suggestion: "必",
      share_price: 26.85,
      code: 6127,
      name: "九豪",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "毛寶洗碗精450g",
      price_112: 8,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_110: "卡-全家35元",
      year_107: "卡-711卡100元",
    },
    {
      suggestion: "必",
      share_price: 16.1,
      code: 6129,
      name: "普誠",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "卡-全家50元",
      price_112: 37,
      year_111: "卡-全家50元",
      price_111: 35,
      year_110: "卡-全家35元",
      year_109: "卡-全家35元",
      year_108: "卡-全家35元",
      year_107: "卡-全家35元",
    },
    {
      suggestion: "可",
      share_price: 23,
      code: 6130,
      name: "星寶",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-711卡35元",
      price_112: 23,
    },
    {
      share_price: 19.2,
      code: 6133,
      name: "金橋",
      year_113: "",
      price_113: "",
      year_108: "USB傳輸線",
    },
    {
      share_price: 30,
      code: 6134,
      name: "萬旭",
      year_113: "",
      price_113: "",
      year_108: "銀鈦防護3D立體摺疊口罩二入",
      year_107: "蔬果保鮮袋",
    },
    {
      suggestion: "必",
      share_price: 23.35,
      code: 6136,
      name: "富爾特",
      year_113: "護手霜",
      price_113: 10,
      year_112: "滾珠按摩精油",
      price_112: 15,
      year_111: "經典滾珠按摩精油",
      price_111: 15,
      year_110: "台灣茶樞手工皂二入",
      year_109: "台灣茶摳 二入皂",
      year_108: "台灣茶摳 艾草潤膚皂 二入",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 176.5,
      code: 6138,
      name: "茂達",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "卡-全家50元",
      price_112: 35,
      year_111: "卡-全家50元",
      price_111: 35,
      year_109: "香皂",
      year_107: "台灣茶摳三入皂+沐浴袋",
    },
    {
      suggestion: "必",
      share_price: 11.3,
      code: 6142,
      name: "友勁",
      year_113: "甲魚全沛全方位維他命",
      price_113: 10,
      year_112: "卡-711卡50元/替",
      price_112: 37,
      year_109: "卡-711卡35元",
      year_108: "環保吸管組",
      year_107: "環保餐具",
    },
    {
      suggestion: "必",
      share_price: 131,
      code: 6143,
      name: "振曜",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "卡-全家50元",
      price_112: 39,
      year_111: "卡-全家50元",
      price_111: 39,
      year_110: "卡-711卡50元",
      year_109: "卡-全家50元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      share_price: 203,
      code: 6146,
      name: "耕興",
      year_113: "",
      price_113: "",
      year_108: "台梗9號白米 2KG",
      year_107: "台梗9號白米 2KG",
    },
    {
      suggestion: "必",
      share_price: 64.1,
      code: 6147,
      name: "頎邦",
      year_113: "環保材質運動涼感巾",
      price_113: 8,
      year_112: "雙層玻璃杯",
      price_112: 5,
      year_111: "保溫保冷提袋",
      price_111: 12,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-全家50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 39.95,
      code: 6148,
      name: "驊宏資",
      year_113: "隨身型牙籤盒",
      price_113: 5,
      year_112: "購物袋",
      price_112: 5,
      year_111: "多功能滑鼠墊",
      price_111: 5,
      year_110: "口罩收納盒",
      year_109: "簡易按摩器",
      year_108: "3in1尺規觸控筆",
      year_107: "3C螢幕手機擦拭布",
    },
    {
      suggestion: "必",
      share_price: 71.4,
      code: 6150,
      name: "撼訊",
      year_113: "樂美雅20cm強化餐盤單入組",
      price_113: 20,
      year_112: "樂美雅21cm方盤",
      price_112: 15,
      year_111: "台鹽生技喜馬拉雅 手採玫瑰鹽",
      price_111: 12,
      year_110: "保溫保冷袋",
      year_109: "FlourUp 特調益生菌2包",
      year_108: "樂活杯",
      year_107: "8合1工具組",
    },
    {
      suggestion: "必",
      share_price: 13.05,
      code: 6152,
      name: "百一",
      year_113: "料理海鹽",
      price_113: 8,
      year_112: "醬料瓶",
      price_112: 8,
      year_111: "修容組",
      price_111: 5,
      year_110: "牙膏",
      year_109: "沙威隆抗菌洗手乳",
      year_108: "吸管三入組",
      year_107: "鍋寶鮮匯玻璃碗 2入",
    },
    {
      suggestion: "必",
      share_price: 14.95,
      code: 6153,
      name: "嘉聯益",
      year_113: "迷你鋁製LED手電筒",
      price_113: 5,
      year_112: "手機平板支架",
      price_112: 5,
      year_111: "香茅清新皂",
      price_111: 5,
      year_110: "燕麥肌膚舒緩柔膚皂",
      year_109: "手機吸盤支架",
      year_108: "USB風扇",
      year_107: "申縮觸控筆",
    },
    {
      suggestion: "必",
      share_price: 27.55,
      code: 6156,
      name: "松上",
      year_113: "皂-蜂王皂 艾草山藥 一入 90g",
      price_113: 5,
      year_112: "茶樹抗菌 300 ml",
      price_112: 10,
      year_111: "菲蘇德美沐浴乳100ml",
      price_111: 5,
      year_109: "蘭麗洗手乳",
      year_108: "香皂組",
    },
    {
      suggestion: "必",
      share_price: 28.45,
      code: 6158,
      name: "禾昌",
      year_113: "卡-全家禮贈卡35元8/31",
      price_113: 22,
      year_112: "卡-全家35元 限",
      price_112: 20,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "鍋寶316不鏽鋼保溫快煮壺",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 25.85,
      code: 6160,
      name: "欣技",
      year_113: "卡-711中美式/現萃茶卡",
      price_113: 17,
      year_112: "卡-711中美式",
      price_112: 18,
      year_111: "卡-711中美式",
      price_111: 18,
      year_108: "卡-711卡35元",
      year_107: "卡-711卡35元",
    },
    {
      suggestion: "必",
      share_price: 46,
      code: 6161,
      name: "捷波",
      year_113: "毛寶洗碗精",
      price_113: 7,
      year_112: "毛寶洗碗精450g",
      price_112: 8,
      year_111: "卡-全家中美式",
      price_111: 15,
      year_110: "旅行鞋用防塵收納袋",
      year_109: "潛水包餐具二件組",
      year_108: "香氛皂2入盒",
      year_107: "蘭麗綿羊皂 2入",
    },
    {
      suggestion: "必",
      share_price: 22.15,
      code: 6163,
      name: "華電網",
      year_113: "卡-711卡50元",
      price_113: 8,
      year_112: "玻璃杯",
      price_112: 5,
      year_111: "馬克杯",
      price_111: 5,
      year_110: "茶杯",
      year_109: "馬克杯",
      year_108: "字得其樂",
      year_107: "字得其樂",
    },
    {
      suggestion: "必",
      share_price: 13,
      code: 6164,
      name: "華興",
      year_113: "皂-南僑水晶肥皂 二入 90 g",
      price_113: 12,
      year_112: "妙管家洗衣粉 500g",
      price_112: 5,
      year_111: "蜂王純萃潔淨乳油木果皂一盒(二入)",
      price_111: 5,
      year_110: "妙管家強效洗衣粉 500 g",
      year_109: "毛寶洗碗精",
      year_108: "卡-全家50元",
      year_107: "卡-全家大美式",
    },
    {
      suggestion: "必",
      share_price: 49.2,
      code: 6165,
      name: "浪凡",
      year_113: "卡-全家35元",
      price_113: 25,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-全家35元",
      price_111: 25,
      year_110: "卡-全家35元",
      year_109: "卡-全家50元",
      year_108: "卡-全家50元",
      year_107: "益生菌",
    },
    {
      suggestion: "必",
      share_price: 13.2,
      code: 6167,
      name: "久正",
      year_113: "卡-全家中美式(2027/06)",
      price_113: 26,
      year_112: "卡-全家中美式",
      price_112: 12,
      year_111: "卡-全家中美式",
      price_111: 15,
      year_110: "卡-全家中美式",
      year_109: "潤膚濕紙巾",
      year_108: "潤膚濕紙巾",
      year_107: "卡-711卡35元",
    },
    {
      share_price: 22.05,
      code: 6168,
      name: "宏齊",
      year_113: "",
      price_113: "",
      year_108: "卡-711卡35元",
      year_107: "LED燈泡",
    },
    {
      suggestion: "必",
      share_price: 19.8,
      code: 6169,
      name: "昱泉",
      year_113: "不鏽鋼玻璃調味瓶",
      price_113: 6,
      year_112: "不織布購物袋",
      price_112: 5,
      year_111: "平面成人口罩",
      price_111: 5,
      year_110: "平面成人口罩",
      year_109: "陶瓷水果刀菜板套組",
      year_108: "玻璃壺",
      year_107: "台灣茶摳手工皂禮盒組",
    },
    {
      suggestion: "必",
      share_price: 52.1,
      code: 6170,
      name: "統振",
      year_113: "電池四入",
      price_113: 7,
      year_112: "沙威隆皂2入",
      price_112: 6,
      year_111: "彩妝商品",
      price_111: 5,
      year_110: "彩妝商品",
      year_109: "彩妝商品",
      year_108: "彩妝商品",
      year_107: "艾森絲(essence)指甲油",
    },
    {
      suggestion: "必",
      share_price: 43.7,
      code: 6173,
      name: "信昌電",
      year_113: "美式格紋收納提籃",
      price_113: 5,
      year_112: "野餐墊",
      price_112: 5,
      year_111: "愛盲手工皂組一組",
      price_111: 5,
      year_110: "環保玻璃瓶",
      year_109: "不銹鋼吸管一組",
      year_108: "運動毛巾",
    },
    {
      suggestion: "必",
      share_price: 27.8,
      code: 6179,
      name: "亞通",
      year_113: "四件式修容組",
      price_113: 6,
      year_112: "卡-全家50元",
      price_112: 39,
      year_111: "卡-全家50元",
      price_111: 39,
    },
    {
      suggestion: "必",
      share_price: 75.6,
      code: 6180,
      name: "橘子",
      year_113: "卡-有閑購物現金抵用券66元",
      price_113: 2,
      year_112: "卡橘子",
      price_112: 15,
      year_111: "beanfun!零用金100元",
      price_111: 5,
      year_110: "beanfun!零用金100元",
      year_109: "水清淨全方位抗菌液",
      year_108: "不鏽鋼多功能工具",
      year_107: "環保購物袋",
    },
    {
      suggestion: "必",
      share_price: 24.2,
      code: 6182,
      name: "合晶",
      year_113: "20W快速充電座/711拿鐵2張，2:1",
      price_113: 25,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711中美式",
      year_109: "沙威隆-抗菌洗手乳180ml",
      year_108: "卡-全家50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "可",
      share_price: 110,
      code: 6183,
      name: "關貿",
      year_113: "",
      price_113: "",
      year_112: "卡-711卡100元",
      price_112: 81,
      year_109: "抗菌洗手組",
    },
    {
      suggestion: "必",
      share_price: 15.2,
      code: 6185,
      name: "幃翔",
      year_113: "毛寶好無比洗衣精500ml",
      price_113: 7,
      year_112: "好無比洗衣精",
      price_112: 7,
      year_111: "好無比洗衣精",
      price_111: 6,
      year_110: "好無比洗衣精",
      year_109: "好無比洗衣精",
      year_108: "好無比洗衣精",
      year_107: "好無比洗衣精",
    },
    {
      suggestion: "必",
      share_price: 59.8,
      code: 6186,
      name: "新潤",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_108: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 403,
      code: 6187,
      name: "萬潤",
      year_113: "卡-全家中美式(2027/06)",
      price_113: 12,
      year_112: "卡-全中美式",
      price_112: 12,
    },
    {
      suggestion: "必",
      share_price: 104.5,
      code: 6188,
      name: "廣明",
      year_113: "皂-蜂王洋甘菊潤膚皂二入 70g",
      price_113: 8,
      year_112: "蜂王植萃精華皂二入皂",
      price_112: 5,
      year_111: "伊莎貝爾無患子潤膚皂二入組",
      price_111: 7,
      year_110: "蜂王綠野草本抗菌皂二入",
      year_109: "無患子洗臉沐浴美容皂二入",
      year_108: "蜂王純萃甦芙植妍皂二入",
      year_107: "蜂王乳油木果精華皂2入",
    },
    {
      suggestion: "必",
      share_price: 77.3,
      code: 6189,
      name: "豐藝",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "卡-全家50元",
      price_112: 36,
      year_111: "卡-全家50元",
      price_111: 35,
      year_110: "伊莎貝爾香皂四入",
      year_109: "蜂王香皂四入",
      year_108: "照明燈",
      year_107: "沙威隆抗菌香皂 2入",
    },
    {
      suggestion: "必",
      share_price: 34.7,
      code: 6190,
      name: "萬泰科",
      year_113: "萬泰ACT超透氣口罩",
      price_113: 5,
      year_112: "口罩",
      price_112: 5,
      year_111: "萬泰ACT超透氣口罩",
      price_111: 5,
      year_108: "銀鈦防護3D立體折疊口罩二入",
      year_107: "銀鈦抗菌蔬果保鮮袋",
    },
    {
      suggestion: "必",
      share_price: 75.2,
      code: 6191,
      name: "精成",
      year_113: "美式格紋收納提籃",
      price_113: 6,
      year_112: "野餐墊",
      price_112: 5,
      year_111: "愛盲手工皂組一組",
      price_111: 5,
      year_110: "環保玻璃瓶",
      year_109: "環保提袋",
      year_108: "運動毛巾",
      year_107: "好樣旅行組",
    },
    {
      suggestion: "必",
      share_price: 143.5,
      code: 6197,
      name: "佳必琪",
      year_113: "",
      price_113: "",
      year_112: "卡-711中美式",
      price_112: 16,
      year_111: "卡-全家大拿鐵",
      price_111: 25,
      year_110: "卡-711中美式",
      year_109: "卡-711中美式",
      year_108: "卡-711中美式",
      year_107: "卡-711中美式",
    },
    {
      share_price: 41.9,
      code: 6198,
      name: "凌泰",
      year_113: "",
      price_113: "",
      year_108: "科技棉感多功能魔術頭巾",
      year_107: "卡-711卡35元",
    },
    {
      suggestion: "可",
      share_price: 47.5,
      code: 6202,
      name: "盛群",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
    },
    {
      suggestion: "可",
      share_price: 62.7,
      code: 6204,
      name: "艾華",
      year_113: "卡-711卡50元",
      price_113: 38,
    },
    {
      suggestion: "必",
      share_price: 57.2,
      code: 6207,
      name: "雷科",
      year_113: "菌魔力抗菌濕紙巾80抽",
      price_113: 8,
      year_112: "維生素複方膠囊",
      price_112: 12,
      year_111: "維生素CDE+鋅複方膠囊",
      price_111: 18,
      year_110: "維生素CDE+鋅複方膠囊",
      year_109: "維生素CDE+鋅複方膠囊",
      year_108: "BB536益菌康",
      year_107: "卡-全家50元",
    },
    {
      share_price: 49,
      code: 6208,
      name: "日揚",
      year_113: "",
      price_113: "",
      year_107: "好無比 超麗潔洗衣精",
    },
    {
      suggestion: "必",
      share_price: 29.45,
      code: 6209,
      name: "今國",
      year_113: "輕巧摺疊手機支架",
      price_113: 5,
      year_112: "妙管家洗潔精500g",
      price_112: 5,
      year_111: "伊莎貝爾洗手乳+香皂",
      price_111: 10,
      year_110: "蜂王綠野草本抗菌皂二入",
      year_109: "伊莎貝爾無患子抗菌洗手乳",
      year_108: "妙管家強效洗衣粉500g",
      year_107: "蘭麗抗菌洗手乳",
    },
    {
      share_price: 72,
      code: 6213,
      name: "聯茂",
      year_113: "",
      price_113: "",
      year_108: "良質米 600克",
      year_107: "不鏽鋼吸管(粗,細,刷)3入",
    },
    {
      suggestion: "必",
      share_price: 141.5,
      code: 6214,
      name: "精誠",
      year_113: "玻璃控油壺 300ml",
      price_113: 8,
      year_112: "幸運草水晶碗",
      price_112: 5,
      year_111: "運動腰包",
      price_111: 10,
      year_110: "沙威隆抗菌洗手乳180ml",
      year_109: "沙威隆抗菌洗手乳180ml",
      year_108: "潛水布水壺套",
      year_107: "環保洗衣粉350g",
    },
    {
      suggestion: "必",
      share_price: 135.5,
      code: 6215,
      name: "和椿",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_110: "卡-711卡35元",
      year_109: "卡-711卡35元",
      year_108: "卡-711卡35元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 38.85,
      code: 6216,
      name: "居易",
      year_113: "輕薄辦公室便利貼",
      price_113: 5,
      year_112: "口罩二入",
      price_112: 5,
      year_111: "口罩二入組",
      price_111: 5,
      year_108: "卡-全家35元",
      year_107: "卡-全家35元",
    },
    {
      suggestion: "必",
      share_price: 36.45,
      code: 6217,
      name: "中探針",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡35元",
      year_108: "卡-711卡35元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 25.1,
      code: 6220,
      name: "岳豐",
      year_113: "皂-蜂王乳嫩白滋養皂一顆",
      price_113: 5,
      year_112: "蜂膠草本抗菌皂一入",
      price_112: 5,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_108: "三合一充電線",
      year_107: "手提式露營燈",
    },
    {
      suggestion: "必",
      share_price: 69.9,
      code: 6221,
      name: "晉泰",
      year_113: "",
      price_113: "",
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 849,
      code: 6223,
      name: "旺矽",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-全家50元",
      year_108: "卡-全家50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 57.1,
      code: 6224,
      name: "聚鼎",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "陶瓷碗禮盒",
      price_112: 15,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "依必朗抗菌洗手乳",
      year_108: "調味罐",
      year_107: "保鮮盒組",
    },
    {
      suggestion: "必",
      share_price: 8.75,
      code: 6226,
      name: "光鼎",
      year_113: "毛寶好無比洗衣精500ml",
      price_113: 7,
      year_112: "好無比洗衣精粉",
      price_112: 7,
      year_111: "小麥環保杯",
      price_111: 5,
      year_110: "口罩收納盒",
      year_109: "蜂王保濕潔膚皂一入",
      year_108: "AC59 小麥餐具三件套",
      year_107: "香皂",
    },
    {
      suggestion: "必",
      share_price: 29.85,
      code: 6229,
      name: "研通",
      year_113: "皂-蜂王洋甘菊潤膚皂二入 60g",
      price_113: 6,
      year_112: "蜂王草本植舒亮澤皂二入",
      price_112: 5,
      year_111: "蜂王皂二入",
      price_111: 5,
      year_110: "蜂王皂二入",
      year_109: "蜂王香皂二入",
      year_108: "蜂王香皂",
      year_107: "蘭麗綿羊香皂二入",
    },
    {
      share_price: 170,
      code: 6230,
      name: "超眾",
      year_113: "",
      price_113: "",
      year_107: "毛寶洗碗精",
    },
    {
      suggestion: "必",
      share_price: 439.5,
      code: 6231,
      name: "系微",
      year_113: "保溫保冷袋(熊)",
      price_113: 10,
      year_112: "毛寶洗碗精450g",
      price_112: 8,
      year_111: "蜂王皂二入",
      price_111: 5,
    },
    {
      suggestion: "必",
      share_price: 26.3,
      code: 6233,
      name: "旺玖",
      year_113: "卡-全家35元",
      price_113: 26,
      year_112: "卡-全家35元",
      price_112: 25,
      year_111: "卡-全家中美式",
      price_111: 15,
      year_110: "卡-全家中美式",
      year_109: "卡-全家中美式",
      year_108: "卡-全家中美式",
      year_107: "卡-全家35元",
    },
    {
      suggestion: "可",
      share_price: 26.15,
      code: 6234,
      name: "高僑",
      year_113: "",
      price_113: "",
      year_112: "卡-全家中美式",
      price_112: 12,
      year_111: "卡-全家中美式",
      year_109: "卡-711咖中美",
    },
    {
      suggestion: "必",
      share_price: 63.6,
      code: 6235,
      name: "華孚",
      year_113: "米-有機白米",
      price_113: 6,
      year_112: "米薄餅",
      price_112: 10,
      year_111: "醫用口罩",
      price_111: 5,
      year_110: "顏帝亞嬰兒皂二入",
      year_109: "華南特調掛耳咖啡",
      year_108: "Cepis有機甘栗",
      year_107: "天然草本蜂蜜洗髮精",
    },
    {
      suggestion: "必",
      share_price: 57.5,
      code: 6237,
      name: "驊訊",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡100元",
    },
    {
      suggestion: "必",
      share_price: 113.5,
      code: 6239,
      name: "力成",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡100元",
      price_112: 81,
      year_111: "卡-全家50元",
      price_111: 39,
      year_110: "卡-全家50元",
      year_109: "卡-全家50元",
      year_108: "卡-全家50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 15.1,
      code: 6241,
      name: "易通展",
      year_113: "甲魚全沛全方位維他命",
      price_113: 10,
      year_112: "甲魚全沛全方位維他命",
      price_112: 20,
      year_111: "卡-711卡35元",
      price_111: 23,
    },
    {
      suggestion: "必",
      share_price: 45.7,
      code: 6243,
      name: "迅杰",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-711卡66",
      price_111: 51,
      year_110: "卡-711卡35元",
      year_109: "卡-711卡35元",
      year_108: "卡-711卡66元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 20.9,
      code: 6244,
      name: "茂迪",
      year_113: "米-優選經典米",
      price_113: 10,
      year_112: "米-600 ｇ",
      price_112: 12,
      year_111: "關山穀堡一等米 800 g",
      price_111: 18,
      year_110: "中興米 500 g",
      year_109: "中興米 500 g",
      year_108: "皇家穀堡 1公斤",
      year_107: "皇家穀堡 1公斤",
    },
    {
      suggestion: "必",
      share_price: 15.2,
      code: 6246,
      name: "臺龍",
      year_113: "樂曼尼多用途造型碟4.5吋",
      price_113: 5,
      year_112: "放大鏡指甲剪",
      price_112: 5,
      year_111: "伊莎貝爾無患子洗手乳 250ml",
      price_111: 12,
      year_110: "愛佳寶304不銹鋼餐具組3件式",
      year_109: "樂美雅法製深盤",
      year_108: "韓漾旅行巧收袋",
      year_107: "企鵝瓶二入組",
    },
    {
      suggestion: "必",
      share_price: 28,
      code: 6248,
      name: "沛波",
      year_113: "犇牛樟芝菌絲體精粹",
      price_113: 10,
      year_112: "甲魚全沛全方位維他命",
      price_112: 20,
    },
    {
      suggestion: "必",
      share_price: 71.6,
      code: 6257,
      name: "矽格",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-全家50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 61.5,
      code: 6261,
      name: "久元",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_110: "卡-711卡35元",
      year_109: "卡-711卡35元",
      year_108: "卡-711卡35元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 10.7,
      code: 6265,
      name: "堃昶",
      year_113: "仙桃牌機能飲品",
      price_113: 5,
      year_112: "仙桃牌機能飲品組合",
      price_112: 5,
      year_111: "口罩",
      price_111: 5,
      year_109: "仙桃牌完美女人",
      year_108: "仙桃牌珍玉補體",
      year_107: "仙桃牌通汝飲",
    },
    {
      suggestion: "必",
      share_price: 31.6,
      code: 6266,
      name: "泰詠",
      year_113: "卡-宜睿好禮即享券50元",
      price_113: 35,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
    },
    {
      suggestion: "必",
      share_price: 70.2,
      code: 6269,
      name: "台郡",
      year_113: "卡-全家中美式(2027/06)",
      price_113: 13,
      year_112: "好無比洗衣精粉",
      price_112: 7,
      year_111: "卡-全家50元",
      price_111: 39,
      year_110: "卡-全家35元",
      year_109: "卡-全家35元",
      year_108: "不鏽鋼吸管5件組",
      year_107: "環保收納袋",
    },
    {
      suggestion: "必",
      share_price: 25.4,
      code: 6270,
      name: "倍微",
      year_113: "保冷袋",
      price_113: 10,
      year_112: "毛寶洗碗精450g",
      price_112: 8,
      year_111: "蜂王皂二入",
      price_111: 5,
    },
    {
      suggestion: "可",
      share_price: 126.5,
      code: 6271,
      name: "同欣",
      year_113: "伊莎貝爾無患子洗手乳 250ml",
      price_113: 12,
      year_112: "妙管家洗潔精500g",
      price_112: 6,
    },
    {
      suggestion: "必",
      share_price: 163.5,
      code: 6274,
      name: "台燿",
      year_113: "卡-全家禮贈50元9/30",
      price_113: 37,
      year_112: "卡-全家50元",
      price_112: 39,
    },
    {
      suggestion: "必",
      share_price: 46.35,
      code: 6275,
      name: "元山",
      year_113: "旅行分裝瓶4件組",
      price_113: 6,
      year_112: "修容組7件組",
      price_112: 8,
      year_111: "不鏽鋼環保吸管組",
      price_111: 5,
      year_107: "卡-711卡35元",
    },
    {
      suggestion: "可",
      share_price: 29.65,
      code: 6276,
      name: "安鈦克",
      year_113: "卡-711卡50元",
      price_113: 38,
    },
    {
      suggestion: "必",
      share_price: 31.2,
      code: 6282,
      name: "康舒",
      year_113: "藍繪碗2入",
      price_113: 6,
      year_112: "皂-蜂王鬱金香皂二入",
      price_112: 5,
      year_111: "蜂王皂二入",
      price_111: 6,
      year_110: "蜂王皂二入",
      year_109: "蜂王草本抗菌皂二入",
      year_108: "薰衣草香氣皂二入",
      year_107: "蘭麗綿羊皂三入",
    },
    {
      suggestion: "必",
      share_price: 82,
      code: 6284,
      name: "佳邦",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_110: "環保玻璃瓶",
      year_109: "環保提袋",
      year_108: "運動毛巾",
      year_107: "不鏽鋼環保吸管組",
    },
    {
      suggestion: "必",
      share_price: 26.6,
      code: 6288,
      name: "聯嘉",
      year_113: "保冷袋 shanldin標籤",
      price_113: 10,
      year_112: "妙管家廚房三寶",
      price_112: 12,
      year_111: "三用照明燈",
      price_111: 10,
    },
    {
      suggestion: "必",
      share_price: 83.5,
      code: 6290,
      name: "良維",
      year_113: "保溫保冷袋(熊)",
      price_113: 7,
      year_112: "紙巾一包",
      price_112: 5,
      year_111: "蜂王皂二入",
      price_111: 5,
      year_110: "卡-全家中美式",
      year_108: "濕紙巾",
      year_107: "濕紙巾",
    },
    {
      suggestion: "必",
      share_price: 86.5,
      code: 6411,
      name: "晶焱",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-全家50元",
      price_112: 39,
      year_111: "卡-全家50元",
      price_111: 39,
      year_110: "卡-全家50元",
      year_109: "卡-全家50元",
      year_108: "卡-全家50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 121,
      code: 6416,
      name: "瑞祺",
      year_113: "卡-711中美式/現萃茶卡",
      price_113: 17,
      year_112: "卡-711中美式",
    },
    {
      suggestion: "必",
      share_price: 81,
      code: 6426,
      name: "統新",
      year_113: "料理剪",
      price_113: 5,
      year_112: "杯墊",
      price_112: 5,
      year_111: "蜂王皂二入",
      price_111: 5,
      year_110: "蜂王乳油木果皂二入",
      year_109: "蜂王草本抗菌皂二入",
      year_108: "香皂2入",
    },
    {
      share_price: 15,
      code: 6431,
      name: "光麗",
      year_113: "",
      price_113: "",
      year_112: "香檳茸超月干能膠囊",
      price_112: 30,
    },
    {
      suggestion: "必",
      share_price: 28.3,
      code: 6432,
      name: "今展科",
      year_113: "卡-全家中美式(2027/06)",
      price_113: 24,
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-全家50元 限",
      price_111: 35,
    },
    {
      suggestion: "必",
      share_price: 241,
      code: 6438,
      name: "迅得",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "卡-全家50元",
      price_112: 39,
      year_111: "卡-全家50元",
      price_111: 39,
      year_110: "卡-全家50元",
      year_109: "卡-全家50元",
      year_108: "卡-全家50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 33.6,
      code: 6441,
      name: "廣錠",
      year_113: "米-台梗8號米",
      price_113: 5,
      year_112: "平安米300 g",
      price_112: 6,
      year_111: "卡-711卡50元",
      price_111: 37,
    },
    {
      share_price: 556,
      code: 6442,
      name: "光聖",
      year_113: "",
      price_113: "",
      year_112: "卡-全家300元",
      price_112: 269,
    },
    {
      suggestion: "必",
      share_price: 17.4,
      code: 6443,
      name: "元晶",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "卡-全家50元",
      price_112: 39,
      year_111: "卡-全家50元",
      price_111: 39,
      year_110: "卡-全家50元",
      year_109: "卡-全家50元",
      year_108: "卡-全家50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "可",
      share_price: 699,
      code: 6446,
      name: "藥華藥",
      year_113: "卡-全家50元",
      price_113: 39,
      year_110: "卡-全家50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "可",
      share_price: 134.5,
      code: 6449,
      name: "鈺邦",
      year_113: "",
      price_113: "",
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡100元",
      year_108: "三合一傳輸充電線",
      year_107: "史奴比湯杯",
    },
    {
      suggestion: "必",
      share_price: 62,
      code: 6457,
      name: "紘康",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 33,
      year_111: "卡-全家50元",
      price_111: 34,
    },
    {
      suggestion: "可",
      share_price: 27,
      code: 6461,
      name: "益得",
      year_113: "身可補發泡錠",
      price_113: 10,
    },
    {
      suggestion: "必",
      share_price: 159.5,
      code: 6462,
      name: "神盾",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "卡-全家50元",
      price_112: 39,
      year_111: "卡-全家50元",
      price_111: 39,
      year_110: "卡-全家50元",
      year_109: "卡-全家50元",
      year_108: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 51.2,
      code: 6470,
      name: "宇智",
      year_113: "出風口手機支架",
      price_113: 8,
      year_112: "燈-感應式小夜燈",
      price_112: 5,
      year_111: "珪藻土洗漱墊",
      price_111: 5,
      year_110: "風潮水瓶",
      year_109: "高級LED露營燈",
    },
    {
      suggestion: "必",
      share_price: 38.2,
      code: 6477,
      name: "安集",
      year_113: "皂-菲蘇德美燕麥潔膚皂",
      price_113: 5,
      year_112: "手工皂禮盒",
      year_111: "手工皂",
    },
    {
      suggestion: "必",
      share_price: 61.3,
      code: 6485,
      name: "點序",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡100元",
      price_112: 79,
      year_111: "卡-全家50元",
      price_111: 34,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "可",
      share_price: 82.7,
      code: 6486,
      name: "互動",
      year_113: "卡-全家35元",
      price_113: 26,
    },
    {
      suggestion: "必",
      share_price: 44.2,
      code: 6492,
      name: "生華科",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "卡-全家50元",
      price_112: 39,
      year_111: "卡-全家50元",
      price_111: 39,
    },
    {
      suggestion: "必",
      share_price: 49.65,
      code: 6494,
      name: "九齊",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
    },
    {
      share_price: 1,
      code: 6495,
      name: "納諾",
      year_113: "歐舒特口腔防護噴霧",
      price_113: 10,
    },
    {
      suggestion: "必",
      share_price: 38.1,
      code: 6509,
      name: "聚和",
      year_113: "黃金盾泡泡洗手液",
      price_113: 15,
      year_112: "便利貼組",
      price_112: 10,
      year_111: "N次貼",
      price_111: 10,
      year_110: "N次貼禮盒",
      year_109: "「N次貼」禮盒",
      year_108: "「N次貼」禮盒",
      year_107: "「黃金盾+N次貼」禮盒",
    },
    {
      suggestion: "可",
      share_price: 65.6,
      code: 6517,
      name: "保勝",
      year_113: "卡-711卡50元",
      price_113: 38,
    },
    {
      suggestion: "可",
      share_price: 66.9,
      code: 6530,
      name: "創威",
      year_113: "卡-全家50元",
      price_113: 39,
    },
    {
      suggestion: "可",
      share_price: 312.5,
      code: 6531,
      name: "愛普",
      year_113: "卡-全家中美式",
      price_113: 13,
      year_112: "卡-全家中美式",
      price_112: 12,
    },
    {
      suggestion: "必",
      share_price: 69.8,
      code: 6532,
      name: "瑞耘",
      year_113: "卡-711中美式/現萃茶卡",
      price_113: 17,
      year_112: "卡-全家中美式",
      price_112: 12,
      year_111: "台灣密封扣水杯",
      price_111: 8,
      year_110: "6+1手電筒工具組",
      year_109: "迷你木桶水果叉",
      year_108: "保溫保冷袋",
    },
    {
      suggestion: "必",
      share_price: 445,
      code: 6533,
      name: "晶心科",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "卡-全家50元",
      price_112: 39,
      year_111: "卡-全家50元",
      price_111: 39,
      year_110: "卡-全家50元",
      year_109: "卡-全家50元",
      year_108: "卡-全家50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 102.5,
      code: 6538,
      name: "倉和",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡100元",
      price_112: 81,
      year_111: "卡-711卡100元",
      price_111: 82,
    },
    {
      suggestion: "必",
      share_price: 51,
      code: 6547,
      name: "高端",
      year_113: "口罩六片(盒)×6盒",
      price_113: 3,
      year_112: "瓷碗二入",
      year_111: "平面型口罩",
      year_110: "平面型口罩",
    },
    {
      suggestion: "可",
      share_price: 8.92,
      code: 6549,
      name: "景凱",
      year_113: "卡-711卡35元",
      price_113: 24,
    },
    {
      suggestion: "必",
      share_price: 58.5,
      code: 6558,
      name: "興能高",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-711卡35元",
      price_111: 23,
    },
    { code: 6562, name: "聯亞藥", year_113: "環保隨行杯", price_113: 10 },
    {
      suggestion: "必",
      share_price: 161.5,
      code: 6568,
      name: "宏觀",
      year_113: "卡-全家中美式(2027/06)",
      price_113: 12,
      year_112: "卡-全家50元",
      price_112: 39,
      year_111: "卡-全家50元",
      price_111: 39,
    },
    {
      suggestion: "必",
      share_price: 80,
      code: 6576,
      name: "逸達",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
    },
    {
      suggestion: "可",
      share_price: 64.7,
      code: 6586,
      name: "醣基",
      year_113: "卡-711卡50元",
      price_113: 38,
    },
    {
      suggestion: "必",
      share_price: 36.3,
      code: 6588,
      name: "東典",
      year_113: "卡-宜睿好禮即享券35元",
      price_113: 22,
      year_112: "卡-全家50元",
      price_112: 39,
      year_111: "卡-全家50元",
      price_111: 39,
    },
    {
      suggestion: "必",
      share_price: 53.3,
      code: 6591,
      name: "動力",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "卡-全家50元",
      price_112: 39,
      year_111: "卡-全家50元",
      price_111: 39,
      year_110: "卡-全家50元",
      year_109: "卡-全家50元",
    },
    {
      share_price: 62.2,
      code: 6593,
      name: "台灣銘板",
      year_113: "",
      price_113: "",
      year_111: "卡-711卡50元",
      price_111: 37,
      year_108: "卡-711卡50元",
    },
    {
      share_price: 36,
      code: 6595,
      name: "光禹",
      year_113: "咩咩上樹萌寵樂園-動物園門票1張",
      price_113: 2,
    },
    {
      share_price: 89.3,
      code: 6596,
      name: "寬宏",
      year_113: "",
      price_113: 3,
      year_108: "展覽入場交換券",
      year_107: "展覽入場交換券",
    },
    {
      suggestion: "必",
      share_price: 24.4,
      code: 6598,
      name: "瑞磁",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "卡-全家50元",
      price_112: 39,
    },
    {
      share_price: 16,
      code: 6603,
      name: "富強鑫",
      year_113: "",
      price_113: "",
      year_112: "口罩",
      price_112: 5,
      year_111: "口罩1包",
      price_111: 5,
    },
    {
      share_price: 27,
      code: 6606,
      name: "建德",
      year_113: "",
      price_113: "",
      year_109: "卡-全家100元",
    },
    {
      suggestion: "可",
      share_price: 20,
      code: 6618,
      name: "永虹",
      year_113: "卡-711卡50元",
      price_113: 38,
    },
    {
      suggestion: "必",
      share_price: 14,
      code: 6621,
      name: "華宇",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
    },
    { share_price: 23.5, code: 6645, name: "金萬林", year_112: "造型便利貼" },
    {
      suggestion: "可",
      share_price: 38.4,
      code: 6649,
      name: "台生材",
      year_113: "",
      price_113: "",
      year_112: "卡-711卡50元",
      price_112: 37,
      year_110: "卡-711卡50元",
    },
    {
      share_price: 44,
      code: 6654,
      name: "天正",
      year_113: "",
      price_113: "",
      year_108: "LED露營燈",
    },
    {
      share_price: 224,
      code: 6664,
      name: "群翊",
      year_113: "",
      price_113: "",
      year_107: "環保筷",
    },
    {
      suggestion: "必",
      share_price: 83.2,
      code: 6672,
      name: "騰輝",
      year_113: "卡-711卡100元",
      price_113: 83,
      year_112: "卡-711卡100元",
      price_112: 81,
      year_111: "卡-711卡100元",
      price_111: 82,
    },
    {
      suggestion: "必",
      share_price: 433,
      code: 6683,
      name: "雍智",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
    },
    {
      share_price: 62.6,
      code: 6684,
      name: "安格",
      year_113: "杯墊一個",
      price_113: 3,
    },
    {
      suggestion: "必",
      share_price: 36.45,
      code: 6698,
      name: "旭暉",
      year_113: "多孔吸水材料杯墊",
      price_113: 3,
      year_112: "卡-全家35元",
      price_112: 20,
      year_110: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 152.5,
      code: 6703,
      name: "軒郁",
      year_113: "乳酸菌面膜(粉盒)",
      price_113: 20,
      year_112: "面膜一盒",
      price_112: 20,
      year_111: "未來美面膜一盒",
      price_111: 25,
      year_110: "面膜三片",
      year_109: "面膜",
      year_108: "面膜",
    },
    {
      suggestion: "必",
      share_price: 101.5,
      code: 6706,
      name: "惠特",
      year_113: "卡-全家100元",
      price_113: 85,
      year_112: "卡-全家50元",
      price_112: 39,
      year_110: "卡-711卡50元",
    },
    {
      suggestion: "可",
      share_price: 178.5,
      code: 6712,
      name: "長聖",
      year_113: "卡-711卡50元",
      price_113: 38,
    },
    {
      suggestion: "可",
      share_price: 224,
      code: 6719,
      name: "力智",
      year_113: "卡-711卡35元",
      price_113: 25,
    },
    {
      suggestion: "必",
      share_price: 79,
      code: 6727,
      name: "亞泰",
      year_113: "卡-711卡100元",
      price_113: 83,
      year_112: "卡-711卡100元",
      price_112: 81,
      year_111: "卡-711卡200元",
      price_111: 173,
    },
    {
      suggestion: "可",
      share_price: 73.5,
      code: 6742,
      name: "澤米",
      year_113: "卡-全家50元",
      price_113: 35,
    },
    {
      suggestion: "必",
      share_price: 17.6,
      code: 6770,
      name: "力積電",
      year_113: "卡-711卡100元",
      price_113: 83,
      year_112: "卡-711卡100元",
      price_112: 81,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡100元",
    },
    {
      suggestion: "必",
      share_price: 222,
      code: 6788,
      name: "華景電",
      year_113: "精美濾掛咖啡",
      price_113: 30,
      year_112: "濾掛咖啡一盒",
      price_112: 20,
      year_111: "精美濾掛咖啡一盒",
      price_111: 18,
    },
    {
      suggestion: "可",
      share_price: 17,
      code: 6793,
      name: "天力離岸",
      year_113: "卡-711卡35元",
      price_113: 25,
    },
    {
      suggestion: "必",
      share_price: 20,
      code: 6797,
      name: "圓點奈米",
      year_113: "卡-711中美式/現萃茶卡",
      price_113: 17,
      year_112: "卡-711卡100元",
      price_112: 81,
    },
    {
      suggestion: "可",
      share_price: 44,
      code: 6798,
      name: "展逸",
      year_113: "展逸國際集團餐券",
      price_113: 3,
    },
    {
      suggestion: "可",
      share_price: 113,
      code: 6799,
      name: "來頡",
      year_113: "卡-711卡50元",
      price_113: 38,
    },
    {
      suggestion: "必",
      share_price: 30.9,
      code: 6810,
      name: "新穎",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
    },
    {
      suggestion: "可",
      share_price: 51.5,
      code: 6886,
      name: "遠東生技",
      year_113: "卡-尊榮紅利金2000元",
      price_113: 3,
    },
    {
      suggestion: "可",
      share_price: 115,
      code: 6902,
      name: "走著瞧-創",
      year_113: "卡-Whoscall進階版禮物卡一年",
      price_113: 10,
    },
    {
      suggestion: "必",
      share_price: 11.75,
      code: 7516,
      name: "清淨海",
      year_113: "清淨海MIIFLORA香水洗髮精720g",
      price_113: 15,
      year_112: "洗衣膠囊18顆/袋",
      price_112: 15,
    },
    {
      suggestion: "可",
      share_price: 840,
      code: 7722,
      name: "LINEPAY",
      year_113: "卡- LINE POINTS 100點",
      price_113: 50,
    },
    {
      suggestion: "必",
      share_price: 23.3,
      code: 8011,
      name: "台通",
      year_113: "卡-711中美式/現萃茶卡",
      price_113: 18,
      year_112: "折疊購物袋",
      price_112: 5,
      year_111: "三用照明燈",
      price_111: 10,
      year_110: "環保餐具二件組",
      year_109: "蘭麗洗手乳",
      year_108: "毛寶洗手乳",
      year_107: "毛寶洗碗精",
    },
    {
      suggestion: "必",
      share_price: 214,
      code: 8016,
      name: "矽創",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-全家中美式",
      price_112: 12,
      year_111: "卡-全家中美式",
      price_111: 15,
      year_107: "卡-全家中美式",
    },
    {
      suggestion: "必",
      share_price: 33.5,
      code: 8021,
      name: "尖點",
      year_113: "輕便旅行分裝組",
      price_113: 6,
      year_112: "馬卡龍捲尺",
      price_112: 5,
      year_111: "多功能工具筆",
      price_111: 5,
      year_110: "卡-711卡50元",
      year_109: "好無比洗衣精",
      year_108: "好無比 洗衣精",
      year_107: "毛寶洗碗精",
    },
    {
      suggestion: "必",
      share_price: 17.05,
      code: 8024,
      name: "佑華微",
      year_113: "皂-蜂王洋甘菊潤膚皂二入 60g",
      price_113: 5,
      year_112: "蜂王草本植舒亮澤皂二入",
      price_112: 5,
      year_111: "蜂王植萃精華皂",
      price_111: 5,
    },
    {
      suggestion: "必",
      share_price: 82.7,
      code: 8027,
      name: "鈦昇",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-全家50元",
      year_108: "便利商店禮物卡",
      year_107: "二合一充電傳輸線",
    },
    {
      suggestion: "必",
      share_price: 122.5,
      code: 8028,
      name: "昇陽半",
      year_113: "高級原木筷架組",
      price_113: 8,
      year_112: "砧板",
      price_112: 15,
      year_111: "玻尿酸乾洗手清潔保濕用品",
      price_111: 6,
      year_110: "餐墊",
      year_109: "精美密封罐",
      year_108: "手工羊毛皂",
      year_107: "襪子",
    },
    {
      suggestion: "必",
      share_price: 54.1,
      code: 8032,
      name: "光菱",
      year_113: "保溫保冷袋(熊)",
      price_113: 5,
      year_112: "卡-711中美式",
      price_112: 16,
      year_111: "桌上型便攜可折疊伸縮手機支架",
      price_111: 5,
      year_110: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 66.2,
      code: 8033,
      name: "雷虎",
      year_113: "麗晶麝香抗菌洗手露",
      price_113: 10,
      year_112: "泡舒洗潔精 800 袋",
      price_112: 15,
      year_111: "35元超商禮物卡",
      price_111: 25,
      year_110: "卡-711卡35元",
      year_109: "防護口罩三入",
      year_108: "卡-全家中美式",
      year_107: "卡-全家35元",
    },
    {
      suggestion: "必",
      share_price: 20,
      code: 8034,
      name: "榮群",
      year_113: "皂-白蜜薰衣草珍珠水嫩皂一入 85 g",
      price_113: 5,
      year_112: "蜂王草本植舒亮澤皂二入",
      price_112: 5,
      year_111: "香皂",
      price_111: 5,
      year_110: "卡-全家50元禮贈卡",
      year_107: "卡-711卡100元",
    },
    {
      suggestion: "必",
      share_price: 27.9,
      code: 8038,
      name: "長園科",
      year_113: "微波保鮮盒",
      price_113: 5,
      year_112: "手電筒",
      price_112: 5,
      year_111: "微波保鮮盒",
      price_111: 5,
      year_110: "麥香支架杯",
      year_109: "多功能手電筒",
      year_108: "8合1工具組手電筒",
      year_107: "樂美雅8吋強化深盤",
    },
    {
      suggestion: "必",
      share_price: 49,
      code: 8039,
      name: "台虹",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "45元面額7-11咖啡兌換卡",
      price_111: 27,
      year_110: "卡-711中拿鐵",
      year_109: "卡-711中拿鐵",
      year_108: "妙管家洗衣精",
      year_107: "蘭麗抗菌洗手乳",
    },
    {
      suggestion: "必",
      share_price: 76,
      code: 8040,
      name: "九暘",
      year_113: "卡-711卡100元",
      price_113: 81,
      year_111: "環保筷組",
      year_110: "環保筷組",
      year_109: "多用途脖圍",
      year_108: "多用途脖圍",
      year_107: "陶瓷刀具組",
    },
    {
      suggestion: "必",
      share_price: 37.8,
      code: 8042,
      name: "金山電",
      year_113: "保冷保溫袋(熊)",
      price_113: 10,
      year_112: "蜂王草本植舒精華皂二入",
      price_112: 5,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_110: "卡-711卡35元",
      year_107: "卡-711卡35元",
    },
    {
      share_price: 44.4,
      code: 8044,
      name: "網家",
      year_113: "環保提袋",
      price_113: 2,
      year_112: "環保提袋",
      price_112: 5,
    },
    {
      suggestion: "可",
      share_price: 51,
      code: 8048,
      name: "德勝",
      year_113: "卡-全家50元",
      price_113: 39,
    },
    {
      suggestion: "必",
      share_price: 33.1,
      code: 8049,
      name: "晶采",
      year_113: "USB智能語音灯",
      price_113: 5,
      year_112: "料理剪刀",
      price_112: 10,
      year_111: "陶瓷料理刀組",
      price_111: 15,
      year_110: "三合一微波保鮮盒",
      year_109: "三用手機充電線",
      year_108: "LED隨身風扇",
      year_107: "刨刀組",
    },
    {
      suggestion: "必",
      share_price: 73.1,
      code: 8050,
      name: "廣積",
      year_113: "雙層隔熱隨行杯",
      price_113: 8,
      year_112: "對筆組",
      price_112: 5,
      year_111: "指甲剪",
      price_111: 35,
      year_110: "環保餐具組",
      year_109: "無患子皂組",
      year_108: "陶瓷隨行杯",
      year_107: "企鵝杯",
    },
    {
      suggestion: "必",
      share_price: 123,
      code: 8054,
      name: "安國",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711中美式",
      price_112: 16,
      year_111: "卡-711卡50元",
      price_111: 34,
      year_110: "卡-711卡50元",
      year_109: "卡-全家中冰拿鐵",
      year_108: "卡-711卡40元",
      year_107: "卡-711卡50元",
    },
    {
      share_price: 37.6,
      code: 8059,
      name: "凱碩",
      year_113: "",
      price_113: "",
      year_108: "計算機",
      year_107: "計算機",
    },
    {
      suggestion: "必",
      share_price: 48.15,
      code: 8064,
      name: "東捷",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_110: "卡-711卡35元",
      year_109: "卡-711卡35元",
      year_108: "卡-711卡35元",
      year_107: "卡-711卡35元",
    },
    {
      suggestion: "必",
      share_price: 26.95,
      code: 8066,
      name: "來思達",
      year_113: "皂-沙威隆抗菌皂二入 80g",
      price_113: 8,
      year_112: "蜂王草本植舒亮澤皂二入",
      price_112: 5,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_110: "卡-711卡35元",
      year_109: "卡-711卡50元",
    },
    {
      share_price: 17,
      code: 8067,
      name: "志旭",
      year_113: "",
      price_113: "",
      year_111: "沐浴乳",
      price_111: 8,
      year_109: "沐浴乳",
    },
    {
      suggestion: "必",
      share_price: 290,
      code: 8069,
      name: "元太",
      year_113: "GREEN&SAFE月桃洗髮皂",
      price_113: 15,
      year_112: "橄欖油禮盒",
      price_112: 35,
      year_111: "永豐麵禮盒",
      price_111: 20,
      year_110: "永豐餘 經典醬料禮盒",
      year_109: "永豐餘 經典醬料禮盒",
      year_108: "GREEN&SAFE石斛青草手工皂",
      year_107: "永豐餘 綠茶抗敏牙膏二入",
    },
    {
      suggestion: "必",
      share_price: 21.9,
      code: 8071,
      name: "能率網通",
      year_113: "蘭花洗衣膠囊4顆",
      price_113: 5,
      year_112: "蘭花洗衣膠囊",
      price_112: 5,
      year_111: "深層海水萃取抗敏感牙膏",
      price_111: 5,
    },
    {
      suggestion: "必",
      share_price: 21.25,
      code: 8074,
      name: "鉅橡",
      year_113: "卡-711中美式/現萃茶卡",
      price_113: 18,
      year_112: "卡-全家50元",
      price_112: 39,
    },
    {
      suggestion: "必",
      share_price: 32.45,
      code: 8076,
      name: "伍豐",
      year_113: "卡-全家100元",
      price_113: 85,
      year_112: "卡-全家100元",
      price_112: 85,
      year_111: "卡-全家100元",
      price_111: 85,
      year_110: "卡-全家100元",
      year_109: "卡-全家100元",
      year_108: "卡-711卡100元",
      year_107: "卡-711卡100元",
    },
    {
      suggestion: "可",
      share_price: 31.45,
      code: 8080,
      name: "印鉐",
      year_113: "伊莎貝爾無患子洗手乳 250 ml",
      price_113: 10,
    },
    {
      share_price: 234,
      code: 8081,
      name: "致新",
      year_113: "",
      price_113: "",
      year_109: "卡-全家50元",
      year_108: "卡-全家50元",
      year_107: "卡-全家50元",
    },
    {
      share_price: 29.95,
      code: 8085,
      name: "福華",
      year_113: "",
      price_113: "",
      year_110: "盒裝面紙",
      year_109: "妙管家強效洗衣粉",
      year_108: "卡-711卡50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 93,
      code: 8086,
      name: "宏捷科",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-全家50元禮贈卡",
      year_109: "卡-711卡100元",
      year_108: "卡-711卡100元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 20.05,
      code: 8088,
      name: "品安",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "防疫口罩",
      price_111: 5,
      year_110: "防疫口罩",
      year_109: "熊本熊微波餐盒",
      year_108: "白人牙膏",
      year_107: "陶瓷刀刨刀組",
    },
    {
      suggestion: "必",
      share_price: 71.1,
      code: 8089,
      name: "康全",
      year_113: "皂-蜂王洋甘菊潤膚皂二入 70g",
      price_113: 6,
      year_112: "蜂王植萃精華皂二入皂",
      price_112: 5,
      year_111: "蜂王植萃天然精華皂",
      price_111: 5,
      year_110: "蜂王植萃皂二入",
    },
    {
      share_price: 124,
      code: 8091,
      name: "翔名",
      year_113: "",
      price_113: "",
      year_111: "卡-全家50元",
      price_111: 39,
    },
    {
      suggestion: "可",
      share_price: 22.75,
      code: 8092,
      name: "建暐",
      year_113: "口罩一盒 (30入)",
      price_113: 5,
    },
    {
      suggestion: "必",
      share_price: 54,
      code: 8096,
      name: "擎亞",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "可",
      share_price: 83.1,
      code: 8097,
      name: "常程",
      year_113: "",
      price_113: "",
      year_112: "卡-711卡100元",
      price_112: 81,
    },
    {
      suggestion: "可",
      share_price: 86.1,
      code: 8099,
      name: "大世科",
      year_113: "卡-711卡300元",
      price_113: 263,
    },
    {
      suggestion: "必",
      share_price: 19,
      code: 8101,
      name: "華冠",
      year_113: "卡-全家35元",
      price_113: 25,
      year_112: "卡-全家35元",
      price_112: 23,
      year_111: "卡-全家35元",
    },
    {
      suggestion: "必",
      share_price: 51.4,
      code: 8103,
      name: "瀚荃",
      year_113: "EPVRE草本洗手液檸檬馬鞭草200ml",
      price_113: 10,
      year_112: "充電線 替",
      price_112: 10,
      year_111: "艾草抗菌滋潤液體皂",
      price_111: 10,
      year_110: "茶樹精油潔淨噴霧",
      year_109: "Dreamhund薰衣草舒敏液體皂",
      year_108: "Dreamhound護手霜",
      year_107: "Opro9 傳輸充電線",
    },
    {
      suggestion: "必",
      share_price: 41.1,
      code: 8104,
      name: "錸寶",
      year_113: "萬用去污膏",
      price_113: 5,
      year_112: "香氛噴霧 大",
      price_112: 10,
      year_111: "隨身型防蚊液100ml大",
      price_111: 10,
    },
    {
      suggestion: "可",
      share_price: 19.35,
      code: 8107,
      name: "大億",
      year_113: "卡-全家35元",
      price_113: 25,
    },
    {
      suggestion: "必",
      share_price: 49.1,
      code: 8111,
      name: "立碁",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_110: "卡-711卡35元",
      year_109: "卡-711卡35元",
      year_108: "卡-全家35元",
      year_107: "卡-全家35元",
    },
    {
      suggestion: "必",
      share_price: 61.5,
      code: 8112,
      name: "至上",
      year_113: "保溫保冷袋(橘色)",
      price_113: 10,
      year_112: "環保小麥桿泡麵碗組",
      price_112: 10,
      year_111: "防疫包",
      price_111: 5,
      year_110: "米 600 g",
      year_109: "英國熊露營工具探照燈",
      year_108: "旅行用插座",
      year_107: "LED燈",
    },
    {
      suggestion: "必",
      share_price: 11,
      code: 8119,
      name: "公信",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-711卡35元",
      price_112: 23,
    },
    {
      suggestion: "可",
      share_price: 26.2,
      code: 8121,
      name: "越峰",
      year_113: "",
      price_113: "",
      year_111: "USii優系高效鎖鮮袋組合包",
      price_111: 20,
      year_110: "多層式環保腰包",
      year_109: "USii優系高效鎖鮮袋",
      year_108: "USii優系高效鎖鮮袋",
      year_107: "USii優系高效鎖鮮袋",
    },
    {
      suggestion: "必",
      share_price: 31.9,
      code: 8150,
      name: "南茂",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 44.25,
      code: 8163,
      name: "達方",
      year_113: "24合1磁吸螺絲刀工具組",
      price_113: 10,
      year_112: "盒-妙管家廚房三寶",
      price_112: 12,
      year_111: "飛狼二合一手電筒",
      price_111: 12,
      year_110: "妙管家懷特保溫杯",
      year_109: "樂美雅餐盤",
      year_108: "樂活杯",
      year_107: "LED露營燈",
    },
    {
      suggestion: "必",
      share_price: 38,
      code: 8171,
      name: "天宇",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-711卡35元",
      price_111: 23,
      year_110: "卡-711卡35元",
      year_109: "卡-711卡35元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡35元",
    },
    {
      suggestion: "必",
      share_price: 14.7,
      code: 8176,
      name: "智捷",
      year_113: "皂-蜂王蜂蜜燕麥舒緩皂一入",
      price_113: 5,
      year_112: "卡-全家35元",
      price_112: 25,
      year_111: "卡-全家35元",
      price_111: 25,
      year_110: "卡-全家35元",
      year_109: "卡-全家35元",
      year_108: "卡-全家35元",
      year_107: "卡-全家35元",
    },
    {
      suggestion: "必",
      share_price: 40.2,
      code: 8183,
      name: "精星",
      year_113: "美式格紋收納提籃",
      price_113: 6,
      year_112: "墊-野餐墊",
      price_112: 5,
      year_111: "愛盲手工皂",
      price_111: 5,
      year_110: "環保玻璃瓶",
      year_109: "不銹鋼吸管",
      year_108: "密封儲存罐",
      year_107: "好樣洗髮沐浴組",
    },
    {
      suggestion: "必",
      share_price: 34.5,
      code: 8213,
      name: "志超",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 29.6,
      code: 8215,
      name: "明基材",
      year_113: "經典雙星保養限定組",
      price_113: 15,
      year_112: "煥顏淨痘 組",
      price_112: 15,
      year_111: "護妍天使益菌生保濕2步組",
      price_111: 15,
      year_110: "機能透氣棒球帽",
      year_109: "護妍天使 溫和淨透潔面乳",
      year_108: "卡-711卡50元",
      year_107: "溫和淨透潔面乳",
    },
    {
      suggestion: "必",
      share_price: 37.55,
      code: 8222,
      name: "寶一",
      year_113: "濾掛式咖啡2入",
      price_113: 5,
      year_112: "咖啡 2入",
      price_112: 5,
      year_111: "濾掛式咖啡2入",
      price_111: 5,
      year_110: "濾掛式咖啡2入",
      year_109: "卡-711卡50元",
      year_108: "魚尾餐具組",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 179,
      code: 8227,
      name: "巨有",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
    },
    {
      suggestion: "可",
      share_price: 43.8,
      code: 8240,
      name: "華宏",
      year_113: "",
      price_113: "",
      year_111: "古寶無患子噴霧式乾洗手",
      price_111: 17,
      year_108: "316不鏽鋼環保吸管組",
    },
    {
      suggestion: "必",
      share_price: 51,
      code: 8249,
      name: "菱光",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "卡-全家50元",
      price_112: 36,
      year_111: "卡-全家50元",
      price_111: 35,
      year_110: "摩斯/樂雅樂餐飲兌換券",
      year_109: "耳機",
      year_108: "摩斯漢堡商品兌換券",
      year_107: "耳機",
    },
    {
      share_price: 161.5,
      code: 8255,
      name: "朋程",
      year_113: "",
      price_113: "",
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 91.3,
      code: 8261,
      name: "富鼎",
      year_113: "卡-711卡35元",
      price_113: 25,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡35元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 49.6,
      code: 8271,
      name: "宇瞻",
      year_113: "隨身碟 32 g",
      price_113: 50,
      year_112: "隨身碟",
      price_112: 30,
      year_111: "卡-711卡50元",
      price_111: 34,
      year_110: "隨身碟 16G",
      year_109: "隨身碟-16 g",
      year_108: "隨身碟",
      year_107: "宇瞻 隨身碟 16G",
    },
    {
      suggestion: "必",
      share_price: 12.05,
      code: 8277,
      name: "商丞",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 33,
      year_111: "卡-711卡50元",
      price_111: 34,
      year_110: "卡-711卡35元",
      year_109: "卡-711卡35元",
      year_108: "卡-711卡35元",
      year_107: "卡-711卡35元",
    },
    {
      suggestion: "必",
      share_price: 24.2,
      code: 8289,
      name: "泰藝電",
      year_113: "伊莎貝爾尤加利潔手慕絲",
      price_113: 10,
      year_112: "米-越光米",
      price_112: 6,
      year_111: "伊莎貝爾手工香皂二入",
      price_111: 7,
    },
    {
      suggestion: "必",
      share_price: 488.5,
      code: 8299,
      name: "群聯",
      year_113: "卡-全家50元",
      price_113: 39,
      year_112: "卡-全家50元",
      price_112: 39,
      year_111: "愛盲手工皂禮盒",
      price_111: 10,
      year_110: "米 500 g",
      year_109: "隨身碟-32 g",
    },
    {
      suggestion: "必",
      share_price: 56,
      code: 8358,
      name: "金居",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡50元",
      year_109: "卡-711卡50元",
      year_108: "卡-711卡50元",
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 44.2,
      code: 8383,
      name: "千附",
      year_113: "卡-711卡50元",
      price_113: 38,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-全家50元",
      year_109: "卡-全家50元",
      year_108: "卡-全家50元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 69.9,
      code: 8390,
      name: "金益鼎",
      year_113: "多功能筆插便利貼筆記本1入",
      price_113: 5,
      year_112: "調味瓶",
      price_112: 8,
      year_111: "草本抗菌皂二入",
      price_111: 7,
    },
    {
      suggestion: "必",
      share_price: 28.6,
      code: 8403,
      name: "盛弘",
      year_113: "海藻鈣錠(10錠)盒/替",
      price_113: 5,
      year_112: "盛弘抵用券二張",
      price_112: 5,
      year_111: "蜂王植萃精華皂二入盒",
      price_111: 5,
    },
    {
      share_price: 23.8,
      code: 8409,
      name: "商之器",
      year_113: "不織布提袋",
      price_113: 5,
      year_110: "蜂王香皂",
      year_109: "蜂王草本抗菌皂二入",
      year_108: "蜂王植萃皂2入",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 10.6,
      code: 8429,
      name: "金麗",
      year_113: "卡-全家中美式(2027/06)",
      price_113: 12,
      year_112: "卡-711中美式",
      price_112: 18,
      year_111: "卡-711中美式",
      price_111: 15,
    },
    {
      suggestion: "必",
      share_price: 61.8,
      code: 8431,
      name: "匯鑽科",
      year_113: "卡-全家中美式(2027/06)",
      price_113: 12,
      year_112: "卡-全家50元",
      price_112: 39,
      year_111: "卡-全家50元",
      price_111: 39,
    },
    {
      suggestion: "必",
      share_price: 76.6,
      code: 8435,
      name: "鉅邁",
      year_113: "BAGCU 折疊購物袋",
      price_113: 5,
      year_112: "卡-711卡100元",
      price_112: 81,
    },
    {
      suggestion: "必",
      share_price: 122,
      code: 8436,
      name: "大江",
      year_113: "花萃透亮面膜五入(同和康生)",
      price_113: 20,
      year_112: "大江",
      price_112: 20,
      year_111: "鋅C望粉狀食品",
      price_111: 25,
      year_110: "大江生醫經典面膜",
      year_109: "大江面膜復刻版",
      year_108: "高級面膜福袋乙袋",
    },
    {
      suggestion: "可",
      share_price: 27.8,
      code: 8437,
      name: "大地",
      year_113: "卡-全家50元",
      price_113: 39,
    },
    {
      suggestion: "必",
      share_price: 34,
      code: 8440,
      name: "綠電",
      year_113: "卡-711卡100元",
      price_113: 83,
      year_112: "卡-711卡50元",
      price_112: 37,
    },
    {
      suggestion: "必",
      share_price: 11.9,
      code: 8443,
      name: "阿瘦",
      year_113: "卡-感恩購物金500元現金抵用券",
      price_113: 25,
      year_112: "阿瘦500元券換襪子",
      price_112: 20,
      year_111: "500元現金抵用券",
      price_111: 5,
      year_110: "500元現金扺用券",
      year_107: "Bo Bonny啵啵妮法藍絨毯",
    },
    {
      share_price: 11,
      code: 8477,
      name: "創業家",
      year_113: "",
      price_113: "",
      year_110: "150元生活市集提貨券",
    },
    {
      suggestion: "可",
      share_price: 73.8,
      code: 8487,
      name: "愛爾達",
      year_113: "愛爾達限量多功能手機背袋",
      price_113: 10,
    },
    {
      share_price: 33,
      code: 8489,
      name: "三貝德",
      year_113: "卡-數位學習課程體驗券",
      price_113: 2,
      year_112: "數位課程體驗券",
      price_112: 5,
      year_111: "卡-全家中美式",
      price_111: 15,
      year_110: "毛寶抑菌力洗碗精(1000g)",
      year_109: "數位學習課程體驗券",
    },
    {
      suggestion: "可",
      share_price: 32.05,
      code: 8905,
      name: "裕國",
      year_113: "",
      price_113: "",
      year_112: "愛佳寶不銹鋼保鮮盒二入",
      price_112: 40,
      year_109: "黑胡椒蘑菇醬組",
    },
    {
      suggestion: "必",
      share_price: 58.1,
      code: 8916,
      name: "光隆",
      year_113: "羽毛胜肽乾洗手噴霧",
      price_113: 5,
      year_112: "卡-711卡50元",
      price_112: 37,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711卡35元",
      year_109: "500元光隆商品抵用券",
      year_108: "卡-全家50元",
    },
    {
      suggestion: "可",
      share_price: 56,
      code: 8927,
      name: "北基",
      year_113: "",
      price_113: "",
      year_111: "卡-25元油票",
      price_111: 10,
      year_108: "25元公司油票",
    },
    {
      share_price: 8.38,
      code: 8933,
      name: "愛地雅",
      year_113: "皂- 伊莎貝爾櫻花皂 一入 75g",
      price_113: 5,
      year_108: "蘭麗綿羊香皂",
      year_107: "依必朗洗衣精",
    },
    {
      suggestion: "必",
      share_price: 24.45,
      code: 8935,
      name: "邦泰",
      year_113: "T-KI成人旅行組(硬盒)",
      price_113: 10,
      year_112: "驅塵夾鏈袋",
      price_112: 5,
      year_111: "口罩",
      price_111: 5,
      year_110: "六都之美防護口罩",
      year_109: "立體口罩套",
      year_108: "環保不鏽鋼吸管組",
      year_107: "愛心襪",
    },
    {
      suggestion: "必",
      share_price: 55.3,
      code: 8936,
      name: "國統",
      year_113: "卡-711中美式/現萃茶卡",
      price_113: 17,
      year_112: "卡-711中美式",
      price_112: 18,
      year_111: "卡-711卡50元",
      price_111: 37,
      year_110: "卡-711中美式",
      year_109: "保鮮碗",
      year_108: "保鮮碗(1個)",
      year_107: "瑪里琳保鮮碗",
    },
    {
      suggestion: "可",
      share_price: 306,
      code: 8996,
      name: "高力",
      year_113: "",
      price_113: "",
      year_112: "卡-711卡50元",
      price_112: 37,
      year_109: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 15.65,
      code: 9902,
      name: "台火",
      year_113: "皂-蜂王洋甘菊潤膚皂二入 60g",
      price_113: 5,
      year_112: "香皂二入袋",
      price_112: 5,
      year_111: "蜂王皂二入組",
      price_111: 5,
      year_110: "蜂王植萃天然皂 二入組",
      year_109: "蜂王 草本抗菌皂 二入",
      year_108: "德國櫻花浪漫情懷皂二入",
      year_107: "蜂王草本皂二入",
    },
    {
      suggestion: "必",
      share_price: 12.25,
      code: 9912,
      name: "偉聯",
      year_113: "卡-全家35元",
      price_113: 26,
      year_112: "卡-711卡35元",
      price_112: 23,
      year_111: "卡-全家35元",
      price_111: 25,
      year_110: "卡-全家35元",
      year_109: "卡-711卡35元",
      year_108: "卡-全家35元",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 163,
      code: 9914,
      name: "美利達",
      year_113: "卡-711卡100元",
      price_113: 83,
      year_112: "卡-711卡100元",
      price_112: 81,
      year_111: "卡-711卡100元",
      price_111: 82,
      year_110: "卡-711卡100元",
      year_109: "卡-711卡100元",
      year_108: "卡-全家100元",
      year_107: "卡-全家100元",
    },
    {
      suggestion: "可",
      share_price: 123.5,
      code: 9917,
      name: "中興保",
      year_113: "",
      price_113: "",
      year_112: "卡-711卡100元",
      price_112: 81,
      year_109: "卡-711卡100元",
    },
    {
      share_price: 39.65,
      code: 9918,
      name: "欣天然",
      year_113: "妙管家小蘇打抗菌洗衣精600g",
      price_113: 6,
      year_107: "卡-711卡50元",
    },
    {
      suggestion: "必",
      share_price: 19.95,
      code: 9919,
      name: "康那香",
      year_113: "口罩x30片 (盒)",
      price_113: 6,
      year_112: "紙巾一包-藍色",
      price_112: 12,
      year_111: "濕紙巾1包",
      price_111: 12,
      year_110: "濕紙巾1包",
      year_109: "康乃馨濕紙巾1包",
      year_108: "康乃馨抗菌濕巾40片裝",
      year_107: "康乃馨口罩20片",
    },
    {
      suggestion: "必",
      share_price: 35,
      code: 9935,
      name: "慶豐富",
      year_113: "卡-宜睿好禮即享券50元",
      price_113: 35,
      year_112: "卡-全家50元 限",
      price_112: 38,
      year_111: "卡-全家50元 限",
      price_111: 35,
      year_110: "卡-全家50元 限",
      year_109: "口罩套",
      year_108: "家飾品",
      year_107: "家飾品",
    },
    {
      share_price: 63.5,
      code: 9937,
      name: "全國油",
      year_113: "",
      price_113: "",
      year_110: "德國 Pril 高效能洗碗精",
      year_107: "德恩奈超氟牙膏156g",
    },
    {
      suggestion: "可",
      share_price: 73.6,
      code: 9938,
      name: "百和",
      year_113: "",
      price_113: "",
      year_112: "襪 2雙 盒",
      price_112: 30,
      year_110: "竹碳襪2雙",
      year_109: "竹碳襪2雙",
    },
    {
      share_price: 21.6,
      code: 9946,
      name: "三發",
      year_113: "",
      price_113: "",
      year_109: "亨利耐熱玻璃瓶",
    },
    {
      suggestion: "可",
      share_price: 58.3,
      code: 9951,
      name: "皇田",
      year_113: "卡-711卡35元",
      price_113: 25,
    },
    {
      suggestion: "可",
      share_price: 162,
      code: 9958,
      name: "世紀鋼",
      year_113: "環保折疊購物袋",
      price_113: 5,
      year_112: "手機支架",
      price_112: 5,
      year_111: "收納袋二入",
      price_111: 5,
      year_110: "蜂王草本抗菌皂二入",
    },
    {
      share_price: 25.7,
      code: 9960,
      name: "邁達康",
      year_113: "",
      price_113: "",
      year_107: "卡-全家50元",
    },
    {
      suggestion: "必",
      share_price: 14.6,
      code: 9962,
      name: "有益",
      year_113: "卡-711卡35元",
      price_113: 23,
      year_111: "卡-711卡35元",
      price_111: 23,
    },
  ];